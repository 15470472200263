<div *ngIf="user">
  <mat-card-title class="card-title pb-4">Basic Info</mat-card-title>

  <app-main-input-form
    label="First Name"
    controlName="firstName"
    formItemType="text"
    placeholder="First Name"
    [value]="user.firstName"
    (updateData)="updateUserData($event, user)" />

  <app-main-input-form
    label="Last Name"
    controlName="lastName"
    formItemType="text"
    placeholder="Last Name"
    [value]="user.lastName"
    (updateData)="updateUserData($event, user)" />

  <app-main-input-form
    label="Role"
    controlName="role"
    formItemType="text"
    placeholder="Role"
    [value]="userRoles[user.role.name].name"
    [disabled]="true" />

  <mat-card-title class="card-title pb-4">Contact Info</mat-card-title>

  <app-main-input-form
    label="E-mail"
    controlName="email"
    formItemType="email"
    placeholder="email"
    [value]="user.email"
    [disabled]="true" />

  <app-main-input-form
    label="Phone number"
    controlName="phoneNumber"
    formItemType="string"
    [placeholder]="phoneMask()"
    [applyPhoneMask]="true"
    [phoneMask]="phoneMask()"
    [phoneMaskExpression]="phoneMaskExpression()"
    [hasPrefix]="true"
    [value]="user.phone.number"
    (updateData)="updatePhoneNumber($event, user)">
    <div class="flex justify-between">
      <mat-form-field class="select-classic max-w-[150px] mr-2">
        <mat-select
          panelClass="single-select-overlay"
          (selectionChange)="updateCountryCode($event, user)"
          [value]="user.phone.countryCode || DEFAULT_COUNTRY.code">
          @for (country of COUNTRY_LIST | sort:'asc':'code'; track country.code) {
          <mat-option [value]="country.code">{{ country.code }} ({{ country.phoneCode }})</mat-option>
          }
        </mat-select>
        <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
        <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
      </mat-form-field>
    </div>
  </app-main-input-form>

  <div [ngClass]="isMobile ? '' : 'flex flex-row items-center mb-10 mt-5'">
    <mat-card-title class="card-title w-52 mr-6">Password</mat-card-title>
    <button [ngClass]="isMobile ? 'w-full mt-5 mb-4' : ''" class="btn-yellow btn-medium" (click)="changePassword()">
      CHANGE PASSWORD
    </button>
  </div>

  <div class="flex">
    <mat-card-title class="card-title w-52 mr-6 mt-2.5">Notifications</mat-card-title>
    <div class="classic-form-item-wrapper mb-6" *ngIf="!isMobile">
      <ng-container *ngTemplateOutlet="optIn"></ng-container>
    </div>
  </div>

  <div [ngClass]="isMobile ? 'pt-5' : 'flex flex-row ml-56 mb-2.5 w-[420px] px-2 justify-between'">
    <mat-slide-toggle
      class="test-mail-toggle main-toggle"
      labelPosition="before"
      [checked]="user.notificationsPreferences.emailEnabled"
      [disabled]="user.role.name === userRolesTypes.ReadOnly"
      (change)="toggleNotification($event, 'email', user)">
      <span>Email:</span> {{ user.notificationsPreferences.emailEnabled ? 'On' : 'Off' }}
    </mat-slide-toggle>

    <div class="mb-6" *ngIf="isMobile">
      <ng-container *ngTemplateOutlet="optIn"></ng-container>
    </div>

    <mat-slide-toggle
      class="test-sms-toggle main-toggle"
      labelPosition="before"
      disableRipple
      [checked]="user.notificationsPreferences.smsEnabled"
      [disabled]="user.role.name === userRolesTypes.ReadOnly || !user.phone.smsOptIn"
      (change)="toggleNotification($event, 'sms', user)">
      <span>SMS:</span> {{ user.notificationsPreferences.smsEnabled ? 'On' : 'Off' }}
    </mat-slide-toggle>
  </div>

  <ng-template #optIn>
    <div>
      <mat-checkbox
        class="opt-in-checkbox -ml-2.5"
        [checked]="user.phone.smsOptIn"
        (change)="updateSMSOptIn($event.checked, user)">
        SMS Opt-in message:
      </mat-checkbox>
      <p class="font-nunito text-ui-dark text-sm mt-2">
        Checking the box indicates your consent to receive regular automated messages from Harmony at the provided phone
        number. You can Opt-Out and stop receiving automated messages by unchecking the box. Charges for messages and
        data may apply.
      </p>
    </div>
  </ng-template>
</div>
