import { AbstractControl, ValidationErrors } from '@angular/forms';
import {
  AttachmentPosition,
  Coordinates,
  DeviceAdditionalAttribute,
  DeviceAttributeType,
  DeviceData,
  EntityStatus,
  IncidentModel,
  NormalizedDeviceData,
  NormalizedIncident,
  SpaceplanTabType,
  UserModel,
  UserRolesTypes,
} from '@models';
import { saveAs } from 'file-saver';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';

export function removeSpacesValidator(control: AbstractControl): ValidationErrors | null {
  if (control && control.value && !control.value.trim().length) {
    return { noSpaceAllowed: true };
  }
  return null;
}

export const passwordValidationPattern: RegExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{12,}/;
export const emailValidationPattern: string = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$';
export const passwordPolicy = ['8009b138-6a61-4e12-85cc-7842b110acda'];
export const userPasswordPolicyId = '8009b138-6a61-4e12-85cc-7842b110acda';
export const userLockOutPolicyId = '5bbe300c-f2d6-47d2-8072-bf11f5af3656';
export const attributeNameValidationPattern: RegExp = /^[a-zA-Z0-9_\-]+$/;

export const normalizeDeviceItem = (
  device: DeviceData,
  locationName: string = '',
  locationId: string = ''
): NormalizedDeviceData => {
  return {
    deviceType: device.deviceModelInformation?.deviceType || '',
    floor: device?.spacePath?.[0]?.friendlyName || '',
    deviceName: device.friendlyName,
    floorId: device.spacePath?.[0]?.id || '',
    roomId: device.spacePath?.[1]?.id || '',
    integrationDeviceId: device.integrationDeviceId || '',
    building: locationName || device?.location?.friendlyName || '',
    buildingStatus: locationName || device?.location?.status || '',
    buildingId: locationId || device?.location?.id || device?.locationId || '',
    manufacturer: device?.deviceModelInformation?.make.name || '',
    model: device?.deviceModelInformation?.name || '',
    serialNumber: device?.physicalDeviceId || '',
    room: device?.spacePath?.[1]?.friendlyName || '',
    status: device.status,
    id: device.id,
    incidentCountByStatuses: device.incidentCountByStatuses,
    firmware: device.tags?.['firmware'] || '',
    ipAddress:
      device.additionalAttributes?.find(attribute => attribute.name === 'ipAddress')?.value ||
      device.tags?.['ipAddress'] ||
      '',
  };
};

export const StatusTextColor = {
  [EntityStatus.Active]: 'text-ui-green-light',
  [EntityStatus.Paused]: 'text-ui-dark',
  [EntityStatus.Archived]: 'text-ui-gray-125',
};

export const defaultSelectOption = {
  title: 'All',
  value: '',
};

export function isElementInViewport(element: HTMLElement, checkElemWidthPosition: boolean = false) {
  const elementRect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  return (
    elementRect.top >= 0 &&
    elementRect.bottom <= windowHeight &&
    (checkElemWidthPosition ? elementRect.left >= 0 : true) &&
    (checkElemWidthPosition ? elementRect.right <= windowWidth : true)
  );
}

export const capitalize = ([first, ...rest]: string) =>
  first ? first.toUpperCase() + rest.join('').toLowerCase() : '';

export enum IncidentColumn {
  INDEX = 'index',
  TICKET_NUMBER = 'ticketNumber',
  STATUS = 'status',
  FAULT_NAME = 'faultname',
  DEVICE_ID = 'deviceId',
  MANUFACTURER = 'manufacturer',
  MODEL = 'model',
  SERIAL_NUMBER = 'serialNumber',
  BUILDING = 'building',
  FLOOR = 'floor',
  ROOM = 'room',
  DEVICE_TYPE = 'deviceType',
  REPORTED = 'reported',
  RESOLVED = 'resolved',
}

export const staticIncidentColumnTitle: Record<string, string> = {
  [IncidentColumn.INDEX]: '№',
  [IncidentColumn.TICKET_NUMBER]: 'Ticket №',
};

export const incidentColumnTitle: Record<string, string> = {
  [IncidentColumn.STATUS]: 'Status',
  [IncidentColumn.FAULT_NAME]: 'Fault Name',
  [IncidentColumn.DEVICE_ID]: 'Device Name',
  [IncidentColumn.DEVICE_TYPE]: 'Device Type',
  [IncidentColumn.MANUFACTURER]: 'Manufacturer',
  [IncidentColumn.MODEL]: 'Model',
  [IncidentColumn.SERIAL_NUMBER]: 'Serial №',
  [IncidentColumn.BUILDING]: 'Building Name',
  [IncidentColumn.FLOOR]: 'Floor',
  [IncidentColumn.ROOM]: 'Room',
  [IncidentColumn.REPORTED]: 'Reported',
  [IncidentColumn.RESOLVED]: 'Resolved',
};

export const incidentColumnOptions = Object.keys(incidentColumnTitle).map(key => ({
  value: key,
  title: incidentColumnTitle[key],
}));

export const dayInMiliseconds = 24 * 60 * 60 * 1000;

export const subtractMonths = (date: Date, months: number) => {
  const dateCopy = new Date(date);

  dateCopy.setMonth(dateCopy.getMonth() - months);

  return dateCopy;
};

export const normalizeIncidents = (incidents: IncidentModel[]): NormalizedIncident[] => {
  return incidents.map(incident => ({
    id: incident.id,
    ticketNumber: `${incident.incidentNumber}`,
    status: incident.status,
    deviceId: incident.device.id,
    deviceName: incident.device.friendlyName,
    deviceType: incident.device.deviceModelInformation?.deviceType || '',
    manufacturer: incident.device.deviceModelInformation?.make.name || '',
    model: incident.device.deviceModelInformation?.name || '',
    serialNumber: incident.device.physicalDeviceId,
    reported: new Date(incident.createdTimestamp).toLocaleString('en-US'),
    assignedUser: '',
    buildingId: incident.device.location.id,
    building: incident.device.location.friendlyName,
    floorId: incident.device.spacePath?.[0].id || '',
    floor: incident.device.spacePath?.[0].friendlyName || '',
    roomId: incident.device.spacePath?.[1].id || '',
    room: incident.device.spacePath?.[1].friendlyName || '',
    resolved: incident.resolvedTimestamp ? new Date(incident.resolvedTimestamp).toLocaleString('en-US') : '',
    faultname: incident.fault.name,
  }));
};

export function getMonthsData(year: number) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthsData: Array<{ startDate: string; endDate: string }> = [];

  for (const month of months) {
    const startDate = new Date(year, months.indexOf(month), 1);
    const endDate = new Date(year, months.indexOf(month) + 1, 0);
    endDate.setHours(23, 59, 59, 999);

    monthsData.push({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
  }

  return monthsData;
}

export const getUserClient = (user: UserModel) =>
  user.role.name === UserRolesTypes.SuperAdmin
    ? user.userClients?.find(user => user.isManagingClient)?.id
    : user.userClients?.at(0)?.id;

export const exportDataToCSV = (dataToRender: Array<{ [key: string]: string | null }>, fileName: string) => {
  if (dataToRender.length) {
    const replacer = (_key: string, value: string | null) => (value === null ? '' : value); // specify how to handle null values
    const header = Object.keys(dataToRender[0]);
    const csv = dataToRender.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, `${fileName}.csv`);
  }
};

export const exportDataToJSON = (dataToRender: Array<{ [key: string]: string | null }>, fileName: string) => {
  if (dataToRender.length) {
    const blob = new Blob([JSON.stringify(dataToRender)], {
      type: 'application/json',
    });

    saveAs(blob, `${fileName}.json`);
  }
};

interface IMergeObject {
  id: string;

  [key: string]: any;
}

export function mergeArraysById(arr1: Array<IMergeObject>, arr2: Array<IMergeObject>) {
  const idMap = new Map();

  for (const obj of arr1) {
    idMap.set(obj.id, obj);
  }

  for (const obj of arr2) {
    const existingObj = idMap.get(obj.id);

    if (existingObj) {
      Object.assign(existingObj, obj);
    }
  }
  return [...idMap.values()];
}

export const getUniqueArrayItemsByProperty = <T>(list: Array<T>, key: keyof T) => {
  const map = new Map();

  for (const obj of list) {
    map.set(obj[key], obj);
  }

  return [...map.values()];
};

export const getDeviceAttributesByType = (attributes: DeviceAdditionalAttribute[], type: DeviceAttributeType) =>
  attributes.filter(attribute => attribute.attributeType === type);

export const getMapBoundariesByCountry = async (country: string): Promise<google.maps.GeocoderResult> => {
  const geocoder = new google.maps.Geocoder();
  const response = await geocoder.geocode({ address: country });
  return response.results[0];
};

export const naturalSorting = (data: any, sort: MatSort): any[] => {
  const active = sort.active;
  const direction = sort.direction;
  if (!active || direction == '') {
    return data;
  }
  return data.sort((a: any, b: any) => {
    let valueA = a[active];
    let valueB = b[active];
    if (sort.active === 'reported' || sort.active === 'resolved') {
      valueA = new Date(valueA).getTime() + '';
      valueB = new Date(valueB).getTime() + '';
    }

    const valueAType = typeof valueA;
    const valueBType = typeof valueB;

    // Not available fields check
    if (valueA === undefined || valueB === undefined) return -1;

    // Object sorting
    if (valueAType === 'object') {
      valueA = JSON.stringify(valueA).toLowerCase();
    }
    if (valueBType === 'object') {
      valueB = JSON.stringify(valueB).toLowerCase();
    }

    // To string numbers
    if (valueAType !== valueBType) {
      if (valueAType === 'number') {
        valueA += ''.toLowerCase();
      }
      if (valueBType === 'number') {
        valueB += ''.toLowerCase();
      }
    }

    // natural sorting logic
    let comparatorResult = valueA.localeCompare(valueB, undefined, {
      numeric: true,
      sensitivity: 'base',
    });

    // asc / desc implementation
    return comparatorResult * (direction == 'asc' ? 1 : -1);
  });
};

export const getTabMetadataFromImageObservable = (file: Blob): Observable<string | null> => {
  return new Observable<string | null>(observer => {
    const reader = new FileReader();
    reader.onload = () => {
      const parser = new DOMParser();
      const svgContent = reader.result as string;
      const xmlDoc = parser.parseFromString(svgContent, spaceplanImgType);
      const metadataElement = xmlDoc.querySelector('metadata#custom-metadata');
      const tabContent = metadataElement?.querySelector('tab')?.textContent || null;

      observer.next(tabContent);
      observer.complete();
    };
    reader.onerror = () => {
      observer.error(reader.error);
    };
    reader.readAsText(file);
  });
};

export const readFileAsync = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(new Error('Unable to read the file'));
    reader.readAsText(file);
  });
};

export const spaceplanImgType = 'image/svg+xml';

export const checkIsObject = (value: any) => {
  return typeof value === 'object' && value !== null;
};
export const checkIsArray = (value: any) => {
  return Array.isArray(value);
};

export const getTabMetadataFromImage = async (file: Blob): Promise<string | null> => {
  try {
    const svgContent = await readFileAsync(file);
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svgContent, spaceplanImgType);
    const metadataElement = xmlDoc.querySelector('metadata#custom-metadata');

    if (metadataElement) {
      return metadataElement.querySelector('tab')?.textContent || null;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const setTabMetadataToImage = async (file: Blob, tabValue: string): Promise<Blob | null> => {
  try {
    const svgContent = await readFileAsync(file);
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svgContent, spaceplanImgType);

    const existedMetadataTab = xmlDoc.querySelector('metadata#custom-metadata tab');

    if (existedMetadataTab) {
      existedMetadataTab.textContent = tabValue;
    } else {
      const metadata = xmlDoc.createElementNS('http://www.w3.org/2000/svg', 'metadata');
      metadata.setAttribute('id', 'custom-metadata');
      const tab = xmlDoc.createElement('tab');
      tab.textContent = tabValue;
      metadata.appendChild(tab);
      xmlDoc.documentElement.appendChild(metadata);
    }

    const serializer = new XMLSerializer();
    const updatedSvg = serializer.serializeToString(xmlDoc);

    return new Blob([updatedSvg], { type: spaceplanImgType });
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const spaceplanTabSelectOptions = [
  {
    value: SpaceplanTabType.AUDIO,
    title: 'Audio',
    disabled: false,
  },
  {
    value: SpaceplanTabType.CONTROL,
    title: 'Control',
    disabled: false,
  },
  {
    value: SpaceplanTabType.NETWORK,
    title: 'Network',
    disabled: false,
  },
  {
    value: SpaceplanTabType.VIDEO,
    title: 'Video',
    disabled: false,
  },
];

export const generateDocumentType = (value: string | number) => `type-${value}`;

export const defaultDocumentTab = 'new';

export const isSpaceplanTabType = (documentTab: string): documentTab is SpaceplanTabType =>
  Object.values(SpaceplanTabType).includes(documentTab as SpaceplanTabType);

export const getAttachmentPosition = (unstructuredDataReference: string): AttachmentPosition | null => {
  return unstructuredDataReference ? (JSON.parse(unstructuredDataReference) as AttachmentPosition) : null;
};

export const getCoordinatesByTab = (unstructuredDataReference: string, documentTab?: string): Coordinates | null => {
  const position = getAttachmentPosition(unstructuredDataReference);
  return documentTab && isSpaceplanTabType(documentTab)
    ? (position?.coordinates[documentTab] as Coordinates)
    : (position?.coordinates as Coordinates);
};
