import { Directive, Host, HostListener, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { isElementInViewport } from '@app-lib';
import { Subscription } from 'rxjs';
import { scrollIntoView } from 'seamless-scroll-polyfill';

@Directive({
  selector: '[appPaginatorSettings]',
})
export class PaginatorSettingsDirective implements OnDestroy {
  @Input() elementToScrollId = '';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  matPagSubs$: Subscription;

  constructor(@Host() @Self() @Optional() public readonly matPag: MatPaginator) {
    const pageSizePreset = [10, 25, 50, 100];
    matPag.pageSizeOptions = pageSizePreset;
    matPag.pageSize = Number(window.localStorage.getItem('paginatorSize')) || pageSizePreset[0];
    matPag.showFirstLastButtons = true;
    this.matPagSubs$ = matPag.page.subscribe(page => {
      window.localStorage.setItem('paginatorSize', JSON.stringify(page.pageSize));
    });
  }

  @HostListener('page', ['$event'])
  onPageChange() {
    if (!this.elementToScrollId) {
      return;
    }

    const element = document.getElementById(this.elementToScrollId);

    setTimeout(() => {
      if (element && !isElementInViewport(element)) {
        scrollIntoView(element, { behavior: 'smooth', block: 'start' });
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.matPagSubs$?.unsubscribe();
  }
}
