import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  COUNTRY_LIST,
  DEFAULT_COUNTRY,
  emailValidationPattern,
  passwordPolicy,
  removeSpacesValidator,
  userLockOutPolicyId,
} from '@app-lib';
import { Store } from '@ngrx/store';
import { addClientUser, AppState, clientUserIsLoading, getClient, selectUser } from '@ngrx-store';
import { Observable } from 'rxjs';
import { rolesRules, UserClient, UserModel, UserRolesIds } from '@models';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PhoneNumberClass } from '@abstraction';

@Component({
  selector: 'app-user-data-form',
  templateUrl: './user-data-form.component.html',
})
export class UserDataFormComponent extends PhoneNumberClass {
  protected readonly COUNTRY_LIST = COUNTRY_LIST;
  form: FormGroup;
  isLoading$: Observable<boolean>;
  roleOptions = rolesRules;
  currentClient: Observable<UserClient | undefined>;
  user: UserModel | undefined;

  get formIsValid(): boolean {
    return this.form.valid;
  }

  get formValues(): { [key: string]: string } {
    return this.form.value;
  }

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private appService: AppService
  ) {
    super();
    this.form = this.fb.group({
      firstName: ['', [Validators.required, Validators.min(3), removeSpacesValidator]],
      lastName: ['', [Validators.required, Validators.min(3), removeSpacesValidator]],
      email: ['', [Validators.required, Validators.pattern(emailValidationPattern)]],
      phone: this.fb.group({
        number: ['', [Validators.required]],
        countryCode: [DEFAULT_COUNTRY.code, [Validators.required]],
        countryNumberCode: [DEFAULT_COUNTRY.phoneCode, [Validators.required]],
      }),
      company: [''],
      passwordPolicyId: passwordPolicy,
      lockoutPolicyId: [userLockOutPolicyId],
      roleId: [UserRolesIds.ReadOnly, [Validators.required]],
      notificationsPreferences: this.fb.group({
        emailEnabled: [false, [Validators.required]],
        smsEnabled: [{ value: false, disabled: true }, [Validators.required]],
      }),
    });
    this.store
      .select(selectUser)
      .pipe(takeUntilDestroyed())
      .subscribe(user => (this.user = user));
    this.currentClient = this.store.select(getClient(this.appService.currentClient));
    this.isLoading$ = this.store.select(clientUserIsLoading);
  }

  submitForm() {
    this.form.markAllAsTouched();
    if (this.formIsValid) {
      const countryCode = this.form.get('phone.countryCode');
      this.form
        .get('phone.countryNumberCode')
        ?.setValue(
          COUNTRY_LIST.find(country => country.code === countryCode?.value)?.phoneCode || DEFAULT_COUNTRY.phoneCode
        );
      const clientId = this.route.snapshot.queryParams['clientId'];
      this.store.dispatch(addClientUser({ newUserData: this.form.value, clientId }));
    }
  }
}
