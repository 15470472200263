import { Component, DestroyRef, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GoogleMap, MapAdvancedMarker, MapAnchorPoint, MapInfoWindow } from '@angular/google-maps';
import { DEFAULT_COUNTRY, getMapBoundariesByCountry } from '@app-lib';
import { LocationData, EntityStatus, IncidentStatus } from '@models';
import { AppState, selectAllLocations } from '@ngrx-store';
import { Store, select } from '@ngrx/store';
import { MapFilterService } from '@services';
import { NgForOf, NgIf } from '@angular/common';
import { PipesModule } from '@pipes';
import { SiteMapMarkerComponent } from '@standalone/site-map-marker/site-map-marker.component';

type DashboardMapMarker = LocationData & {
  options: google.maps.marker.AdvancedMarkerElementOptions;
  position: google.maps.LatLngLiteral;
};

@Component({
  selector: 'app-dashboard-map',
  standalone: true,
  templateUrl: './dashboard-map.component.html',
  imports: [GoogleMap, NgIf, MapAdvancedMarker, PipesModule, MapInfoWindow, NgForOf, SiteMapMarkerComponent],
})
export class DashboardMapComponent {
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
  destroyRef = inject(DestroyRef);
  selectedSite: LocationData | null = null;
  locations: LocationData[] = [];

  mapOptions: google.maps.MapOptions = {
    mapId: '2a6238c26271516b',
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
  };
  markers: Array<DashboardMapMarker> = [];
  filteredMarkers: Array<DashboardMapMarker> = [];
  map: google.maps.Map | undefined = undefined;

  constructor(private store: Store<AppState>, public mapFilterService: MapFilterService) {
    this.store
      .pipe(select(selectAllLocations))
      .pipe(takeUntilDestroyed())
      .subscribe(locations => {
        this.locations = locations;
        this.createMarkers();
      });
  }

  isValidLatitude = (lat: number) => isFinite(lat) && Math.abs(lat) <= 90;

  isValidLongitude = (lng: number) => isFinite(lng) && Math.abs(lng) <= 180;

  mapInitialized(map: google.maps.Map) {
    this.map = map;
    this.createMarkers();
    this.mapFilterService.mapFilter$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(selectedFilters => {
      this.filterMarkers(selectedFilters);
    });
  }

  createMarkers() {
    if (this.map) {
      const bounds = new google.maps.LatLngBounds();
      const markers: Array<DashboardMapMarker> = [];

      this.locations.forEach(location => {
        if (
          !this.isValidLatitude(location.geographic.lat) ||
          !this.isValidLongitude(location.geographic.long) ||
          location.status === EntityStatus.Archived
        ) {
          return;
        }
        const latLng = new google.maps.LatLng(location.geographic.lat, location.geographic.long);
        const marker = {
          ...location,
          position: latLng.toJSON(),
          options: {
            // animation: google.maps.Animation.DROP,
          },
        };
        markers.push(marker);
        bounds.extend(latLng);
      });
      this.markers = markers;
      this.filterMarkers(this.mapFilterService.mapFilter);

      if (this.markers.length > 1) {
        this.map.fitBounds(bounds);
      }

      if (this.markers.length === 1) {
        this.map.setCenter(bounds.getCenter());
        this.map.setZoom(13);
      }

      if (!this.markers.length) {
        this.initCountryOnMap();
      }
    }
  }

  async initCountryOnMap() {
    const geocodeResult = await getMapBoundariesByCountry(DEFAULT_COUNTRY.name);

    if (geocodeResult.geometry.bounds) {
      this.map?.fitBounds(geocodeResult.geometry.bounds);
    }
  }

  openInfo(marker: MapAdvancedMarker, site: LocationData, window: Element) {
    const element: MapAnchorPoint = {
      getAnchor() {
        return marker.advancedMarker;
      },
    };
    if (site.devicesByStatuses && site.incidentCountByStatuses) {
      this.selectedSite = site;
      this.infoWindow.open(element, false, window);
    }
  }

  hideInfo() {
    this.infoWindow.close();
  }

  filterMarkers(selectedMapFilters: Array<string>) {
    this.filteredMarkers =
      selectedMapFilters.length > 0
        ? this.markers.filter(marker => {
            let pausedMatch = false;
            let newIncidentsMatch = false;
            let inProgressIncidentsMatch = false;
            let goodMatch = false;

            if (!marker.incidentCountByStatuses) {
              return true;
            }

            if (selectedMapFilters.includes(EntityStatus.Paused)) {
              pausedMatch = marker.status === EntityStatus.Paused;
            }
            if (selectedMapFilters.includes(IncidentStatus.IN_QUEUE) && marker.status === EntityStatus.Active) {
              newIncidentsMatch = marker.incidentCountByStatuses.newCount > 0;
            }
            if (selectedMapFilters.includes(IncidentStatus.IN_PROGRESS) && marker.status === EntityStatus.Active) {
              inProgressIncidentsMatch =
                marker.incidentCountByStatuses.inProgressCount > 0 && !marker.incidentCountByStatuses.newCount;
            }
            if (selectedMapFilters.includes('good')) {
              goodMatch =
                marker.status === EntityStatus.Active &&
                !marker.incidentCountByStatuses.inProgressCount &&
                !marker.incidentCountByStatuses.newCount;
            }
            return pausedMatch || newIncidentsMatch || inProgressIncidentsMatch || goodMatch;
          })
        : this.markers;
  }
}
