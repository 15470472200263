import { AfterContentInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIconButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { PipesModule } from '@pipes';
import { TippyDirective } from '@ngneat/helipopper';
import { SessionStorageService } from '@services';
import { TableSelectedItem } from '@models';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-table-search-input',
  templateUrl: './table-search-input.component.html',
  standalone: true,
  styleUrls: ['./table-search-input.component.scss'],
  imports: [
    NgIf,
    MatPrefix,
    MatSuffix,
    MatIconButton,
    FormsModule,
    MatFormField,
    MatInput,
    NgClass,
    PipesModule,
    TippyDirective,
    RouterLink,
  ],
})
export class TableSearchInputComponent implements AfterContentInit {
  @ViewChild('input', { read: TippyDirective }) tippyButton!: TippyDirective;
  @Input() tableId!: string;
  @Input() placeholder = 'Enter Search text';
  @Input() inputValue = '';
  @Input() inputWidth = '256px';
  @Input() cssClass = '';
  @Output() searchDataEvent = new EventEmitter<string>();
  lastPickedItems: TableSelectedItem[] = [];

  constructor(private sessionStorageService: SessionStorageService) {}

  clear() {
    this.inputValue = '';
    this.searchDataEvent.emit('');
  }

  modelChange(value: string) {
    this.searchDataEvent.emit(value.trim());
    value.length ? this.tippyButton.hide() : this.tippyButton.show();
  }

  ngAfterContentInit(): void {
    if (this.tableId) {
      this.lastPickedItems = (this.sessionStorageService.getLastSelectedItems(this.tableId) || []).slice(0, 5);
    }
  }
}
