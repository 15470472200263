import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FloorSpaceModel, LocationFullData, SpaceType } from '@models';
import { removeSpacesValidator } from '@app-lib';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, LocationActions, addFloor, isFloorLoading, locationState, updateFloor } from '@ngrx-store';
import { Actions, ofType } from '@ngrx/effects';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-add-floors',
  templateUrl: './add-floors.component.html',
  styles: [],
})
export class AddFloorsComponent implements OnDestroy {
  form!: FormGroup;
  floorSearch = '';
  openedFloorId: null | string = null;
  locationFullData: LocationFullData | undefined;
  locationDataSub$: Subscription;
  floorSuccessSub$: Subscription;
  floorIsLoading$!: Observable<boolean>;
  floorStatus: 'current' | 'archived' = 'current';

  constructor(private fb: FormBuilder, private store: Store<AppState>, actions$: Actions) {
    this.form = this.fb.group({
      friendlyName: ['', [removeSpacesValidator]],
    });

    this.locationDataSub$ = this.store.select(locationState).subscribe(location => {
      this.locationFullData = location;
    });

    this.floorIsLoading$ = this.store.select(isFloorLoading);

    this.floorSuccessSub$ = actions$.pipe(ofType(LocationActions.addFloorSuccess)).subscribe(() => {
      this.form.reset({ friendlyName: '' });
      this.form.markAsPristine();
    });
  }

  ngOnDestroy() {
    this.locationDataSub$?.unsubscribe();
    this.floorSuccessSub$?.unsubscribe();
  }

  submit() {
    const friendlyName = this.form.value.friendlyName.trim();
    if (this.locationFullData) {
      this.store.dispatch(
        addFloor({
          locationId: this.locationFullData.id,
          data: { friendlyName, type: SpaceType.floor },
        })
      );
    }
  }

  floorSearchChanged(searchData: string) {
    this.floorSearch = searchData;
  }

  onFloorStatusFilterChange(change: MatButtonToggleChange) {
    this.floorStatus = change.value;
  }

  editFloor(data: Partial<FloorSpaceModel>, floorId: string) {
    if (this.locationFullData) {
      this.store.dispatch(
        updateFloor({
          locationId: this.locationFullData.id,
          spaceId: floorId,
          data,
        })
      );
    }
  }
}
