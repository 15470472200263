import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms';
import { capitalize, removeSpacesValidator } from '@app-lib';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogConfig,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {
  addNewManufacturer,
  AppState,
  DevicesActions,
  getDeviceManufacturers,
  getMyClient,
  isManufacturerCreating,
} from '@ngrx-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { AsyncPipe, NgIf } from '@angular/common';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectOption, UserClient } from '@models';

@Component({
  selector: 'app-add-manufacturer-dialog',
  templateUrl: './add-manufacturer-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogClose,
    MatError,
    ReactiveFormsModule,
    MatInput,
    MatFormField,
    MatDialogContent,
    MatDialogActions,
    MatProgressSpinner,
    AsyncPipe,
    NgIf,
  ],
})
export class AddManufacturerDialogComponent {
  form: FormGroup;
  isLoading$: Observable<boolean | undefined>;
  client: UserClient | undefined;
  manufacturerOptions: SelectOption[] = [];
  @ViewChild('ngForm') ngForm: FormGroupDirective | undefined;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddManufacturerDialogComponent>,
    private store: Store<AppState>,
    actions$: Actions
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required, removeSpacesValidator]],
    });
    this.isLoading$ = this.store.select(isManufacturerCreating);
    actions$.pipe(ofType(DevicesActions.addNewManufacturerSuccess), takeUntilDestroyed()).subscribe(() => {
      this.ngForm?.resetForm();
    });
    this.store
      .select(getMyClient)
      .pipe(takeUntilDestroyed())
      .subscribe(client => (this.client = client));

    this.store
      .select(getDeviceManufacturers)
      .pipe(takeUntilDestroyed())
      .subscribe(manufacturers => {
        if (manufacturers.length) {
          this.manufacturerOptions = manufacturers.map(({ id, name }) => ({ title: name, value: id }));
        }
      });
  }

  addManufacturer() {
    if (this.form.valid && this.client) {
      if (this.checkManufacturerAlreadyExist()) {
        this.form.controls['name'].setErrors({ alreadyExist: true });
        return;
      }

      this.store.dispatch(
        addNewManufacturer({
          clientId: this.client.id,
          newManufacturerData: { name: capitalize(this.form.value.name) },
        })
      );
    } else {
      this.form.markAsTouched();
    }
  }

  checkManufacturerAlreadyExist() {
    return this.manufacturerOptions.some(({ title }) => title.toLowerCase() === this.form.value.name.toLowerCase());
  }
}

export function openAddManufacturerDialog(dialog: MatDialog) {
  const config = new MatDialogConfig();
  config.disableClose = true;
  config.panelClass = 'app-dialog';
  config.backdropClass = 'backdrop-modal-panel';
  const dialogRef = dialog.open(AddManufacturerDialogComponent, config);
  return dialogRef.afterClosed();
}
