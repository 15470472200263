import { ChangeDetectorRef, Component, DestroyRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { ChildClient, UserStatus } from '@models';
import { Store } from '@ngrx/store';
import { AppState, clientIdChanged, selectChildClients, updateChildClient } from '@ngrx-store';
import { AppService } from '@services';
import { Router, RouterLink } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { naturalSorting } from '@app-lib';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { MatIcon } from '@angular/material/icon';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { UserStatusComponent } from '@standalone/user-status/user-status.component';
import { MatTooltip } from '@angular/material/tooltip';
import { DirectivesModule, VirtualTableScrollNormalizerDirective } from '@directives';
import { CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  standalone: true,
  selector: 'app-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.scss'],
  imports: [
    MatIcon,
    RouterLink,
    PageHeaderComponent,
    SkeletonTableComponent,
    MatPaginator,
    NgClass,
    MatSortHeader,
    NoDataComponent,
    MatCard,
    MatCardContent,
    MatButtonToggleGroup,
    MatButtonToggle,
    SearchInputComponent,
    MatSort,
    MatTable,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderCell,
    MatColumnDef,
    MatCell,
    UserStatusComponent,
    MatTooltip,
    NgIf,
    MatHeaderRow,
    MatRow,
    MatHeaderRowDef,
    MatRowDef,
    DirectivesModule,
    DatePipe,
    CdkFixedSizeVirtualScroll,
    CdkVirtualScrollViewport,
    VirtualTableScrollNormalizerDirective,
  ],
})
export class ClientsTableComponent implements OnInit {
  protected readonly UserStatus = UserStatus;
  @Input() filters: Record<string, string[] | string | boolean> = {};
  @Input() tableId: string | null = null;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumns: string[] = ['index', 'friendlyName', 'status', 'created', 'actions'];
  dataSource: MatTableDataSource<ChildClient> = new MatTableDataSource();
  clientStatusFilter: 'current' | 'archived' = 'current';
  search = '';
  destroyRef = inject(DestroyRef);

  @ViewChild('tableContent') set paginatorBlock(element: MatPaginator) {
    if (element) {
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.filterLogic;
      this.filterTable();
      this.cdr.detectChanges();
    }
  }

  filterLogic(record: ChildClient, filter: string) {
    const filterData = JSON.parse(filter);
    const statusMatch =
      filterData.userStatusFilter === 'archived'
        ? record.status === UserStatus.ARCHIVED
        : record.status !== UserStatus.ARCHIVED;
    const nameMatch =
      !filterData.userTextFilter || record.friendlyName.toLowerCase().includes(filterData.userTextFilter.toLowerCase());

    return statusMatch && nameMatch;
  }

  clientSearch(value: string) {
    this.search = value;
    this.filterTable();
  }

  constructor(
    private store: Store<AppState>,
    private appService: AppService,
    private router: Router,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {
    this.store
      .select(selectChildClients)
      .pipe(takeUntilDestroyed())
      .subscribe(clientList => {
        this.dataSource = new MatTableDataSource(clientList);
        this.dataSource.sortData = naturalSorting;
      });
  }

  ngOnInit() {
    this.filterTable();
  }

  archiveClient(client: ChildClient) {
    this.openConfirmationDialog({
      clientId: client.id,
      title: 'Archive client',
      description: `Do you really want to archive ${client.friendlyName} ?`,
      data: { isArchived: true },
    });
  }

  unarchiveClient(client: ChildClient) {
    this.openConfirmationDialog({
      clientId: client.id,
      title: 'Unarchive client',
      description: `Do you really want to unarchive ${client.friendlyName} ?`,
      data: { isArchived: false },
    });
  }

  activateClient(client: ChildClient) {
    this.openConfirmationDialog({
      clientId: client.id,
      title: 'Activate client',
      description: `Do you really want to activate ${client.friendlyName} ?`,
      data: { isDisabled: false },
    });
  }

  deactivateClient(client: ChildClient) {
    this.openConfirmationDialog({
      clientId: client.id,
      title: 'Deactivate client',
      description: `Do you really want to deactivate ${client.friendlyName} ?`,
      data: { isDisabled: true },
    });
  }

  selectClient(clientId: string) {
    this.appService.currentClient = clientId;
    this.store.dispatch(clientIdChanged());
    this.router.navigateByUrl(`/client-management/client-details/${clientId}`);
  }

  openConfirmationDialog({
    clientId,
    title,
    description,
    data,
  }: {
    clientId: string;
    title: string;
    description: string;
    data: Partial<ChildClient>;
  }) {
    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(
            updateChildClient({
              clientClientId: clientId,
              clientData: data,
            })
          );
        }
      });
  }

  onStatusFilterChange(value: 'current' | 'archived') {
    if (value) {
      this.clientStatusFilter = value;
      this.filterTable();
    }
  }

  filterTable() {
    this.dataSource.filter = JSON.stringify({
      userStatusFilter: this.clientStatusFilter,
      userTextFilter: this.search,
    });
  }
}
