import { Directive, Self } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: '[appNoSpaceSelect]',
})
export class NoSpaceSelectDirective {
  constructor(@Self() private select: MatSelect) {
    const originalHandleKeydown = this.select._handleKeydown;

    this.select._handleKeydown = (event: KeyboardEvent) => {
      if (event.key == ' ') return;

      originalHandleKeydown.call(this.select, event);
    };
  }
}
