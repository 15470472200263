import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatPrefix } from '@angular/material/form-field';

@Component({
  selector: 'app-copyright-footer',
  templateUrl: './copyright-footer.component.html',
  styles: [],
  standalone: true,
  imports: [MatIcon, MatPrefix],
})
export class CopyrightFooterComponent {
  @Input() isMobile = false;
  year = new Date().getFullYear();
}
