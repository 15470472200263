import { Output } from '@angular/core';
import { Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIconButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  standalone: true,
  styleUrls: ['./search-input.component.scss'],
  imports: [NgIf, MatPrefix, MatSuffix, MatIconButton, FormsModule, MatFormField, MatInput, NgClass],
})
export class SearchInputComponent {
  @Input() placeholder = 'Enter Search text';
  @Input() inputValue = '';
  @Input() inputWidth = '256px';
  @Input() cssClass = '';
  @Output() searchDataEvent = new EventEmitter<string>();

  clear() {
    this.inputValue = '';
    this.searchDataEvent.emit('');
  }

  modelChange(value: string) {
    this.searchDataEvent.emit(value.trim());
  }
}
