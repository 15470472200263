import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@ngrx-store';
import { loadClientUsers } from './client-users.actions';
import { AppService } from '@services';

export const clientUsersResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
  const clientId = route.queryParams['clientId'] || inject(AppService).currentClient;
  return inject(Store<AppState>).dispatch(loadClientUsers({ clientId }));
};
