import { defaultSelectOption } from '@app-lib';
import { IncidentModel, IncidentStatus } from '@models';
import { mockDeviceData } from './devices';

export const mockIncidents: IncidentModel[] = [
  {
    id: '1',
    assetId: '1',
    clientId: '1',
    createdTimestamp: '2022-12-27, 2:45:01',
    description: 'test incident 1',
    device: mockDeviceData[0],
    fault: {
      name: 'No Connection',
      description: 'Device is disconnected',
    },
    inProgressSubStatus: null,
    incidentNumber: 100,
    lastUpdatedTimestamp: '2022-12-27, 2:45:01',
    resolvedTimestamp: null,
    severity: 'medium',
    status: IncidentStatus.IN_QUEUE,
  },
  {
    id: '2',
    assetId: '2',
    clientId: '1',
    createdTimestamp: '2022-12-27, 2:45:01',
    description: 'test incident 2',
    device: mockDeviceData[0],
    fault: {
      name: 'No Connection',
      description: 'Device is disconnected',
    },
    inProgressSubStatus: null,
    incidentNumber: 101,
    lastUpdatedTimestamp: '2022-12-27, 2:45:01',
    resolvedTimestamp: null,
    severity: 'medium',
    status: IncidentStatus.IN_PROGRESS,
  },
  {
    id: '3',
    assetId: '3',
    clientId: '1',
    createdTimestamp: '2022-12-27, 2:45:01',
    description: 'test incident 3',
    device: mockDeviceData[0],
    fault: {
      name: 'No Connection',
      description: 'Device is disconnected',
    },
    inProgressSubStatus: null,
    incidentNumber: 102,
    lastUpdatedTimestamp: '2022-12-27, 2:45:01',
    resolvedTimestamp: null,
    severity: 'medium',
    status: IncidentStatus.RESOLVED,
  },
];

export const deviceTypeOptionsMock = [
  {
    value: 'schedulingPanel',
    title: 'Scheduling Panel',
  },
  {
    value: 'camera',
    title: 'Camera',
  },
  {
    value: 'scanner',
    title: 'Scanner',
  },
  {
    value: 'printer',
    title: 'Printer',
  },
  {
    value: 'modem',
    title: 'Modem',
  },
];

export const assignedUserOptionsMock = [
  {
    value: '1',
    title: 'Alex Danovich',
  },
  {
    value: '2',
    title: 'John Doe',
  },
  {
    value: '3',
    title: 'Courtney Henry',
  },
  {
    value: '4',
    title: 'Leslie Alexander',
  },
  {
    value: '5',
    title: 'Ralph Edwards',
  },
];

export const incidentStatusMock = [
  {
    value: IncidentStatus.IN_QUEUE,
    title: 'In queue',
  },
  {
    value: IncidentStatus.IN_PROGRESS,
    title: 'In progress',
  },
];

export const buildingMock = [
  defaultSelectOption,
  {
    value: '1',
    title: 'Building 1',
  },
  {
    value: '2',
    title: 'Building 2',
  },
  {
    value: '3',
    title: 'Building 3',
  },
  {
    value: '4',
    title: 'Building 4',
  },
  {
    value: '5',
    title: 'Building 5',
  },
];

export const floorMock = [
  defaultSelectOption,
  {
    value: '1',
    title: 'Floor 1',
  },
  {
    value: '2',
    title: 'Floor 2',
  },
  {
    value: '3',
    title: 'Floor 3',
  },
  {
    value: '4',
    title: 'Floor 4',
  },
  {
    value: '5',
    title: 'Floor 5',
  },
];

export const roomMock = [
  defaultSelectOption,
  {
    value: '1',
    title: 'Room 1',
  },
  {
    value: '2',
    title: 'Room 2',
  },
  {
    value: '3',
    title: 'Room 3',
  },
  {
    value: '4',
    title: 'Room 4',
  },
  {
    value: '5',
    title: 'Room 5',
  },
];

export const faultMock = [
  {
    value: '1',
    title: 'No Connection',
  },
  {
    value: '2',
    title: 'No Signal',
  },
  {
    value: '3',
    title: 'Heartbeat Absent',
  },
  {
    value: '4',
    title: 'No Video',
  },
  {
    value: '5',
    title: 'Device Offline',
  },
];

export const firmwareMock = [
  {
    value: '1',
    title: 'T-KM2ELAKUC',
  },
  {
    value: '2',
    title: 'R-KM2ELAKUC',
  },
  {
    value: '3',
    title: 'M-KM2ELAKUC',
  },
  {
    value: '4',
    title: 'V-KM2ELAKUC',
  },
  {
    value: '5',
    title: 'S-KM2ELAKUC',
  },
];
