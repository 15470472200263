export type SelectOption = {
  title: string;
  value: string;
};

export interface TableSelectedItem {
  title: string;
  url: string[];
}

export interface SavedTableSettings {
  columns: string[];
  zoomSize: number;
  lastSelectedItems: TableSelectedItem[];
  filters: {
    [key: string]: string | string[];
  };
}

export const DefaultSavedTableSettings: SavedTableSettings = {
  columns: [],
  zoomSize: 100,
  lastSelectedItems: [],
  filters: {},
};

export enum ScreenOrientations {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

export type SelectExtendedOption = SelectOption & {
  checked: boolean;
};

export type CustomHTTPResponseError = {
  name: string;
  status: number;
  message: string;
};

export enum InstanceTypes {
  DEV = 'dev',
  STAGE = 'stage',
  PROD = 'prod',
  ON_PREM = 'on prem',
}
