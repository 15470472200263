<mat-form-field class="search-input" [ngClass]="cssClass" [style]="{ width: inputWidth }">
  <i matPrefix class="icon-search text-ui-gray-light text-2xl"></i>
  <input
    #input
    [value]="inputValue"
    [(ngModel)]="inputValue"
    (ngModelChange)="modelChange($event)"
    type="text"
    matInput
    placeholder="{{ placeholder }}" />
  <button *ngIf="input.value" matSuffix mat-icon-button aria-label="Clear" class="test-clear-button" (click)="clear()">
    <i class="icon-close text-ui-dark text-3xl"></i>
  </button>
</mat-form-field>
