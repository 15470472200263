import { createAction, props } from '@ngrx/store';
import { ChildClient } from '@models';

export const addChildClient = createAction(
  '[Client Management] Add Child Client User',
  props<{ newUserData: ChildClient }>()
);
export const addChildClientSuccess = createAction(
  '[Client Management] Add Child Client User Success',
  props<{ newClient: ChildClient }>()
);

export const updateChildClient = createAction(
  '[Client Management] Update Child Client',
  props<{ clientClientId: string; clientData: Partial<ChildClient> }>()
);
export const updateChildClientSuccess = createAction(
  '[Client Management] Update Child Client Success',
  props<{ childClient: ChildClient }>()
);

export const loadChildClients = createAction('[Client Management] Load child client list');
export const loadChildClientsSuccess = createAction(
  '[Client Management] Load child client list success',
  props<{ clientList: ChildClient[] }>()
);

export const clientManagementError = createAction('[Client Management] Request Error', props<{ error: ErrorEvent }>());
