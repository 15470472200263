import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-no-data',
  template: `
    <div class="flex items-center justify-center p-3" [class.py-60]="hasBigPadding">
      <span class="test-result-block font-nunito text-sm font-normal text-ui-gray-100">{{
        resultContent ? 'No Results' : noDataText
      }}</span>
    </div>
  `,
  styles: [],
})
export class NoDataComponent {
  @Input() resultContent = false;
  @Input() noDataText = 'No Data';
  @Input() hasBigPadding = false;
}
