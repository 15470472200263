import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { AuthService } from '@services';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MainLogoComponent } from '@standalone/main-logo/main-logo.component';
import { SignInContainerComponent } from './containers/sign-in-container/sign-in-container.component';
import { PasswordRecoveryContainerComponent } from './containers/password-recovery-container/password-recovery-container.component';
import { ForgotPasswordContainerComponent } from './containers/forgot-password-container/forgot-password-container.component';
import { SignInComponent } from '@standalone/sign-in/sign-in.component';
import { ForgotPasswordComponent } from '@standalone/forgot-password/forgot-password.component';
import { PasswordRecoveryComponent } from '@standalone/password-recovery/password-recovery.component';
import { AuthGuard, LoginGuard } from '@app-lib';

@NgModule({
  declarations: [SignInContainerComponent, ForgotPasswordContainerComponent, PasswordRecoveryContainerComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: SignInContainerComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordContainerComponent,
      },
      {
        path: 'forgot-password/:state',
        component: ForgotPasswordContainerComponent,
      },
      {
        path: 'password-recovery',
        component: PasswordRecoveryContainerComponent,
      },
      {
        path: 'password-recovery/:state',
        component: PasswordRecoveryContainerComponent,
      },
    ]),
    NgOptimizedImage,
    MatIconModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MainLogoComponent,
    SignInComponent,
    ForgotPasswordComponent,
    PasswordRecoveryComponent,
  ],
  providers: [AuthService],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [AuthService, AuthGuard, LoginGuard],
    };
  }
}
