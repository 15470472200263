<div *ngIf="user; else loading">
  <div *ngIf="editor$ | async as editor">
    <div class="flex items-center justify-between test-header pb-6">
      <div>
        <app-page-header>{{ user.firstName }} {{ user.lastName }}</app-page-header>
        <app-breadcrumbs [routeNames]="[user.firstName + ' ' + user.lastName]" />
      </div>

      <div appRestrictedEl [showForClientAdmin]="true" *ngIf="editor.id !== user.id" class="flex items-center">
        <button
          class="btn-blue btn-medium mr-3"
          [disabled]="(resendInvitationLoading$ | async) === true"
          (click)="resendInvitation(user)"
          *ngIf="user.status === UserStatus.INVITED">
          <mat-spinner *ngIf="(resendInvitationLoading$ | async) === true" diameter="20" class="mr-2" />
          <i class="icon-resend-email text-3xl mr-2"></i>
          Resend Invitation
        </button>
        <button class="btn-gray btn-medium" (click)="archive(user)" *ngIf="user.status !== UserStatus.ARCHIVED">
          <i class="icon-add-to-archive text-3xl mr-2"></i>
          Archive
        </button>
        <button class="btn-green btn-medium" (click)="restore(user)" *ngIf="user.status === UserStatus.ARCHIVED">
          <i class="icon-restore text-3xl mr-2"></i>
          Restore
        </button>
      </div>
    </div>
    <app-user-details [editor]="editor" [user]="user" />
  </div>
</div>

<ng-template #loading>
  <app-skeleton-page-header [buttonCount]="0" />
  <ngx-skeleton-loader [theme]="{ height: '600px' }" />
</ng-template>
