import { ChangeDetectorRef, Component, DestroyRef, Input, OnChanges, OnDestroy, inject } from '@angular/core';
import * as Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { DeviceAttributeType, DeviceChart, DeviceData } from '@models';
import { AppService, DeviceService } from '@services';
import { Subscription } from 'rxjs';
import { createPredefinedValuesChart, createArbitraryValueChart, getDeviceAttributesByType } from '@app-lib';
import { MatButtonToggle, MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MatCard } from '@angular/material/card';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HighchartsChartModule } from 'highcharts-angular';

type PeriodFilter = 'day' | 'week' | 'month';
export enum PeriodFilterEnum {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

Highcharts.setOptions({
  global: {
    // @ts-ignore
    getTimezoneOffset: function () {
      return new Date().getTimezoneOffset();
    },
  },
});

NoDataToDisplay(Highcharts);

@Component({
  standalone: true,
  selector: 'app-device-stats-chart',
  templateUrl: './device-stats-chart.component.html',
  imports: [
    MatButtonToggleGroup,
    MatButtonToggle,
    MatIcon,
    NgIf,
    MatProgressSpinner,
    MatSuffix,
    MatOption,
    MatSelect,
    MatFormField,
    MatCard,
    NgxSkeletonLoaderModule,
    HighchartsChartModule,
    NgForOf,
    MatPrefix,
    NgClass,
  ],
})
export class DeviceStatsChartComponent implements OnDestroy, OnChanges {
  @Input() device: DeviceData | undefined;
  @Input() isMobile = false;
  destroyRef = inject(DestroyRef);
  periodFilterValue: PeriodFilter = PeriodFilterEnum.DAY;
  Highcharts: typeof Highcharts = Highcharts;
  updateFromInput = false;

  deviceChartsSubs$!: Subscription;
  deviceCharts: DeviceChart[] | null = null;
  isLoading = false;
  clientId = this.appService.currentClient;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  chartData: any = null;
  chartReadyToRender = false;
  locationId!: string | undefined;
  selectedChart = '';
  chartOptions: Array<{ value: string; title: string }> = [];
  isChartFirstTimeLoaded = false;

  constructor(private deviceService: DeviceService, private appService: AppService, private cdr: ChangeDetectorRef) {}

  logChartInstance(chart: Highcharts.Chart) {
    this.chartReadyToRender = true;
    this.cdr.detectChanges();
    return chart;
  }

  changePeriod(event: MatButtonToggleChange) {
    this.periodFilterValue = event.value;
    this.loadCharts();
  }

  getDateRange() {
    const now = new Date();
    const end = new Date();

    if (this.periodFilterValue === PeriodFilterEnum.DAY) {
      end.setDate(now.getDate() - 1);
    }

    if (this.periodFilterValue === PeriodFilterEnum.WEEK) {
      end.setDate(now.getDate() - 7);
    }

    if (this.periodFilterValue === PeriodFilterEnum.MONTH) {
      end.setDate(now.getDate() - 30);
    }

    return { fromDateTimeUtc: end.toISOString(), toDateTimeUtc: now.toISOString() };
  }

  loadCharts() {
    if (this.device?.additionalAttributes) {
      const points = getDeviceAttributesByType(this.device.additionalAttributes, DeviceAttributeType.REALTIME).map(
        point => ({ name: point.name })
      );
      const { fromDateTimeUtc, toDateTimeUtc } = this.getDateRange();
      this.locationId = this.device.location?.id || this.device.locationId;
      if (this.locationId) {
        this.isLoading = true;
        this.deviceChartsSubs$ = this.deviceService
          .getChartsData({
            clientId: this.clientId,
            deviceId: this.device.id,
            data: {
              fromDateTimeUtc,
              toDateTimeUtc,
              points,
            },
          })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: charts => {
              this.isChartFirstTimeLoaded = true;
              this.deviceCharts = null;
              this.chartData = null;
              this.isLoading = false;

              setTimeout(() => {
                this.deviceCharts = charts;
                this.generateChartData();
              }, 0);
            },
            error: () => {
              this.isLoading = false;
            },
          });
      }
    }
  }

  createChartSelectOptions() {
    const realTimePoints = getDeviceAttributesByType(
      this.device?.additionalAttributes || [],
      DeviceAttributeType.REALTIME
    );
    if (realTimePoints?.length) {
      this.chartOptions = realTimePoints.map(point => ({
        value: point.name,
        title: point.friendlyName?.trim() || point.name,
      }));
      const connectedSelect = this.chartOptions.findIndex(opt => opt.value === 'connected');
      this.selectedChart = this.chartOptions[connectedSelect >= 0 ? connectedSelect : 0]?.value;
    }
  }

  chartChange(value: string) {
    this.selectedChart = value;
    this.generateChartData();
  }

  generateChartData() {
    if (this.deviceCharts) {
      const currentChart = this.deviceCharts.find(({ name }) => name === this.selectedChart);

      if (currentChart) {
        this.chartData = null;
        setTimeout(() => {
          if (!currentChart.unitOFMeasure.isStep) {
            this.chartData = createArbitraryValueChart(currentChart);
          }

          if (currentChart.unitOFMeasure.isStep) {
            this.chartData = createPredefinedValuesChart(currentChart);
          }
        }, 0);
      }
    }
  }

  refreshCharts() {
    this.loadCharts();
  }

  ngOnDestroy() {
    this.deviceChartsSubs$?.unsubscribe();
  }

  ngOnChanges() {
    if (!this.isChartFirstTimeLoaded) {
      this.createChartSelectOptions();
      this.loadCharts();
    }
  }

  protected readonly PeriodFilterEnum = PeriodFilterEnum;
}
