<div>
  <app-skeleton-page-header />
  <section class="flex flex-row align-top max-h-[100%]">
    <div class="pr-4 w-[320px]">
      <ngx-skeleton-loader [theme]="{ height: '300px' }" />
    </div>
    <div class="flex-grow">
      <ngx-skeleton-loader [theme]="{ height: '120px' }" />
      <ngx-skeleton-loader [theme]="{ height: '40px' }" />
      <ngx-skeleton-loader [theme]="{ height: '220px' }" />
      <app-skeleton-table [columnCount]="5" />
    </div>
  </section>
</div>
