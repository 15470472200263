import { Component, Input, OnInit } from '@angular/core';

import { InstanceTypes } from '@models';
import { environment } from '@env/environment';

@Component({
  selector: 'app-instance-badge',
  template: `
    @if (instance) {
    <div class="mt-[-30px] " [ngClass]="minified ? 'pl-8' : 'pl-4'">
      <span class="bg-ui-green-light p-1 px-2 rounded text-white capitalize font-semibold font-nunito text-xs h-5">{{
        instance
      }}</span>
    </div>
    }
  `,
  styles: [],
})
export class InstanceBadgeComponent implements OnInit {
  @Input() minified = false;
  instance = '';

  ngOnInit() {
    if (environment.instance !== InstanceTypes.PROD) {
      this.instance = environment.instance;
    }
  }
}
