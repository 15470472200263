import { Component, DestroyRef, Input, OnChanges, OnInit, inject } from '@angular/core';
import { EntityStatus, FloorSpaceModel, LocationFullData, RoomSpaceModel } from '@models';
import { AppState, updateFloor, updateLocation, updateRoom } from '@ngrx-store';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@services';
import { Action, Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-dashboard-location-header',
  templateUrl: './dashboard-location-header.component.html',
  styles: [],
})
export class DashboardLocationHeaderComponent implements OnInit, OnChanges {
  @Input() locationData: LocationFullData | undefined;
  @Input() floorId!: string;
  @Input() roomId!: string;
  destroyRef = inject(DestroyRef);
  title!: string;
  routeNames: string[] = [];
  siteStatus = EntityStatus;
  entityStatus = EntityStatus;
  currentFloor: FloorSpaceModel | undefined;
  currentRoom: RoomSpaceModel | undefined;

  constructor(private dialog: MatDialog, private store: Store<AppState>, private appService: AppService) {}

  ngOnInit(): void {
    this.getRoutes();
  }

  ngOnChanges(): void {
    this.getRoutes();
  }

  getRoutes() {
    if (this.locationData) {
      this.routeNames = [];
      this.currentFloor = undefined;
      this.currentRoom = undefined;

      this.title = this.locationData.friendlyName;

      if (this.locationData.id) {
        this.routeNames.push(this.locationData.friendlyName);
      }

      if (this.floorId) {
        this.currentFloor = this.locationData.floors.find(floor => floor.id === this.floorId);
      }

      if (this.currentFloor) {
        this.routeNames.push(this.currentFloor.friendlyName);
        this.title = this.currentFloor.friendlyName;
      }

      if (this.roomId && this.currentFloor && this.currentFloor.rooms?.length) {
        this.currentRoom = this.currentFloor.rooms.find(room => room.id === this.roomId);
      }

      if (this.currentRoom) {
        this.title = this.currentRoom.friendlyName;
        this.routeNames.push(this.currentRoom.friendlyName);
      }
    }
  }

  openConfirmationDialog({ action, title, description }: { title: string; description: string; action: Action }) {
    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(action);
        }
      });
  }

  resumeSite() {
    if (this.locationData) {
      this.openConfirmationDialog({
        title: 'Resume',
        description: `Are you sure you want to resume the ${this.locationData.friendlyName}?`,
        action: updateLocation({
          clientId: this.appService.currentClient,
          locationId: this.locationData.id,
          data: { status: EntityStatus.Active },
        }),
      });
    }
  }

  pauseSite() {
    if (this.locationData) {
      this.openConfirmationDialog({
        title: 'Pause',
        description: `Are you sure you want to pause the ${this.locationData.friendlyName}?`,
        action: updateLocation({
          clientId: this.appService.currentClient,
          locationId: this.locationData.id,
          data: { status: EntityStatus.Paused },
        }),
      });
    }
  }

  archiveSite() {
    if (this.locationData) {
      this.openConfirmationDialog({
        title: 'Add to Archive',
        description: `Are you sure you want to add ${this.locationData.friendlyName} to archive?`,
        action: updateLocation({
          clientId: this.appService.currentClient,
          locationId: this.locationData.id,
          data: { status: EntityStatus.Archived },
        }),
      });
    }
  }

  unarchiveSite() {
    if (this.locationData) {
      this.openConfirmationDialog({
        title: 'Restore',
        description: `Are you sure you want to restore ${this.locationData.friendlyName} from archive?`,
        action: updateLocation({
          clientId: this.appService.currentClient,
          locationId: this.locationData.id,
          data: { status: EntityStatus.Active },
        }),
      });
    }
  }

  resumeFloor() {
    if (this.locationData && this.currentFloor) {
      this.openConfirmationDialog({
        title: 'Resume',
        description: `Are you sure you want to resume the ${this.currentFloor.friendlyName}?`,
        action: updateFloor({
          locationId: this.locationData.id,
          spaceId: this.currentFloor.id,
          data: { status: EntityStatus.Active },
        }),
      });
    }
  }

  pauseFloor() {
    if (this.locationData && this.currentFloor) {
      this.openConfirmationDialog({
        title: 'Pause',
        description: `Are you sure you want to pause the ${this.currentFloor.friendlyName}?`,
        action: updateFloor({
          locationId: this.locationData.id,
          spaceId: this.currentFloor.id,
          data: { status: EntityStatus.Paused },
        }),
      });
    }
  }

  archiveFloor() {
    if (this.locationData && this.currentFloor) {
      this.openConfirmationDialog({
        title: 'Add to Archive',
        description: `Are you sure you want to add ${this.currentFloor.friendlyName} to archive?`,
        action: updateFloor({
          locationId: this.locationData.id,
          spaceId: this.currentFloor.id,
          data: { status: EntityStatus.Archived },
        }),
      });
    }
  }

  unarchiveFloor() {
    if (this.locationData && this.currentFloor) {
      this.openConfirmationDialog({
        title: 'Restore',
        description: `Are you sure you want to restore ${this.currentFloor.friendlyName} from archive?`,
        action: updateFloor({
          locationId: this.locationData.id,
          spaceId: this.currentFloor.id,
          data: { status: EntityStatus.Active },
        }),
      });
    }
  }

  resumeRoom() {
    if (this.locationData && this.currentRoom) {
      this.openConfirmationDialog({
        title: 'Resume',
        description: `Are you sure you want to resume the ${this.currentRoom.friendlyName}?`,
        action: updateRoom({
          locationId: this.locationData.id,
          spaceId: this.currentRoom.id,
          data: { status: EntityStatus.Active },
        }),
      });
    }
  }

  pauseRoom() {
    if (this.locationData && this.currentRoom) {
      this.openConfirmationDialog({
        title: 'Pause',
        description: `Are you sure you want to pause the ${this.currentRoom.friendlyName}?`,
        action: updateRoom({
          locationId: this.locationData.id,
          spaceId: this.currentRoom.id,
          data: { status: EntityStatus.Paused },
        }),
      });
    }
  }

  archiveRoom() {
    if (this.locationData && this.currentRoom) {
      this.openConfirmationDialog({
        title: 'Add to Archive',
        description: `Are you sure you want to add ${this.currentRoom.friendlyName} to archive?`,
        action: updateRoom({
          locationId: this.locationData.id,
          spaceId: this.currentRoom.id,
          data: { status: EntityStatus.Archived },
        }),
      });
    }
  }

  unarchiveRoom() {
    if (this.locationData && this.currentRoom) {
      this.openConfirmationDialog({
        title: 'Restore',
        description: `Are you sure you want to restore ${this.currentRoom.friendlyName} from archive?`,
        action: updateRoom({
          locationId: this.locationData.id,
          spaceId: this.currentRoom.id,
          data: { status: EntityStatus.Active },
        }),
      });
    }
  }
}
