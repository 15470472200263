import { Component } from '@angular/core';
import { SpaceDetailsComponent } from '../space-details/space-details.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DevicePopoverComponent } from '@standalone/device-popover/device-popover.component';

@Component({
  selector: 'app-room-details',
  templateUrl: './room-details.component.html',
})
export class RoomDetailsComponent extends SpaceDetailsComponent {
  showPlan = false;
  planType: 'room' | 'floor' = 'room';
  devicePopover = DevicePopoverComponent;

  togglePlan(event: MatSlideToggleChange) {
    this.showPlan = event.checked;
  }
}
