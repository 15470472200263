import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { setTabMetadataToImage } from '@app-lib';
import { AppState, uploadFloorplan, uploadRoomplan } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { NotificationsService } from '@services';

@Directive({
  selector: '[appUploadSpaceplan]',
})
export class UploadSpaceplanDirective {
  @Input() locationId = '';
  @Input() floorId = '';
  @Input() roomId = '';
  @Input() tabValue: string | null = null;
  @Output() fileUploaded = new EventEmitter();
  accept = 'image/svg+xml';

  @HostListener('change', ['$event'])
  async onFileChange($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    let file = inputElement.files?.[0];
    if (file && file.type !== 'image/svg+xml') {
      this.notificationService.showErrorMessage('Only SVG files are allowed.');
      return;
    }

    if (file && this.locationId && this.floorId) {
      if (this.tabValue) {
        const imgBlob = await setTabMetadataToImage(file, this.tabValue);
        if (imgBlob) {
          file = new File([imgBlob], file.name);
        }
      }
      const dispatchAction = this.roomId
        ? uploadRoomplan({ locationId: this.locationId, floorId: this.floorId, file, roomId: this.roomId })
        : uploadFloorplan({ locationId: this.locationId, spaceId: this.floorId, file });
      this.store.dispatch(dispatchAction);
      this.fileUploaded.emit(file.name);
    }
    inputElement.value = '';
  }
  constructor(private store: Store<AppState>, private notificationService: NotificationsService) {}
}
