import { Component, Input } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { AsyncPipe, NgIf } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PipesModule } from '@pipes';

@Component({
  selector: 'app-img-loader',
  templateUrl: './img-loader.component.html',
  standalone: true,
  styles: [],
  imports: [MatProgressSpinner, NgIf, NgxSkeletonLoaderModule, PipesModule, AsyncPipe],
})
export class ImgLoaderComponent {
  @Input() loader: 'skeleton' | 'spinner' = 'spinner';
  @Input() imgSrc!: string;
  @Input() errorSrc = '';
  @Input() altText = 'Image text placeholder';

  loading = true;

  onLoad() {
    this.loading = false;
  }
}
