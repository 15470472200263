import { Component, ViewChild } from '@angular/core';
import { UserDataFormComponent } from '@app/modules/user-management/components/user-data-form/user-data-form.component';
import { MatStepper } from '@angular/material/stepper';
import { NotificationsService } from '@services';
import { Router } from '@angular/router';
import { AlertSettingsComponent } from '@standalone/alert-settings/alert-settings.component';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styles: [],
})
export class AddNewUserComponent {
  @ViewChild(UserDataFormComponent) firstStepForm!: UserDataFormComponent;
  @ViewChild(AlertSettingsComponent) alertSettings!: AlertSettingsComponent;
  public buildingSearchValue = '';

  constructor(private notificationService: NotificationsService, private router: Router) {}

  goToStepTwo(stepper: MatStepper) {
    if (this.firstStepForm.formIsValid) {
      if (stepper.selected) {
        stepper.selected.completed = true;
        stepper.selected.editable = false;
      }
      stepper.next();
    } else {
      this.firstStepForm.submitForm();
    }
  }

  backToPrevStep(stepper: MatStepper) {
    stepper.steps.first.completed = false;
    stepper.steps.first.editable = true;
    stepper.previous();
  }

  createUser() {
    const body = {
      ...this.firstStepForm.formValues,
      ...{ alertSettings: this.alertSettings.alertSettingsValue },
    };

    console.log(body);
    this.notificationService.showSuccessMessage('User created');
    this.router.navigateByUrl('/user-management');
  }

  buildingSearchChanged($event: string) {
    // const target = $event?.target as HTMLInputElement;
    this.buildingSearchValue = $event;
  }
}
