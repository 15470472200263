export enum CountryCodes {
  'United States' = 'US',
  'United Kingdom' = 'GB',
  'Hong Kong' = 'HK',
  'Japan' = 'JP',
  'Australia' = 'AU',
}

export interface Country {
  name: string;
  mask: string;
  maskExpression: string;
  code: string;
  phoneCode: string;
  mapSettings: {
    center: { lat: number; lng: number };
    defaultZipCode?: string;
  };
}

export const DEFAULT_COUNTRY: Country = {
  name: 'United States',
  mask: '(000) 000-0000',
  maskExpression: '(___) ___-____',
  code: CountryCodes['United States'],
  phoneCode: '+1',
  mapSettings: {
    center: { lat: 45, lng: -98 },
  },
};

export const COUNTRY_LIST: Country[] = [
  DEFAULT_COUNTRY,
  {
    name: 'United Kingdom',
    mask: '000 0000 0000',
    maskExpression: '___ ____ ____',
    code: CountryCodes['United Kingdom'],
    phoneCode: '+44',
    mapSettings: {
      center: { lat: 52.957308, lng: -1.988953 },
    },
  },
  {
    name: 'Hong Kong',
    mask: '0000 0000',
    maskExpression: '____ ____',
    code: CountryCodes['Hong Kong'],
    phoneCode: '+852',
    mapSettings: {
      center: { lat: 22.316365, lng: 114.169121 },
      defaultZipCode: '999077',
    },
  },
  {
    name: 'Japan',
    mask: '00-0000-0000',
    maskExpression: '__-____-____',
    code: CountryCodes.Japan,
    phoneCode: '+81',
    mapSettings: {
      center: { lat: 35.789055, lng: 138.465512 },
    },
  },
  {
    name: 'Australia',
    mask: '(00) 0000 0000',
    maskExpression: '(__) ____ ____',
    code: CountryCodes.Australia,
    phoneCode: '+61',
    mapSettings: {
      center: { lat: -23.449842, lng: 131.772449 },
    },
  },
];
