import { Injectable } from '@angular/core';
import { DefaultSavedTableSettings, DeviceTableCols, SavedTableSettings, TableSelectedItem } from '@models';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  // Create or Update Item
  setItem(key: string, value: any): void {
    try {
      const serializedValue = JSON.stringify(value);
      sessionStorage.setItem(key, serializedValue);
    } catch (e) {
      console.error('Error saving to sessionStorage', e);
    }
  }

  // Read Item
  getItem<T>(key: string): T | null {
    try {
      const serializedValue = sessionStorage.getItem(key);
      return serializedValue ? JSON.parse(serializedValue) : null;
    } catch (e) {
      console.error('Error reading from sessionStorage', e);
      return null;
    }
  }

  getFilters(key: string): { [key: string]: string | string[] } | null {
    try {
      const serializedValue = sessionStorage.getItem(key);
      return serializedValue ? JSON.parse(serializedValue).filters : null;
    } catch (e) {
      console.error('Error reading from sessionStorage', e);
      return null;
    }
  }

  getColumns(key: string): DeviceTableCols[] | null {
    try {
      const serializedValue = sessionStorage.getItem(key);
      return serializedValue ? JSON.parse(serializedValue).columns : null;
    } catch (e) {
      console.error('Error reading from sessionStorage', e);
      return null;
    }
  }

  saveColumnsSettingsToSessionStorage(key: string, columns: string[]) {
    const tableSettings: SavedTableSettings = this.getItem(key) || DefaultSavedTableSettings;
    tableSettings.columns = columns;
    try {
      const serializedValue = JSON.stringify(tableSettings);
      sessionStorage.setItem(key, serializedValue);
    } catch (e) {
      console.error('Error saving to sessionStorage', e);
    }
  }

  saveFiltersToSessionStorage(key: string, filters: { [key: string]: string | string[] }) {
    const tableSettings: SavedTableSettings = this.getItem(key) || DefaultSavedTableSettings;
    tableSettings.filters = filters;
    try {
      const serializedValue = JSON.stringify(tableSettings);
      sessionStorage.setItem(key, serializedValue);
    } catch (e) {
      console.error('Error saving to sessionStorage', e);
    }
  }

  getLastSelectedItems(key: string): TableSelectedItem[] | null {
    try {
      const serializedValue = sessionStorage.getItem(key);
      return serializedValue ? JSON.parse(serializedValue).lastSelectedItems : null;
    } catch (e) {
      console.error('Error reading from sessionStorage', e);
      return null;
    }
  }

  saveLastSelectedItems(key: string, item: TableSelectedItem): void {
    const tableSettings: SavedTableSettings = this.getItem(key) || DefaultSavedTableSettings;
    const existingItemIndex = tableSettings.lastSelectedItems.findIndex(
      (selectedItem: TableSelectedItem) => selectedItem.title === item.title
    );

    if (existingItemIndex !== -1) {
      tableSettings.lastSelectedItems.splice(existingItemIndex, 1);
    }
    tableSettings.lastSelectedItems.unshift(item);

    try {
      const serializedValue = JSON.stringify(tableSettings);
      sessionStorage.setItem(key, serializedValue);
    } catch (e) {
      console.error('Error saving to sessionStorage', e);
    }
  }

  // Delete Item
  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  // Clear All Items
  clear(): void {
    sessionStorage.clear();
  }
}
