<div class="app-block-shadow flex items-center p-6 bg-white rounded-lg text-base text-ui-dark">
  <ul class="location-info w-[33%]">
    <li class="custom-text-ellipsis">
      <span class="font-semibold">Building Name:&nbsp;</span>
      <span class="test-location-name" [matTooltip]="locationData?.friendlyName || ''">{{
        locationData?.friendlyName
      }}</span>
    </li>
    <li class="custom-text-ellipsis">
      <span class="font-semibold">Address:</span>
      <span class="test-address-block" [matTooltip]="fullAddress">
        {{ fullAddress }}
      </span>
    </li>
    <li *ngIf="!currentFloorId" class="test-building-status">
      <span class="font-semibold">Building Status:&nbsp;</span>
      <span [class]="statusColors[locationData?.status || EntityStatus.Active]">
        <app-entity-status fontWeight="medium" fontSize="base" [status]="locationData?.status" />
      </span>
    </li>
    <li *ngIf="currentFloorId && !currentRoomId" class="test-floor-status">
      <span class="font-semibold">Floor Status:&nbsp;</span>
      <span [class]="statusColors[currentFloor?.status || EntityStatus.Active]">
        <app-entity-status fontWeight="medium" fontSize="base" [status]="currentFloor?.status" />
      </span>
    </li>
    <li *ngIf="currentRoomId" class="test-room-status">
      <span class="font-semibold">Room Status:&nbsp;</span>
      <span [class]="statusColors[currentRoom?.status || EntityStatus.Active]">
        <app-entity-status fontWeight="medium" fontSize="base" [status]="currentRoom?.status" />
      </span>
    </li>
  </ul>

  <div *ngIf="!floorList.length; else floorListSelect" class="test-floor-list w-[20%] text-center text-sm px-5">
    <div class="test-total-floors" *ngIf="!currentRoomId">
      <div class="pb-4">Total Floors:</div>
      <div class="text-base">
        <span class="font-poppins font-semibold">{{ buildingStats.floors }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="!roomList.length; else roomListSelect" class="test-total-rooms w-[27%] text-center px-4 text-sm">
    <div class="pb-4">Total Rooms:</div>
    <div class="text-base">
      <span class="font-poppins font-semibold">{{ buildingStats.rooms }}</span>
    </div>
  </div>
  <div class="building-stats w-[30%] text-sm flex justify-end">
    <div>
      <div class="whitespace-nowrap">Incidents:</div>
      <div class="mt-4" *ngIf="buildingStats.incidentAlert + buildingStats.incidentInProgress">
        <app-incident-status-count
          *ngIf="buildingStats.incidentAlert"
          [status]="incidentStatus.IN_QUEUE"
          [amount]="buildingStats.incidentAlert"
          class="mr-2" />
        <app-incident-status-count
          *ngIf="buildingStats.incidentInProgress"
          [status]="incidentStatus.IN_PROGRESS"
          [amount]="buildingStats.incidentInProgress" />
      </div>
    </div>
    <span class="border-r border-ui-gray-light mx-4 my-2"></span>
    <div>
      <div class="whitespace-nowrap">Devices:</div>
      <div class="mt-4" *ngIf="buildingStats.deviceActive + buildingStats.devicePaused">
        <app-device-status-count
          [activeAmount]="buildingStats.deviceActive"
          [pausedAmount]="buildingStats.devicePaused" />
      </div>
    </div>
  </div>
</div>

<ng-template #floorListSelect>
  <div class="test-floor-list w-[20%] min-w-[282px] text-center px-4 text-sm">
    <ng-container *ngIf="currentFloor?.status !== EntityStatus.Archived; else floorNameContainer">
      <div class="pb-2">Floor:</div>
      <div class="text-xl font-poppins font-semibold">
        <mat-form-field class="select-classic rounded-borders w-auto">
          <mat-select
            appSelectBlur
            value="{{ currentFloorId }}"
            panelClass="single-select-overlay space-select-overlay"
            (selectionChange)="switchSpace($event, true)">
            <div class="mb-1 mx-3">
              <app-search-input
                placeholder="Search floor"
                inputWidth="100%"
                (searchDataEvent)="searchFloor = $event"></app-search-input>
            </div>
            <mat-option
              *ngFor="
                let floor of floorList | filterFloorSpaceByName : searchFloor : false | sort : 'asc' : 'friendlyName'
              "
              [value]="floor.id"
              >{{ floor.friendlyName }}</mat-option
            >
          </mat-select>
          <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
        </mat-form-field>
      </div>
    </ng-container>
  </div>
  <ng-template #floorNameContainer>
    <div class="pb-4">Floor:</div>
    <div class="text-base">
      <span class="font-poppins font-semibold">{{ floorName }}</span>
    </div>
  </ng-template>
</ng-template>
<ng-template #roomListSelect>
  <div class="test-room-list w-[20%] min-w-[282px] text-center px-4 text-sm">
    <ng-container *ngIf="currentRoom?.status !== EntityStatus.Archived; else roomNameContainer">
      <div class="pb-2">Room:</div>
      <div class="text-base font-poppins font-semibold">
        <mat-form-field class="select-classic rounded-borders w-auto">
          <mat-select
            appSelectBlur
            value="{{ currentRoomId }}"
            panelClass="single-select-overlay space-select-overlay"
            (selectionChange)="switchSpace($event)">
            <div class="mb-1 mx-3">
              <app-search-input
                placeholder="Search room"
                inputWidth="100%"
                (searchDataEvent)="searchRoom = $event"></app-search-input>
            </div>
            <mat-option
              *ngFor="let room of roomList | filterRoomsByName : searchRoom : false | sort : 'asc' : 'friendlyName'"
              [value]="room.id"
              >{{ room.friendlyName }}</mat-option
            >
          </mat-select>
          <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-template #roomNameContainer>
      <div class="pb-4">Room:</div>
      <div class="text-xl">
        <span class="font-poppins font-semibold">{{ currentRoom?.friendlyName }}</span>
      </div>
    </ng-template>
  </div>
</ng-template>
