<ul [class.is-dark-mode]="isDarkMode">
  <li [class.matched]="hasMinLength" class="test-has-min-length">
    <i class="icon-CheckCircleRounded"></i>
    <span></span>
    Password minimum length: 12 character(s)
  </li>
  <li [class.matched]="hasNumber" class="test-has-number">
    <i class="icon-CheckCircleRounded"></i>
    <span></span>
    Contains at least 1 number
  </li>
  <li [class.matched]="hasSpecialCharacter" class="test-has-special-character">
    <i class="icon-CheckCircleRounded"></i>
    <span></span>
    Contains at least 1 special character
  </li>
  <li [class.matched]="hasUppercase" class="test-has-uppercase">
    <i class="icon-CheckCircleRounded"></i>
    <span></span>
    Contains at least 1 uppercase letter
  </li>
  <li [class.matched]="hasLowercase" class="test-has-lowercase">
    <i class="icon-CheckCircleRounded"></i>
    <span></span>
    Contains at least 1 lowercase letter
  </li>
</ul>
