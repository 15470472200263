@if ((isLoading$|async) === false) {
<div class="flex justify-between items-center mb-5">
  <div>
    <app-page-header>Add New Client</app-page-header>
    <app-breadcrumbs [routeNames]="['Add New Client']" />
  </div>
  <div>
    <label for="create-client-submit" class="test-submit-btn cursor-pointer btn-medium btn-green px-4 py-2">
      Save
    </label>
  </div>
</div>
<mat-card class="card">
  <mat-card-content class="p-0">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <mat-card-title class="card-title-small pb-4">Basic Info</mat-card-title>
      <mat-card-content class="p-0">
        <div class="flex flex-row">
          <label class="input-main-label">Client Name:*</label>
          <mat-form-field class="input-classic">
            <input formControlName="friendlyName" matInput placeholder="Enter client name" type="text" />
            <mat-error>Input is not valid</mat-error>
          </mat-form-field>
        </div>
        <div class="flex flex-row">
          <label class="input-main-label">Ticketing client id:</label>
          <mat-form-field class="input-classic" formGroupName="tags">
            <input formControlName="ticketingClientId" matInput placeholder="Enter ticketing client id" type="text" />
            <mat-error>Input is not valid</mat-error>
          </mat-form-field>
        </div>
        <mat-card-title class="card-title-small pb-4">Contact Info</mat-card-title>
        <mat-card-content class="p-0">
          <div class="flex flex-row" formGroupName="tags">
            <label class="input-main-label">Phone number:</label>
            <div class="flex justify-between">
              <mat-form-field class="input-classic max-w-[150px] mr-2">
                <mat-select (selectionChange)="changeMaskSettings($event)" formControlName="countryCode">
                  @for (country of COUNTRY_LIST; track country.code) {
                  <mat-option [value]="country.code">{{ country.code }} ({{ country.phoneCode }})</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-form-field class="input-classic flex-grow max-w-[245px]">
                <input
                  formControlName="phoneNumber"
                  [shownMaskExpression]="phoneMaskExpression()"
                  [mask]="phoneMask()"
                  matInput
                  [placeholder]="phoneMask()"
                  type="text" />
                <mat-error>Input is not valid</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="flex flex-row">
            <label class="input-main-label">Website:</label>
            <mat-form-field class="input-classic" formGroupName="tags">
              <input formControlName="website" matInput placeholder="Enter website" type="text" />
              <mat-error>Input is not valid</mat-error>
            </mat-form-field>
          </div>
          <div class="flex flex-row">
            <label class="input-main-label">Description:</label>
            <mat-form-field class="input-classic">
              <input formControlName="description" matInput placeholder="Enter description" type="text" />
              <mat-error>Input is not valid</mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-card-title class="card-title-small pb-4">Session Timeout</mat-card-title>
        <mat-card-content class="p-0">
          <div class="flex flex-row">
            <label class="input-main-label"></label>
            <div>
              <app-slider (valueChanged)="updateSliderValue($event)" />
            </div>
          </div>
        </mat-card-content>
      </mat-card-content>
      <input id="create-client-submit" type="submit" hidden="hidden" />
    </form>
  </mat-card-content>
</mat-card>
} @else {
<mat-spinner class="m-auto" />
}
