<h1 class="dialog-title">
  <span>Edit Room</span>
  <button mat-dialog-close>
    <i class="icon-close"></i>
  </button>
</h1>
<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content class="dialog-content">
    <div class="flex flex-row">
      <label for="friendlyName" class="input-main-label capitalize">Room name*</label>
      <mat-form-field class="input-classic">
        <input matInput id="friendlyName" type="text" placeholder="Enter Room name*" formControlName="friendlyName" />
        <mat-error>Form is not valid</mat-error>
      </mat-form-field>
    </div>
    <div class="flex flex-row">
      <label for="externalReference" class="input-main-label capitalize">Doc link</label>
      <mat-form-field class="input-classic">
        <input matInput id="externalReference" type="text" placeholder="Doc link" formControlName="externalReference" />
        <mat-error>Form is not valid</mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-dialog-close class="test-btn-cancel btn-transparent btn-medium font-bold mr-4">CANCEL</button>
    <button type="submit" class="btn-green btn-medium font-bold" [disabled]="!form.valid || (isRoomUpdating$ | async)">
      <mat-spinner *ngIf="isRoomUpdating$ | async" diameter="20" class="mr-1" />
      SAVE
    </button>
  </mat-dialog-actions>
</form>
