import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeviceData, EntityStatus, IncidentModel, IncidentStatus } from '@models';
import { NotificationsService } from '@services';

@Component({
  selector: 'app-device-info-block',
  templateUrl: './device-info-block.component.html',
  styleUrls: ['./device-info-block.component.scss'],
})
export class DeviceInfoBlockComponent implements OnChanges {
  protected readonly EntityStatus = EntityStatus;
  @Input() device!: DeviceData;
  @Input() incidents: IncidentModel[] = [];
  @Input() clientId!: string;
  incidentStatus = IncidentStatus;
  tempSensor: string | null | undefined = undefined;

  constructor(private notificationMessage: NotificationsService) {}

  copiedToClipboard() {
    this.notificationMessage.showSuccessMessage('Copied to clipboard');
  }

  getDeviceUrl(device: DeviceData): string {
    return device.tags?.['ipAddress'] + '/' + (device.tags?.['hyperlink'] || '');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device']) {
      this.initDeviceAttributes();
    }
  }

  initDeviceAttributes() {
    if (this.device.additionalAttributes) {
      this.tempSensor = this.device.additionalAttributes?.find(attribute => attribute.name === 'tempSensor')?.value;
    }
  }
}
