<google-map
  *ngIf="locations"
  width="100%"
  height="100%"
  [options]="mapOptions"
  (mapInitialized)="mapInitialized($event)">
  <map-advanced-marker
    #advancedMarker="mapAdvancedMarker"
    *ngFor="let markerData of filteredMarkers"
    [position]="markerData.position"
    [options]="markerData.options"
    [gmpDraggable]="false"
    [content]="markerData | dashboardMapMarkerIcon"
    (mapClick)="openInfo(advancedMarker, markerData, infoWindow)"></map-advanced-marker>
  <div #infoWindow>
    <i
      *ngIf="selectedSite"
      class="icon-close absolute top-0 right-0 text-base text-ui-gray-100 p-1 cursor-pointer"
      (click)="hideInfo()"></i>
    <app-site-map-marker *ngIf="selectedSite" [site]="selectedSite" />
  </div>
  <map-info-window />
</google-map>
