import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DEFAULT_COUNTRY, removeSpacesValidator } from '@app-lib';
import { take } from 'rxjs';
import { MatError, MatFormField, MatSuffix } from '@angular/material/form-field';
import { NgxMaskDirective } from 'ngx-mask';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-main-input-form',
  standalone: true,
  templateUrl: './main-input-form.component.html',
  styles: ['div {display: flex; flex-direction:row }'],
  imports: [MatFormField, ReactiveFormsModule, NgxMaskDirective, MatInput, MatIcon, MatSuffix, NgIf, MatError],
})
export class MainInputFormComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() controlName = '';
  @Input() formItemType = 'text';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() value: string | number | null = null;
  @Input() autofocus = false;
  @Input() formFieldClass = '';
  @Input() min: number | null = null;
  @Input() max: number | null = null;
  @Input() isRequired = true;
  @Input() hasPrefix = false;
  @Output() updateData = new EventEmitter<Record<string, string>>();
  @Output() clickOutside = new EventEmitter();
  @Input() applyPhoneMask = false;
  @Input() phoneMask!: string;
  @Input() phoneMaskExpression!: string;

  form: FormGroup;
  formInitialValue!: string | number | null;
  isInitialized = false;
  showSubmitButton = false;
  isValueSaved = false;

  @ViewChild('submitButton') submitButton!: ElementRef;
  @ViewChild('input') input!: ElementRef;

  constructor(private fb: FormBuilder, private elRef: ElementRef, private ngZone: NgZone) {
    this.form = this.fb.group({});
  }

  ngAfterViewInit(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        if (this.autofocus) {
          this.focusInput();
        }
        this.isInitialized = true;
      });
  }

  @HostListener('document:mousedown', ['$event'])
  clickout(event: Event) {
    if (!this.elRef.nativeElement.contains(event.target) && this.isInitialized) {
      this.clickOutside.emit();
    }
  }

  ngOnInit(): void {
    const validators = [];

    if (this.formItemType === 'number') {
      if (this.min) validators.push(Validators.min(this.min));
      if (this.max) validators.push(Validators.max(this.max));
    } else {
      validators.push(removeSpacesValidator);
    }

    if (this.isRequired) {
      validators.push(Validators.required);
    }

    this.form.addControl(this.controlName, new FormControl({ value: this.value, disabled: this.disabled }, validators));
  }

  ngOnDestroy(): void {
    document.removeEventListener('mousedown', this.clickout);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.form.get(this.controlName)?.setValue(changes['value'].currentValue);
    }
  }

  onFocus() {
    this.showSubmitButton = true;
    this.formInitialValue = this.value;
    this.isValueSaved = false;
  }

  onBlur() {
    setTimeout(() => {
      if (!this.isValueSaved) {
        this.form.get(this.controlName)?.setValue(this.formInitialValue);
      }
      this.showSubmitButton = false;
    }, 200);
  }

  focusInput() {
    this.input.nativeElement.focus();
  }

  submitForm() {
    if (this.form.valid) {
      this.input.nativeElement.blur();
      if (this.formInitialValue !== this.form.get(this.controlName)?.value) {
        this.updateData.emit(
          this.formItemType === 'number'
            ? { [this.controlName]: Number(this.form.get(this.controlName)?.value) }
            : this.form.value
        );
        this.isValueSaved = true;
      }
      this.submitButton.nativeElement.blur();
    }
  }

  protected readonly DEFAULT_COUNTRY = DEFAULT_COUNTRY;
}
