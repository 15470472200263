<mat-card class="card pb-2">
  <mat-card-title class="card-title">Building Incidents </mat-card-title>
  <mat-card-content class="p-0">
    <app-incidents-table-desktop-filters
      tableId="building-incidents-table"
      [filtersEnabled]="true"
      [resolvedIncidentsLoading]="resolvedIncidentsLoading" />
    <app-incidents-table
      tableId="building-incidents-table"
      [isInjected]="true"
      [dataIsLoading]="resolvedIncidentsLoading"
      noDataText="All the Building devices are working properly" />
  </mat-card-content>
</mat-card>
