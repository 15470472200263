<div class="overflow-auto mb-6 flex">
  <div
    *ngIf="spaces.length"
    class="h-[140px] w-[224px] bg-ui-green-light text-white rounded-lg p-3.5 shadow-[0px_6px_22px_0px_rgba(222,223,226,0.51)] mr-3">
    <h4 class="font-poppins font-semibold mb-0 text-base whitespace-nowrap overflow-hidden text-ellipsis">
      All Buildings
    </h4>
    <div class="flex items-center mb-1.5 mt-[45px]">
      <p class="font-nunito text-[10px] mb-0 flex items-center">
        <span class="font-poppins font-semibold text-2xl mr-1.5">12</span>Incidents
      </p>
      <span class="mx-2 text-ui-gray-light text-base">|</span>
      <p class="font-nunito text-[10px] mb-0 flex items-center">
        <span class="font-poppins font-semibold text-2xl mr-1.5">20</span>Devices
      </p>
    </div>
    <ul class="flex">
      <li class="site-device-status"><span class="status-indicator bg-ui-red-50"></span>99</li>
      <li class="site-device-status"><span class="status-indicator bg-ui-yellow-100"></span>99</li>
      <li class="site-device-status"><span class="status-indicator border border-white bg-ui-green-light"></span>99</li>
      <li class="site-device-status"><span class="status-indicator bg-ui-dark"></span>99</li>
    </ul>
  </div>
  <button
    class="h-[140px] w-[64px] flex justify-center items-center bg-white font-nunito text-xs font-semibold text-ui-dark flex-col rounded-lg mr-3"
    (click)="openAddSpaceDialog()">
    <mat-icon>add</mat-icon><span class="leading-[14px]">Add<br />space</span>
  </button>
  <div
    *ngFor="let space of spaces"
    class="h-[140px] w-[224px] bg-white text-ui-dark rounded-lg p-3.5 shadow-[0px_6px_22px_0px_rgba(222,223,226,0.51)]">
    <div class="flex justify-between">
      <div class="w-[calc(100%_-_50px)]">
        <h4 class="font-poppins font-semibold mb-0 text-base whitespace-nowrap overflow-hidden text-ellipsis">
          {{ space.name }}
        </h4>
        <p class="font-nunito text-[10px] mb-0 capitalize">
          {{ (space.room && 'Room') || (space.floor && 'Floor') || 'Building' }}
        </p>
      </div>
      <div class="group relative">
        <mat-icon class="cursor-pointer rounded-full hover:bg-ui-green-150">more_vert</mat-icon>
        <div class="absolute rounded-md bg-white py-2 w-[174px] -left-[87px] invisible group-hover:visible">
          <button
            class="text-black font-nunito text-sm h-[40px] flex items-center px-3 hover:text-ui-green-light hover:bg-ui-green-150 w-full"
            (click)="openEditDialog(space)">
            Edit space
          </button>
          <button
            class="text-black font-nunito text-sm h-[40px] flex items-center px-3 hover:text-ui-green-light hover:bg-ui-green-150 w-full"
            (click)="openDeleteDialog(space.id, space.name)">
            Delete space
          </button>
        </div>
      </div>
    </div>
    <p class="font-nunito font-semibold text-xs mb-1.5 whitespace-nowrap overflow-hidden text-ellipsis w-full">
      {{ space.building.value }}
    </p>
    <div class="flex items-center mb-1.5">
      <p class="font-nunito text-[10px] mb-0 flex items-center">
        <span class="font-poppins font-semibold text-2xl mr-1.5">{{ space.incidents }}</span
        >Incidents
      </p>
      <span class="mx-2 text-ui-gray-light text-base">|</span>
      <p class="font-nunito text-[10px] mb-0 flex items-center">
        <span class="font-poppins font-semibold text-2xl mr-1.5">{{ space.devices }}</span
        >Devices
      </p>
    </div>
    <ul class="flex">
      <li class="site-device-status"><span class="status-indicator bg-ui-red-50"></span>{{ space.deviceAlert }}</li>
      <li class="site-device-status">
        <span class="status-indicator bg-ui-yellow-100"></span>{{ space.deviceWarning }}
      </li>
      <li class="site-device-status"><span class="status-indicator bg-ui-green-light"></span>{{ space.deviceGood }}</li>
      <li class="site-device-status"><span class="status-indicator bg-ui-dark"></span>{{ space.deviceOffline }}</li>
    </ul>
  </div>
</div>
