import { Component, OnDestroy, ViewChild } from '@angular/core';
import { deviceColumnOptions } from '@mocks';
import { Store } from '@ngrx/store';
import { AppState, deviceLoaded, selectAllDevices } from '@ngrx-store';
import { Observable } from 'rxjs';
import { DeviceData } from '@models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DevicesFilterService } from '@services';
import { DevicesTableComponent } from '@standalone/_tables/devices-table/devices-table.component';

@Component({
  selector: 'app-devices-main-page',
  templateUrl: './devices-main-page.component.html',
})
export class DevicesMainPageComponent implements OnDestroy {
  deviceList$!: Observable<DeviceData[]>;
  deviceListLoaded$!: Observable<boolean>;
  @ViewChild(DevicesTableComponent) deviceTable!: DevicesTableComponent;

  constructor(private store: Store<AppState>, public devicesFilterService: DevicesFilterService) {
    this.store
      .select(selectAllDevices)
      .pipe(takeUntilDestroyed())
      .subscribe(devices => {
        this.devicesFilterService.initDevices(devices);
      });
    this.deviceListLoaded$ = this.store.select(deviceLoaded);

    this.devicesFilterService.initColumns(deviceColumnOptions.map(column => column.value));
  }
  ngOnDestroy() {
    this.devicesFilterService.resetState();
  }

  exportDevices(type: 'json' | 'csv') {
    if (type === 'json') {
      this.deviceTable.exportDevicesToJSON();
    }
    if (type === 'csv') {
      this.deviceTable.exportDevicesToCSV();
    }
  }
}
