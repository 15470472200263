import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { IncidentModel, NormalizedIncident } from '@models';
import {
  exportDataToCSV,
  exportDataToJSON,
  IncidentColumn,
  incidentColumnTitle,
  naturalSorting,
  normalizeIncidents,
  staticIncidentColumnTitle,
} from '@app-lib';
import { IncidentsFilterService } from '@services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { IncidentStatusComponent } from '@standalone/incident-status/incident-status.component';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { NgClass, NgIf } from '@angular/common';
import { DirectivesModule, VirtualTableScrollNormalizerDirective } from '@directives';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

enum IncidentTabStatusFilter {
  OPEN = 'open',
  HISTORY = 'history',
}

@Component({
  standalone: true,
  selector: 'app-incidents-table',
  templateUrl: './incidents-table.component.html',
  styleUrls: ['./incidents-table.component.scss'],
  imports: [
    MatTable,
    MatSortHeader,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatCellDef,
    MatSort,
    RouterLink,
    IncidentStatusComponent,
    MatHeaderCellDef,
    SkeletonTableComponent,
    MatPaginator,
    NgClass,
    DirectivesModule,
    NoDataComponent,
    MatHeaderRow,
    MatRow,
    NgIf,
    MatRowDef,
    MatHeaderRowDef,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    VirtualTableScrollNormalizerDirective,
  ],
})
export class IncidentsTableComponent implements AfterViewInit, OnChanges {
  @ViewChild(MatSort) sort!: MatSort;
  @Input() noDataText = 'Data is Empty';
  @Input() tableId: string | null = null;
  @Input() isNoDataBigSize = false;
  @Input() incidentsData: IncidentModel[] = [];
  @Input() isReportMode = false;
  @Input() isInjected = false;
  @Input() dataIsLoading = false;
  @Input() isMobile = false;

  readonly staticIncidentColumnTitle = staticIncidentColumnTitle;
  readonly incidentColumnTitle = incidentColumnTitle;
  readonly IncidentColumn = IncidentColumn;
  incidentTabStatusFilterEnum = IncidentTabStatusFilter;
  dataSource: MatTableDataSource<NormalizedIncident> = new MatTableDataSource();

  @ViewChild('table') set tableEl(element: MatTable<any>) {
    if (element) {
      this.dataSource.sort = this.sort;
      this.changeDetectionRef.detectChanges();
    }
  }
  constructor(public incidentsFilterService: IncidentsFilterService, private changeDetectionRef: ChangeDetectorRef) {
    this.incidentsFilterService.filteredIncidents$.pipe(takeUntilDestroyed()).subscribe(incidents => {
      this.initTableData(incidents);
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['incidentsData']) {
      this.initTableData(normalizeIncidents(changes['incidentsData'].currentValue));
    }
  }

  initTableData(incidents: NormalizedIncident[]) {
    this.dataSource = new MatTableDataSource<NormalizedIncident>(incidents);
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = naturalSorting;
  }

  prepareIncidentsDataForDownloading() {
    const columns = this.incidentsFilterService.displayedColumns.map(column => (column === 'index' ? 'id' : column));
    const incidentKeys = Object.keys(this.dataSource.filteredData[0]);
    const dataToRender: Array<{ [key: string]: string | null }> = this.dataSource.filteredData.map(incident => {
      const row: { [key: string]: string | null } = {};
      columns.forEach(item => {
        const incidentColumn = incidentKeys.find(key => key === item);
        if (incidentColumn) {
          const value = incident[item as keyof typeof incident];
          if (typeof value !== 'object') {
            row[item] = value;
          }
        }
      });

      return row;
    });

    return dataToRender;
  }

  exportIncidentsToCSV() {
    exportDataToCSV(this.prepareIncidentsDataForDownloading(), 'incidents report');
  }

  exportIncidentsToJSON() {
    exportDataToJSON(this.prepareIncidentsDataForDownloading(), 'incidents report');
  }
}
