<div class="border-t border-t-ui-gray-light py-2 pr-2 pl-3 flex justify-between items-center h-11">
  <span
    class="test-floorname text-sm font-nunito text-ui-dark overflow-hidden w-[155px] text-ellipsis whitespace-nowrap"
    *ngIf="!isEditable"
    >{{ floor.friendlyName }}</span
  >
  <app-main-input-form
    #inputForm
    *ngIf="isEditable"
    controlName="friendlyName"
    formItemType="text"
    placeholder="Enter floor name"
    [value]="floor.friendlyName"
    [autofocus]="true"
    formFieldClass="hide-error-message"
    (updateData)="updateFloorName($event)"
    (clickOutside)="clickOutside()"></app-main-input-form>
  <div class="flex items-center">
    <ng-container *ngIf="floor.status !== entityStatus.Archived">
      <button class="load-btn relative btn-blue btn-small" *ngIf="floor.id && !floor.documentReference">
        <i class="icon-upload-map text-base mr-1"></i>
        Upload Floor Plan
        <input type="file" appUploadSpaceplan accept="image/svg+xml" [locationId]="locationId" [floorId]="floor.id" />
      </button>
      <app-delete-spaceplan
        *ngIf="floor.documentReference"
        [documentReference]="floor.documentReference"
        [floorId]="floor.id"
        [locationId]="locationId" />
      <i
        class="icon-edit text-ui-gray-100 cursor-pointer inline-flex items-center mr-1 ml-2 text-3xl"
        [matTooltip]="'Edit'"
        (click)="edit()"></i>
      <i
        [matTooltip]="'Archive'"
        class="test-delete-button icon-add-to-archive text-ui-gray-100 text-3xl cursor-pointer"
        (click)="archiveFloor()"></i>
    </ng-container>
    <i
      *ngIf="floor.status === entityStatus.Archived"
      [matTooltip]="'Restore'"
      class="icon-restore text-ui-gray-100 text-3xl cursor-pointer inline-flex items-center"
      (click)="restoreFloor()"></i>
    <!-- <mat-icon
      class="test-delete-button text-ui-gray-100 text-2xl cursor-pointer inline-flex items-center pointer-events-none"
      (click)="deleteFloor()"
      >delete_outline
    </mat-icon> -->
  </div>
</div>
