import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgForOf, NgIf, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-breadcrumbs',
  template: `
    <span *ngFor="let breadcrumb of breadcrumbsUrls; index as i" class="font-nunito text-sm font-semibold">
      <a (click)="goTo(i)">
        <span
          class="test-route-name"
          [class]="
            breadcrumbsUrls.length - 1 !== i ? 'cursor-pointer hover:underline text-ui-dark' : ' text-ui-gray-100'
          "
          >{{ routeName(i) | titlecase }}</span
        >
        <span class="test-separator" *ngIf="breadcrumbsUrls.length - 1 !== i"> {{ separator }} </span>
      </a>
    </span>
  `,
  standalone: true,
  styleUrls: ['./breadcrumbs.component.scss'],
  imports: [NgForOf, TitleCasePipe, NgIf],
})
export class BreadcrumbsComponent implements OnInit, OnChanges {
  @Input() separator = '>';
  @Input() routeNames: string[] = [];
  normalizedRouteNames: string[] = [];
  breadcrumbsUrls!: string[];

  constructor(public router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['routeNames']) {
      this.defineRoutes();
    }
  }

  routeName(index: number): string {
    return this.normalizedRouteNames[index] || this.breadcrumbsUrls[index];
  }

  ngOnInit(): void {
    this.defineRoutes();
  }

  defineRoutes() {
    this.normalizedRouteNames = [];
    this.breadcrumbsUrls = [];
    this.breadcrumbsUrls = this.router.url.split('/');
    this.breadcrumbsUrls.shift();
    this.breadcrumbsUrls.length = this.routeNames.length + 1;
    this.normalizedRouteNames[0] = this.breadcrumbsUrls[0].replaceAll('-', ' ');
    this.normalizedRouteNames = [...this.normalizedRouteNames, ...this.routeNames];
  }

  goTo(index: number) {
    if (index === this.breadcrumbsUrls.length - 1) return;
    let url = [...this.breadcrumbsUrls];
    url = url.splice(0, index + 1);
    this.router.navigateByUrl('/' + url.toString().replaceAll(',', '/'));
  }
}
