import { Component, Input } from '@angular/core';
import { IncidentStatus } from '@models';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-incident-status',
  template: `<span
    class="status-block"
    [ngClass]="{
      'status-in-progress': status === incidentStatus.IN_PROGRESS,
      'status-in-queue': status === incidentStatus.IN_QUEUE,
      'status-resolved': status === incidentStatus.RESOLVED
    }"
    >{{ statusText[status] }}</span
  >`,
  standalone: true,
  styleUrls: ['./incident-status.component.scss'],
  imports: [NgClass],
})
export class IncidentStatusComponent {
  @Input() status!: IncidentStatus;
  statusText = {
    [IncidentStatus.IN_QUEUE]: 'In queue',
    [IncidentStatus.IN_PROGRESS]: 'In progress',
    [IncidentStatus.RESOLVED]: 'Resolved',
  };
  incidentStatus = IncidentStatus;
}
