<mat-spinner *ngIf="loading && loader === 'spinner'" diameter="20"></mat-spinner>
<div *ngIf="loading && loader === 'skeleton'">
  <ngx-skeleton-loader appearance="line" [theme]="{ height: '150px' }" />
</div>
<img
  [hidden]="loading"
  (load)="onLoad()"
  [src]="imgSrc | srcSecurePipe : errorSrc | async"
  [alt]="altText"
  class="select-none pointer-events-none" />
