import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AppState, logout } from '@ngrx-store';
import { Store } from '@ngrx/store';

export enum IdleUserTimes {
  IDLE_TIME = 570, // Idle time in seconds
  TIMEOUT = 30, // Timeout in seconds
}

@Injectable({
  providedIn: 'root',
})
export class IdleUserService {
  constructor(private _idle: Idle, private store: Store<AppState>) {
    this._idle.setIdle(IdleUserTimes.IDLE_TIME);
    this._idle.setTimeout(IdleUserTimes.TIMEOUT);
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // Default interrupts (clicks, scrolls, touches, etc.)

    window.addEventListener('storage', event => {
      if (event.key === 'idleState' && event.newValue === 'Started') {
        this._idle.watch();
        // reload app if in another tab user has already logged in
        window.location.reload();
      }

      if (event.key === 'idleState' && event.newValue === 'Stopped') {
        this._idle.stop();
        // reload app if in another tab user has already logged out
        this.store.dispatch(logout());
      }
    });
  }

  get idle() {
    return this._idle;
  }

  // idleTime param must be passed in seconds
  setIdleTime(idleTime: number) {
    this._idle.setIdle(idleTime - IdleUserTimes.TIMEOUT);
  }

  startWatching() {
    this._idle.watch();
    localStorage.setItem('idleState', 'Started');
  }

  stopWatching() {
    this._idle.stop();
    localStorage.setItem('idleState', 'Stopped');
  }
}
