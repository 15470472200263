import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInfoComponent } from '@app/modules/user-profile/containers/user-info/user-info.component';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { DirectivesModule } from '@directives';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertSettingsComponent } from '@standalone/alert-settings/alert-settings.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { PersonalProfileComponent } from '@standalone/personal-pofile/personal-profile.component';

@NgModule({
  declarations: [UserInfoComponent],
  imports: [
    CommonModule,
    MatTreeModule,
    RouterModule.forChild([{ path: '', component: UserInfoComponent }]),
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatTabsModule,
    DirectivesModule,
    MatProgressSpinnerModule,
    AlertSettingsComponent,
    PageHeaderComponent,
    PersonalProfileComponent,
  ],
})
export class UserProfileModule {
  static forRoot(): ModuleWithProviders<UserProfileModule> {
    return {
      ngModule: UserProfileModule,
      providers: [],
    };
  }
}
