import { capitalize, defaultSelectOption } from '@app-lib';
import { EntityStatus } from '@models';

export const siteStatusOptions = [
  defaultSelectOption,
  {
    value: EntityStatus.Active,
    title: capitalize(EntityStatus.Active),
  },
  {
    value: EntityStatus.Paused,
    title: capitalize(EntityStatus.Paused),
  },
];
