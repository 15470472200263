@let areRoomDocumentsLoading = areRoomDocumentsLoading$ | async; @let isRoomplanLoading = isRoomplanLoading$ | async;
@let devices = (deviceList$ | async); @if(areRoomDocumentsLoading) {
<ngx-skeleton-loader [theme]="{ height: '400px' }" />
} @else if (room && room.parentSpaceId) {
<div class="mb-2">
  <mat-spinner *ngIf="isRoomplanLoading" diameter="40" class="mr-1"></mat-spinner>
  <mat-button-toggle-group
    [value]="selectedDocumentTab"
    (change)="documentTabChange($event)"
    class="main-toggle-group flex">
    @for (tab of documentTabs; track tab; let index = $index) {
    <mat-button-toggle
      class="border-ui-green-light"
      [value]="tab"
      [ngClass]="{
        'ml-auto rounded-l': tab === defaultTab,
        'rounded-r border-r':
          currentUserRoleId === userRoleIds.SuperAdmin && documentTabs.length > 1 && index === documentTabs.length - 2
      }">
      <mat-icon matIconPrefix *ngIf="tab === defaultTab">add</mat-icon>
      <span class="capitalize">{{ tab === defaultTab ? 'Add new tab' : tab }}</span>
    </mat-button-toggle>
    }
  </mat-button-toggle-group>
</div>

@if(documentTabs.length === 0) {
<app-no-data noDataText="No block diagram added" />
} @else { @for (document of documents; track document) {
<app-spaceplan
  *ngIf="devices && document.tab === selectedDocumentTab"
  [spaceplan]="{
    id: room.id,
    documentReference: document.fileName,
    attachments: devices,
    parentSpaceId: room.parentSpaceId
  }"
  [documentTab]="selectedDocumentTab"
  [outsideImgBlobUrl]="document.blobUrl"
  [locationId]="locationId"
  [canEdit]="canEdit"
  [popover]="popover" />
}

<app-upload-spaceplan
  [hidden]="selectedDocumentTab !== defaultTab"
  [existedTabs]="documentTabs"
  uploadText="Upload Block Diagram"
  [locationId]="locationId"
  [floorId]="room.parentSpaceId"
  [roomId]="room.id"
  [tabEnabled]="true" />
} }
