<div
  #box
  *ngIf="width && height && attachment.status !== entityStatus.Archived"
  class="resizable-draggable"
  [class.active]="status === Status.RESIZE || status === Status.MOVE"
  [class.edit-mode]="editMode"
  [class.cursor-pointer]="allowNavigation"
  [class.attachment-paused]="attachment.status === entityStatus.Paused"
  [class.incident-new]="attachment.status === entityStatus.Active && attachment.incidentCountByStatuses.newCount"
  [class.incident-inProgress]="
    attachment.status === entityStatus.Active &&
    !attachment.incidentCountByStatuses.newCount &&
    attachment.incidentCountByStatuses.inProgressCount
  "
  [style.width.px]="width"
  [style.height.px]="height"
  [style.transform]="'translate3d(' + left + 'px,' + top + 'px,' + '0px)'"
  (mousedown)="editMode ? setStatus($event, 2) : null"
  (window:mouseup)="setStatus($event, 0)">
  <div class="resize-action" *ngIf="editMode" (mousedown)="setStatus($event, 1)"></div>
  <span
    class="popover-element"
    [routerLink]="allowNavigation && !editMode ? routerLink : []"
    [class.hidden]="editMode"
    [tp]="popover"
    [tpData]="popoverData"
    tpTrigger="mouseenter"
    tpVariation="popper"
    tpPlacement="bottom"></span>
  <!-- keep "dev-info" elements for development purposes -->
  <!-- <span class="dev-info" *ngIf="editMode">{{ width | number : '1.0-0' }}px</span>
  <span class="dev-info" *ngIf="editMode">{{ height | number : '1.0-0' }}px</span>
  <span class="dev-info" *ngIf="editMode">({{ left }}, {{ top }})</span> -->

  <div class="attachment-info">
    <button (click)="confirm()" class="test-confirm-btn btn-green mr-2"><mat-icon>check</mat-icon></button>
    <button (click)="cancel()" class="test-close-btn btn-red"><mat-icon>close</mat-icon></button>
  </div>
</div>
