import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TIPPY_REF, TippyInstance } from '@ngneat/helipopper';
import { AttachmentPopoverComponent } from '@abstraction';
import { AppService } from '@services';
import { Store } from '@ngrx/store';
import { AppState, updateRoom } from '@ngrx-store';
import { MatDialog } from '@angular/material/dialog';
import { DirectivesModule } from '@directives';

@Component({
  selector: 'app-attachment-space-popover',
  templateUrl: '../attachment-popover.component.html',
  standalone: true,
  imports: [CommonModule, DirectivesModule],
})
export class AttachmentSpacePopoverComponent extends AttachmentPopoverComponent {
  constructor(
    @Inject(TIPPY_REF) tippy: TippyInstance,
    appService: AppService,
    private store: Store<AppState>,
    dialog: MatDialog
  ) {
    super(tippy, dialog, appService);
  }

  deleteAttachment() {
    this.store.dispatch(
      updateRoom({
        locationId: this.locationId,
        spaceId: this.id,
        data: { unstructuredDataReference: '' },
      })
    );
  }
}
