<div class="flex justify-between items-center mb-4">
  <app-page-header>Users Management</app-page-header>
  <a appRestrictedEl [showForClientAdmin]="true" class="btn-green btn-medium" routerLink="add-new-user">
    <mat-icon fontIcon="add" />
    Add New User
  </a>
</div>
<mat-card class="card shadow-card pb-0">
  <mat-card-content class="pb-2 px-0">
    <app-users-table tableId="users-main-table" />
  </mat-card-content>
</mat-card>
