import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@ngrx-store';
import { MatDialog } from '@angular/material/dialog';
import { UserDetailsClass } from '@abstraction';

@Component({
  selector: 'app-user-details-page',
  templateUrl: './user-details-page.component.html',
  styleUrls: ['./user-details-page.component.scss'],
})
export class UserDetailsPageComponent extends UserDetailsClass {
  constructor(override route: ActivatedRoute, override store: Store<AppState>, override dialog: MatDialog) {
    super(route, store, dialog);
  }
}
