import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementMainPageComponent } from './containers/user-management-main-page/user-management-main-page.component';
import { RouterModule } from '@angular/router';
import { AddNewUserComponent } from '@app/modules/user-management/containers/add-new-user/add-new-user.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UserDataFormComponent } from '@app/modules/user-management/components/user-data-form/user-data-form.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { UserDetailsPageComponent } from './containers/user-details-page/user-details-page.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PipesModule } from '@pipes';
import { clientUsersResolver } from '@ngrx-store';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DirectivesModule } from '@directives';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { SkeletonPageHeaderComponent } from '@standalone/skeleton-page-header/skeleton-page-header.component';
import { UserDetailsComponent } from '@standalone/user-details/user-details.component';
import { BreadcrumbsComponent } from '@standalone/breadcrumbs/breadcrumbs.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { UsersTableComponent } from '@standalone/_tables/users-table/users-table.component';
import { RoleGuard } from '@app-lib';

@NgModule({
  declarations: [UserManagementMainPageComponent, AddNewUserComponent, UserDataFormComponent, UserDetailsPageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        resolve: {
          userClientList: clientUsersResolver,
        },
        children: [
          {
            path: '',
            component: UserManagementMainPageComponent,
          },
          {
            path: 'add-new-user',
            component: AddNewUserComponent,
            canActivate: [RoleGuard],
            data: {
              activeForAdmin: true,
            },
          },
          {
            path: ':id',
            component: UserDetailsPageComponent,
          },
        ],
      },
    ]),
    MatCardModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatCardModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatCheckboxModule,
    PipesModule,
    MatTooltipModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    DirectivesModule,
    NgxSkeletonLoaderModule,
    NgxMaskDirective,
    NgxMaskPipe,
    SkeletonPageHeaderComponent,
    UserDetailsComponent,
    BreadcrumbsComponent,
    PageHeaderComponent,
    UsersTableComponent,
  ],
})
export class UserManagementModule {}
