import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState, isSuperAdmin, selectUser } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { UserModel } from '@models';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styles: [],
})
export class UserInfoComponent {
  user$: Observable<UserModel | undefined>;
  isSuperAdmin$: Observable<boolean>;

  constructor(private store: Store<AppState>) {
    this.user$ = this.store.select(selectUser);
    this.isSuperAdmin$ = this.store.select(isSuperAdmin);
  }
}
