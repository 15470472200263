import { MatSelectChange } from '@angular/material/select';
import { signal } from '@angular/core';
import { COUNTRY_LIST, DEFAULT_COUNTRY } from '@app-lib';

export abstract class PhoneNumberClass {
  phoneMask = signal(DEFAULT_COUNTRY.mask);
  phoneMaskExpression = signal(DEFAULT_COUNTRY.maskExpression);
  changeMaskSettings($event: MatSelectChange) {
    const country = COUNTRY_LIST.find(country => country.code === $event.value);
    if (country?.mask && country.maskExpression) {
      this.phoneMask.set(country?.mask);
      this.phoneMaskExpression.set(country.maskExpression);
    }
  }
}
