import { Component, Input } from '@angular/core';
import { LocationData } from '@models';
import { NgIf } from '@angular/common';
import { SpaceCardInfoComponent } from '@standalone/space-card-info/space-card-info.component';

@Component({
  selector: 'app-site-map-marker',
  template: `
    <app-space-card-info
      *ngIf="site.incidentCountByStatuses && site.devicesByStatuses"
      [status]="site.status"
      [incidentNew]="site.incidentCountByStatuses.newCount"
      [incidentInProgress]="site.incidentCountByStatuses.inProgressCount"
      [spaceLink]="['/dashboard', site.id]"
      [name]="site.friendlyName || site.name"
      [address]="site.address.country + ', ' + site.address.city + ', ' + site.address.streetAddress"
      [deviceActive]="site.devicesByStatuses.activeCount"
      [devicePaused]="site.devicesByStatuses.pausedCount" />
  `,
  standalone: true,
  styles: [],
  imports: [NgIf, SpaceCardInfoComponent],
})
export class SiteMapMarkerComponent {
  @Input() site!: LocationData;
}
