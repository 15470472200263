import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogConfig,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { AppService } from '@services';

export interface ConfirmationDialogProps {
  title: string;
  description: string;
}

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  templateUrl: './confirmation-dialog.component.html',
  imports: [MatDialogClose, MatDialogActions, MatDialogContent],
})
export class ConfirmationDialogComponent {
  static open(dialog: MatDialog, data: ConfirmationDialogProps) {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.panelClass = 'app-dialog';
    config.backdropClass = 'backdrop-modal-panel';
    config.data = {
      ...data,
    };
    const dialogRef = dialog.open(ConfirmationDialogComponent, config);
    return dialogRef.afterClosed();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogProps,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public appService: AppService
  ) {}

  confirm() {
    this.dialogRef.close(true);
  }
}
