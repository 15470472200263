import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterById',
})
export class FilterByIdPipe implements PipeTransform {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  transform(items: any[], id: string) {
    if (!items || !id) {
      return items;
    }

    return items.filter(item => item.id === id);
  }
}
