import { Component, DestroyRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpaceplanComponent } from '../spaceplan/spaceplan.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { UploadSpaceplanComponent } from '../upload-spaceplan/upload-spaceplan.component';
import { MatIconModule } from '@angular/material/icon';
import { defaultDocumentTab, generateDocumentType } from '@app-lib';
import {
  AppState,
  areRoomDocumentsLoading,
  getDeviceBySpaceId,
  getRoomDocuments,
  getRoomImages,
  isRoomplanLoading,
  userRole,
} from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService } from '@services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeviceData, RoomSpaceModel, SpaceImage, UserRolesIds } from '@models';
import { Observable } from 'rxjs';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { Content } from '@ngneat/overview';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-spaceplan-tabs',
  standalone: true,
  imports: [
    CommonModule,
    SpaceplanComponent,
    NgxSkeletonLoaderModule,
    MatButtonToggleModule,
    UploadSpaceplanComponent,
    MatIconModule,
    NoDataComponent,
    MatProgressSpinner,
  ],
  templateUrl: './spaceplan-tabs.component.html',
})
export class SpaceplanTabsComponent implements OnChanges {
  @Input() locationId = '';
  @Input() room: RoomSpaceModel | undefined;
  @Input() popover: Content | null = null;
  @Input() canEdit = false;
  @Output() tabSelect = new EventEmitter<string>();

  userRoleIds = UserRolesIds;
  currentUserRoleId = '';
  areRoomDocumentsLoading$: Observable<boolean>;
  isRoomplanLoading$: Observable<boolean>;
  deviceList$: Observable<DeviceData[]> | undefined;
  defaultTab = defaultDocumentTab;
  selectedDocumentTab = '';
  documentTabs: string[] = [];
  documents: SpaceImage[] = [];

  constructor(private store: Store<AppState>, private appService: AppService, private destroyRef: DestroyRef) {
    this.store
      .select(userRole)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(roleId => (this.currentUserRoleId = roleId));

    this.areRoomDocumentsLoading$ = this.store.select(areRoomDocumentsLoading);
    this.isRoomplanLoading$ = this.store.select(isRoomplanLoading);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.room && this.room.parentSpaceId && changes['room'].currentValue.id !== changes['room'].previousValue?.id) {
      this.store.dispatch(
        getRoomDocuments({
          locationId: this.locationId,
          floorId: this.room.parentSpaceId,
          roomId: this.room.id,
        })
      );

      this.deviceList$ = this.store.select(getDeviceBySpaceId(this.room.id));
      this.store
        .select(getRoomImages)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(documents => {
          this.documents = documents;
          this.generateDocumentTabs(documents);
        });
    }
  }

  documentTabChange(change: MatButtonToggleChange) {
    this.appService.activeAttachmentId = null;
    this.selectedDocumentTab = change.value;
    this.tabSelect.emit(change.value);
  }

  generateDocumentTabs(documents: SpaceImage[]) {
    const docTabs = documents.map(({ tab }, index) => tab || generateDocumentType(index));
    this.documentTabs = [...new Set([...this.documentTabs, ...docTabs].filter(item => docTabs.includes(item)))];

    if (this.currentUserRoleId === this.userRoleIds.SuperAdmin) {
      this.documentTabs.push(defaultDocumentTab);
      this.selectedDocumentTab = this.selectedDocumentTab ? this.documentTabs[this.documentTabs.length - 1] : '';
    } else if (documents.length) {
      this.selectedDocumentTab = this.documentTabs[0];
    }
  }
}
