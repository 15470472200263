<!--<div class="alert-settings" *ngIf="locations | filterBuildingByName : buildingFilterValue as buildingList">-->
<div class="alert-settings" *ngIf="locations | filterBuildingByName : buildingFilterValue as buildingList">
  <div class="header">
    <div>Buildings</div>
    <div>Floors</div>
    <div>Rooms</div>
  </div>
  <div
    *ngFor="let building of buildingList"
    class="building relative test-building-{{ building.id }}"
    [ngClass]="{ 'inner-shadow': uiHelper[building.id].expanded }">
    <section
      matTooltip="This building is empty"
      *ngIf="!building.floors.length"
      class="empty-building-blocker"></section>
    <!--    Building row-->
    <div class="flex test-building-container">
      <div
        class="flex-grow flex cursor-pointer"
        (click)="uiHelper[building.id].expanded = !uiHelper[building.id].expanded">
        <mat-icon class="min-w-[30px]" matPrefix>{{
          uiHelper[building.id].expanded ? 'expand_less' : 'expand_more'
        }}</mat-icon>
        <div class="flex-grow">
          <div>
            <strong>{{ building.name }}</strong>
          </div>
          <div class="pt-3">
            {{ building.address.country }}, {{ building.address.city }}, {{ building.address.streetAddress }},
          </div>
        </div>
      </div>
      <div>
        <mat-checkbox
          [indeterminate]="buildingStateIsIndeterminate(building)"
          [checked]="buildingHasSelectedItems(building.id)"
          (change)="toggleBuildingSelection($event, building)" />
      </div>
    </div>
    <!--    Floors row-->
    <div class="test-floors-container">
      <div *ngIf="uiHelper[building.id].expanded">
        <app-search-input
          class="w-full border-b-2 p-2 absolute top-0 left-0 bg-white z-10"
          placeholder="Enter Floor"
          inputWidth="100%"
          [inputValue]="uiHelper[building.id].floorSearch"
          (searchDataEvent)="floorSearchChanged($event, building.id)" />
        <ul *ngIf="building.floors | filterFloorByName : uiHelper[building.id].floorSearch as floorList">
          <li
            class="test-floor-item"
            *ngFor="let floor of floorList | filterFloorByName : uiHelper[building.id].floorSearch"
            [ngClass]="{ selected: floor.id === uiHelper[building.id].floor?.id }">
            <span (click)="selectFloor(floor, building)" class="flex-grow">{{ floor.name }}</span>
            <span matSuffix>
              <mat-checkbox
                [indeterminate]="floorStateIsIndeterminate(building, floor)"
                [checked]="isFloorChecked(building.id, floor.id)"
                (change)="toggleAllRoomsOnTheFloor($event, building, floor)" />
            </span>
          </li>
          <li class="no-results" *ngIf="!floorList.length">No Results</li>
        </ul>
      </div>
    </div>
    <!--    Rooms row-->
    <div class="test-rooms-container">
      <div *ngIf="uiHelper[building.id].expanded && uiHelper[building.id].floor?.rooms">
        <app-search-input
          class="w-full border-b-2 p-2 absolute top-0 left-0 bg-white z-10"
          placeholder="Enter Floor"
          inputWidth="100%"
          [inputValue]="uiHelper[building.id].roomSearch"
          (searchDataEvent)="roomSearchChanged($event, building.id)" />
        <ul
          *ngIf="uiHelper[building.id].floor?.rooms | filterRoomsByName : uiHelper[building.id].roomSearch as roomList">
          <li class="test-room-item" *ngFor="let room of roomList">
            <span class="flex-grow">{{ room.name }}</span>
            <span matSuffix>
              <mat-checkbox
                [checked]="isRoomChecked(building.id, room.id)"
                (change)="toggleRoomSelection($event, building, room.id)" />
            </span>
          </li>
          <li class="no-results" *ngIf="!roomList.length">No Results</li>
        </ul>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!buildingList.length">
    <app-filter-no-buildings-found />
  </ng-container>
</div>
