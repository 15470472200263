import { Component, Input, Output, EventEmitter, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { EntityStatus, FloorSpaceModel } from '@models';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-floor-item',
  templateUrl: './floor-item.component.html',
})
export class FloorItemComponent {
  @Input() locationId!: string;
  @Input() floor!: FloorSpaceModel;
  @Output() deleteFloorEvent = new EventEmitter<string>();
  @Output() editFloorEvent = new EventEmitter<Partial<FloorSpaceModel>>();
  destroyRef = inject(DestroyRef);
  isEditable = false;
  entityStatus = EntityStatus;

  constructor(private dialog: MatDialog) {}

  deleteFloor() {
    ConfirmationDialogComponent.open(this.dialog, {
      title: 'Remove floor',
      description: `Are you sure you want to remove floor ${this.floor.friendlyName}?`,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.deleteFloorEvent.emit(this.floor.id);
        }
      });
  }

  archiveFloor() {
    ConfirmationDialogComponent.open(this.dialog, {
      title: 'Archive floor',
      description: `Are you sure you want to archive floor [${this.floor.friendlyName}]?`,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.editFloorEvent.emit({ status: EntityStatus.Archived });
        }
      });
  }

  restoreFloor() {
    ConfirmationDialogComponent.open(this.dialog, {
      title: 'Restore floor',
      description: `Are you sure you want to restore floor [${this.floor.friendlyName}]?`,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.editFloorEvent.emit({ status: EntityStatus.Active });
        }
      });
  }

  edit() {
    this.isEditable = true;
  }

  clickOutside() {
    this.isEditable = false;
  }

  updateFloorName(data: Record<FloorSpaceModel['friendlyName'], string>) {
    this.editFloorEvent.emit(data);
    this.isEditable = false;
  }
}
