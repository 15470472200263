<mat-form-field class="{{ selectClass }}" [ngClass]="value && value.length ? 'has-selected-value' : ''">
  <mat-label
    ><span *ngIf="showLabelPlaceholder ? true : !(value && value.length)">{{ label }}</span
    >{{ showSelectedValues ? labelDetails : '' }}</mat-label
  >
  <mat-select
    [value]="value"
    [panelClass]="cssPanelClass"
    #selectRef
    multiple
    [class]="selectElementClass"
    (selectionChange)="selectionChange($event)">
    <div class="options-action-wrapper">
      <button class="options-action-button test-select-all" (click)="selectAll()">Select All</button>
      <button class="options-action-button test-clear-all" (click)="clearAll()">Clear All</button>
    </div>
    <mat-option
      *ngFor="let option of sortingEnabled ? (options | sort : 'asc' : 'title') : options"
      [value]="option.value"
      [attr.data-value]="option.value"
      >{{ option.title }}</mat-option
    >
  </mat-select>
  <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
  <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
</mat-form-field>
