import { Action, Store } from '@ngrx/store';
import { AppState, isLocationLoading, locationState } from '@ngrx-store';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@services';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DestroyRef, inject } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EntityStatus, LocationFullData } from '@models';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';

export abstract class SpaceDetailsClass {
  protected readonly EntityStatus = EntityStatus;
  destroyRef = inject(DestroyRef);
  locationId: string;
  floorId: string | null = '';
  roomId: string | null = '';
  locationData!: LocationFullData;
  loading$!: Observable<boolean>;

  onLocationLoaded = () => {};

  protected constructor(
    public store: Store<AppState>,
    public dialog: MatDialog,
    public appService: AppService,
    public route: ActivatedRoute
  ) {
    this.locationId = this.route.snapshot.paramMap.get('locationId') || '';
    this.floorId = this.route.snapshot.paramMap.get('floorId');
    this.roomId = this.route.snapshot.paramMap.get('roomId');
    this.getStoreData();
  }

  getStoreData() {
    this.loading$ = this.store.select(isLocationLoading);
    this.store
      .select(locationState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(location => {
        if (location) {
          this.locationData = location;
          this.locationData.floors.filter(floor => floor.status !== EntityStatus.Archived);
          this.onLocationLoaded();
        }
      });
  }

  openConfirmationDialog({ action, title, description }: { title: string; description: string; action: Action }) {
    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(action);
        }
      });
  }
}
