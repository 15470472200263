export interface ResetPassword {
  password: string;
  confirmPassword: string;
  token: string;
  userPolicyId: string;
}

export interface ChangePassword {
  password: string;
  confirmPassword: string;
}
