import { Component, DestroyRef, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WidgetCreationBase, WidgetModel } from '@models';
import { openAddSiteDialog } from '../add-site-dialog/add-site-dialog.component';
import { NotificationsService } from '@services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-site-widget',
  templateUrl: './site-widget.component.html',
  styleUrls: ['./site-widget.component.scss'],
})
export class SiteWidgetComponent {
  destroyRef = inject(DestroyRef);
  spaces: WidgetModel[] = [];

  constructor(private dialog: MatDialog, private notificationService: NotificationsService) {}

  createFakeWidget(data: WidgetCreationBase): WidgetModel {
    const space: WidgetModel = {
      id: `${this.spaces.length + 1}`,
      name: data.name,
      building: {
        id: data.buildingId,
        value: 'some building address',
      },
      incidents: 34,
      devices: 15,
      deviceAlert: 4,
      deviceWarning: 3,
      deviceGood: 7,
      deviceOffline: 1,
    };

    if (data.floorId) {
      space['floor'] = {
        id: data.floorId,
        value: 'floor 1',
      };
    }

    if (data.roomId) {
      space['room'] = {
        id: data.roomId,
        value: 'room 1',
      };
    }

    return space;
  }

  openAddSpaceDialog() {
    openAddSiteDialog(this.dialog)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((spaceData: WidgetCreationBase) => {
        if (spaceData) {
          this.spaces.push(this.createFakeWidget(spaceData));
          this.notificationService.showSuccessMessage('A new note was successfully created');
        }
      });
  }

  openEditDialog(space: WidgetModel) {
    openAddSiteDialog(this.dialog, space)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((spaceData: WidgetCreationBase) => {
        if (spaceData) {
          this.notificationService.showSuccessMessage('A new note was successfully updated');
        }
      });
  }

  openDeleteDialog(id: WidgetModel['id'], name: WidgetModel['name']) {
    ConfirmationDialogComponent.open(this.dialog, {
      title: 'Delete Widget',
      description: `Are you sure you want to delete widget [${name}]?`,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.spaces.forEach((value, index) => {
          if (value.id === id) this.spaces.splice(index, 1);
        });
        this.notificationService.showSuccessMessage(`Site [${name}] was successfully deleted`);
      });
  }
}
