import { locationAdapter, LocationsState } from './locations.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const { selectAll } = locationAdapter.getSelectors();

export const selectLocationsState = createFeatureSelector<LocationsState>('locations');

export const selectAllLocations = createSelector(selectLocationsState, selectAll);

export const areLocationsLoaded = createSelector(selectLocationsState, state => state.allLocationsLoaded);

export const isLocationLoading = createSelector(selectLocationsState, state => state.isLocationLoading);

export const isFullLocationLoaded = createSelector(selectLocationsState, state => state.isFullLocationLoaded);

export const selectLocation = (props: { id: string }) =>
  createSelector(selectLocationsState, state => {
    return state.entities[props.id];
  });

export const locationState = createSelector(selectLocationsState, state =>
  Object.keys(state.locationState).length ? state.locationState : undefined
);

export const locationErrorSelect = createSelector(selectLocationsState, state => state.locationError);

export const isFloorLoading = createSelector(selectLocationsState, state => state.floorLoading);

export const isRoomLoading = createSelector(selectLocationsState, state => state.roomLoading);

export const isRoomUpdating = createSelector(selectLocationsState, state => state.roomUpdateLoading);

export const isLocationsSummariesLoading = createSelector(
  selectLocationsState,
  state => state.isLocationsSummariesLoading
);

export const areRoomDocumentsLoading = createSelector(selectLocationsState, state => state.areRoomDocumentsLoading);

export const isRoomplanLoading = createSelector(selectLocationsState, state => state.roomplanLoading);

export const getRoomImages = createSelector(selectLocationsState, state => state.roomDocuments);
