import { Component, OnDestroy } from '@angular/core';
import { BuildingDataFilterService } from '@services';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { NgForOf, NgIf } from '@angular/common';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MultipleSelectComponent } from '@standalone/multiple-select/multiple-select.component';
import { MatButton } from '@angular/material/button';
import { PipesModule } from '@pipes';

@Component({
  standalone: true,
  selector: 'app-site-table-desktop-filters',
  templateUrl: './site-table-desktop-filters.component.html',
  imports: [
    MatButtonToggleGroup,
    MatButtonToggle,
    SearchInputComponent,
    NgIf,
    MatLabel,
    MatIcon,
    MatOption,
    MatSelect,
    MatSuffix,
    MatFormField,
    MultipleSelectComponent,
    MatButton,
    NgForOf,
    PipesModule,
  ],
})
export class SiteTableDesktopFiltersComponent implements OnDestroy {
  constructor(public buildingDataFilterService: BuildingDataFilterService) {}

  get selectedFilters(): boolean {
    return !!(this.buildingDataFilterService.statusFilter || this.buildingDataFilterService.incidentFilter?.length);
  }

  clearAllFilters() {
    this.buildingDataFilterService.statusFilter = '';
    this.buildingDataFilterService.incidentFilter = [];
  }

  ngOnDestroy() {
    this.buildingDataFilterService.clearFilters();
  }
}
