declare global {
  interface Window {
    google: {};
  }
}

export function setGoogleMapMock() {
  window.google = window.google || {
    maps: {
      Map: function () {
        this.fitBounds = (_bounds: any, _padding: any) => {};
        this.setCenter = () => {};
        this.setZoom = () => {};
        this.addListener = () => {};
      },
      OverlayView: function () {},
      Geocoder: function () {
        this.geocode = function () {
          return new Promise(resolve => {
            resolve({
              results: [
                {
                  address_components: [],
                  formatted_address: '',
                  geometry: {
                    location: {},
                    location_type: '',
                    viewport: {},
                  },
                  partial_match: true,
                  place_id: '',
                  plus_code: {
                    global_code: '',
                  },
                  postcode_localities: [''],
                  types: ['type 1', 'type 2'],
                },
              ],
            });
          });
        };
      },
      Marker: function () {
        this.addListener = () => {};
        this.setPosition = () => {};
        this.setMap = () => {};
      },
      InfoWindow: function () {
        this.setContent = () => {};
        this.close = () => {};
        this.setOptions = () => {};
      },
      LatLng: function (lat: any, lng: any) {
        this.lat = () => lat;
        this.lng = () => lng;
      },
      Size: function () {},
      Animation: {
        DROP: '',
      },
      LatLngBounds: function () {},
      MapTypeId: { ROADMAP: true },
      places: {
        Autocomplete: function () {},
        AutocompleteService: function () {},
        PlacesService: function (_obj: any) {
          return {
            PlacesServiceStatus: {
              OK: true,
            },
            textSearch: function (_query: any) {
              return [];
            },
            nearbySearch: function (_query: any) {
              return [];
            },
          };
        },
      },
      GeocoderStatus: {
        OK: 'OK',
      },
      importLibrary: () => {},
      marker: {
        AdvancedMarkerElement: function () {},
      },
    } as any,
  };
}
