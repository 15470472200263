<div class="border-x border-b border-ui-gray-light py-2 pr-2 pl-3 flex justify-between items-center h-11">
  <a
    [routerLink]="['/dashboard', locationId, floorId, room.id]"
    class="test-roomname text-sm font-nunito text-ui-dark overflow-hidden w-[155px] text-ellipsis whitespace-nowrap underline"
    >{{ room.friendlyName }}</a
  >
  <div class="flex items-center">
    <ng-container *ngIf="room.status !== entityStatus.Archived">
      <button
        *ngIf="!room.unstructuredDataReference"
        [disabled]="!floorplan"
        class="btn-blue btn-small"
        [class.bg-ui-blue-150]="editRoomId === room.id"
        (click)="assignRoom()">
        <i class="icon-Assign-a-Room text-3xl mr-1"></i> Assign a Room
      </button>
      <div *ngIf="room.unstructuredDataReference" class="flex items-center test-floorplan-button-block">
        <button
          class="btn-white-stroked hover:text-white hover:bg-ui-blue-100 active:bg-ui-blue-150 btn-small mr-2"
          [disabled]="!floorplan"
          [class]="editRoomId === room.id ? 'bg-ui-blue-150 text-white' : ''"
          (click)="assignRoom()">
          <i class="icon-move text-3xl mr-1"></i>
          Move/Resize
        </button>
        <button
          class="btn-white-stroked btn-small hover:bg-ui-red-50 hover:text-white active:bg-ui-red-150"
          (click)="removeCoordinates()">
          <i class="icon-Unassign text-3xl mr-1"></i>
          Unassign
        </button>
      </div>
      <a
        *ngIf="room.externalReference"
        [href]="room.externalReference"
        target="_blank"
        [matTooltip]="'Doc link'"
        class="inline-flex items-center ml-2">
        <i class="icon-link text-3xl text-ui-gray-100"></i>
      </a>
      <i
        [matTooltip]="'Edit'"
        class="icon-edit text-ui-gray-100 text-3xl cursor-pointer inline-flex items-center mr-1 ml-2"
        (click)="edit()"></i>
      <i
        [matTooltip]="'Archive'"
        class="test-delete-button icon-add-to-archive text-ui-gray-100 text-3xl cursor-pointer"
        (click)="archiveRoom()"></i>
    </ng-container>
    <i
      *ngIf="room.status === entityStatus.Archived"
      [matTooltip]="'Restore'"
      class="icon-restore text-ui-gray-100 text-3xl cursor-pointer inline-flex items-center"
      (click)="unarchiveRoom()"></i>
  </div>
</div>
