import { Component, DestroyRef, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IncidentModel } from '@models';
import { AppState, selectLocationIncidents } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService, IncidentsFilterService, IncidentsService } from '@services';

@Component({
  selector: 'app-building-plan-incidents',
  templateUrl: './building-plan-incidents.component.html',
})
export class BuildingPlanIncidentsComponent implements OnInit, OnDestroy {
  @Input() locationId = '';
  incidentTableColumns = ['status', 'faultname', 'deviceId', 'floor', 'room', 'deviceType', 'reported'];
  resolvedIncidents: IncidentModel[] = [];
  resolvedIncidentsLoading = false;
  destroyRef = inject(DestroyRef);

  constructor(
    private store: Store<AppState>,
    private incidentsService: IncidentsService,
    private appService: AppService,
    private incidentsFilterService: IncidentsFilterService
  ) {}

  ngOnInit(): void {
    if (this.locationId) {
      this.store
        .select(selectLocationIncidents(this.locationId))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(incidents => {
          this.incidentsFilterService.initOpenIncidents(incidents);
        });
      this.incidentsFilterService.initFilterTableType({
        displayedColumns: this.incidentTableColumns,
        isBuildingTable: true,
      });
    }

    this.incidentsFilterService.resolvedDates$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => this.getResolvedIncidents(value));
  }

  ngOnDestroy(): void {
    this.incidentsFilterService.resetState();
  }

  getResolvedIncidents(dateRange: { startDateTime: string; endDateTime: string }) {
    if (dateRange.startDateTime && dateRange.endDateTime) {
      this.resolvedIncidentsLoading = true;
      this.incidentsService
        .getAllResolvedIncidents(this.appService.currentClient, {
          ...dateRange,
          locationId: this.locationId,
        })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: data => {
            this.resolvedIncidents = data;
            this.incidentsFilterService.initResolvedIncidents(data);
            this.resolvedIncidentsLoading = false;
          },
          error: () => {
            this.resolvedIncidentsLoading = false;
          },
        });
    }
  }
}
