import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DeviceData } from '@models';

@Component({
  selector: 'app-device-location-widget',
  templateUrl: './device-location-widget.component.html',
  styleUrls: ['./device-location-widget.component.scss'],
})
export class DeviceLocationWidgetComponent implements OnInit, OnChanges {
  @Input() device!: DeviceData;
  address!: string;

  ngOnInit(): void {
    this.updateAddress();
  }

  updateAddress() {
    if (this.device?.location?.address) {
      this.address = `${this.device.location.address.streetAddress || ''} ${this.device.location.address?.city || ''} ${
        this.device.location.address.stateProvince || ''
      }
      ${this.device.location.address?.postalCode || ''} ${this.device.location.address.country || ''}`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device']) {
      this.updateAddress();
    }
  }
}
