<div class="w-[247px] bg-white text-ui-dark flex justify-between overflow-hidden">
  <div class="w-[calc(100%_-_24px)]">
    <h4
      class="font-poppins font-semibold text-base whitespace-nowrap overflow-hidden text-ellipsis flex items-center"
      [class.mb-1.5]="status !== entityStatus.Archived"
      [class.h-6]="status === entityStatus.Archived">
      <i class="icon-add-to-archive text-ui-gray-100 text-3xl" *ngIf="status === entityStatus.Archived"></i>
      <i class="icon-offline text-ui-gray-100 text-3xl" *ngIf="status === entityStatus.Paused"></i>
      <ng-container *ngIf="status === entityStatus.Active">
        <i class="icon-CheckCircleRounded text-ui-green-light text-3xl" *ngIf="!incidentNew && !incidentInProgress">
          <span class="path1"></span><span class="path2"></span>
        </i>
        <i class="icon-alert text-ui-red-50 text-3xl" *ngIf="incidentNew > 0"></i>
        <i class="icon-warning text-ui-yellow-100 text-3xl" *ngIf="incidentInProgress > 0 && !incidentNew"></i>
      </ng-container>
      <a
        class="inline-flex ml-1.5 text-sm"
        [class]="spaceLink.length ? 'hover:underline cursor-pointer' : 'cursor-default'"
        [routerLink]="spaceLink"
        >{{ name }}</a
      >
    </h4>

    <p
      *ngIf="address"
      class="test-address font-nunito font-semibold text-xs mb-1.5 whitespace-nowrap overflow-hidden text-ellipsis w-full">
      {{ address }}
    </p>
    <div class="flex items-stretch mb-1.5 justify-start" *ngIf="status === entityStatus.Active">
      <div>
        <p class="font-nunito text-[10px] mb-0 flex items-center">Incidents</p>
        <ul class="flex">
          <li class="site-device-status" *ngIf="incidentNew">
            <span class="status-indicator bg-ui-red-50"></span>{{ incidentNew }}
          </li>
          <li class="site-device-status" *ngIf="incidentInProgress">
            <span class="status-indicator bg-ui-yellow-100"></span> {{ incidentInProgress }}
          </li>
        </ul>
      </div>
      <div class="mx-2 my-1 border-l border-ui-gray-light"></div>
      <div>
        <p class="font-nunito text-[10px] mb-0 flex items-center">Devices</p>
        <app-device-status-count
          *ngIf="status === entityStatus.Active"
          size="small"
          [showTooltip]="false"
          [activeAmount]="deviceActive"
          [pausedAmount]="devicePaused" />
      </div>
    </div>
  </div>
  <div class="w-6 flex flex-col justify-end">
    <a *ngIf="spaceLink.length" [routerLink]="spaceLink" class="flex" [class.mb-2]="status !== entityStatus.Archived"
      ><i class="icon-arrow-right text-ui-dark text-3xl"></i
    ></a>
  </div>
</div>
