import { createFeatureSelector, createSelector } from '@ngrx/store';
import { childClientAdapter, ChildClientState } from './clients-managements.reducer';

const { selectAll } = childClientAdapter.getSelectors();

export const selectChildClientState = createFeatureSelector<ChildClientState>('clients');

export const selectChildClients = createSelector(selectChildClientState, selectAll);

export const childClientIsLoading = createSelector(selectChildClientState, state => state.isLoading);

export const selectChildClientById = (childClientId: string) => {
  return createSelector(selectChildClients, clientList => clientList.find(client => client.id === childClientId));
};
