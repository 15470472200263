import { Component } from '@angular/core';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogConfig,
  MatDialogContent,
} from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmedValidator, passwordValidationPattern } from '@app-lib';
import { Store } from '@ngrx/store';
import { AppState, authChangeUserPassword, isLoading } from '@ngrx-store';
import { Observable } from 'rxjs';
import { MatError, MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { PasswordRequirementsComponent } from '@standalone/password-requirements/password-requirements.component';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  standalone: true,
  styles: [],
  imports: [
    MatError,
    MatSuffix,
    ReactiveFormsModule,
    MatInput,
    MatFormField,
    MatDialogContent,
    MatDialogClose,
    MatDialogActions,
    AsyncPipe,
    MatProgressSpinner,
    NgIf,
    PasswordRequirementsComponent,
  ],
})
export class ChangePasswordDialogComponent {
  isLoading$!: Observable<boolean | undefined>;
  form: FormGroup;
  hideInput: { password: boolean; confirmPassword: boolean } = {
    password: true,
    confirmPassword: true,
  };

  constructor(private fb: FormBuilder, private store: Store<AppState>) {
    this.isLoading$ = this.store.select(isLoading);
    this.form = this.fb.group(
      {
        password: ['', [Validators.required, Validators.pattern(passwordValidationPattern)]],
        confirmPassword: ['', [Validators.required, Validators.pattern(passwordValidationPattern)]],
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
      }
    );
  }

  saveChanges() {
    if (this.form.valid) {
      this.store.dispatch(authChangeUserPassword({ data: this.form.value }));
    }
  }
}

export function openChangePasswordDialog(dialog: MatDialog) {
  const config = new MatDialogConfig();
  config.disableClose = true;
  config.panelClass = 'app-dialog';
  config.backdropClass = 'backdrop-modal-panel';
  const dialogRef = dialog.open(ChangePasswordDialogComponent, config);
  return dialogRef.afterClosed();
}
