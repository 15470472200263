import { Component } from '@angular/core';

@Component({
  selector: 'app-client-management-main-page',
  template: `
    <div class="flex justify-between items-center mb-4">
      <app-page-header>Client Management</app-page-header>
      <a class="btn-green btn-medium" routerLink="add-client">
        <mat-icon fontIcon="add" />
        Add New Client</a
      >
    </div>
    <mat-card class="card py-0 pb-2">
      <mat-card-content class="p-0">
        <app-clients-table tableId="client-management-main-page" />
      </mat-card-content>
    </mat-card>
  `,
  styles: [],
})
export class ClientManagementMainPageComponent {}
