import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncidentsMainPageComponent } from './containers/incidents-main-page/incidents-main-page.component';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { IncidentDetailsPageComponent } from './containers/incident-details-page/incident-details-page.component';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { IncidentsService } from '@services';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { BreadcrumbsComponent } from '@standalone/breadcrumbs/breadcrumbs.component';
import { ImgLoaderComponent } from '@standalone/img-loader/img-loader.component';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';
import { IncidentStatusComponent } from '@standalone/incident-status/incident-status.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { IncidentsTableDesktopFiltersComponent } from '@standalone/_tables/incidents-table-desktop-filters/incidents-table-desktop-filters.component';
import { IncidentsTableComponent } from '@standalone/_tables/incidents-table/incidents-table.component';

@NgModule({
  declarations: [IncidentsMainPageComponent, IncidentDetailsPageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: IncidentsMainPageComponent,
      },
      {
        path: ':id',
        component: IncidentDetailsPageComponent,
      },
    ]),
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatSortModule,
    MatDialogModule,
    MatInputModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
    SkeletonTableComponent,
    BreadcrumbsComponent,
    ImgLoaderComponent,
    EntityStatusComponent,
    IncidentStatusComponent,
    PageHeaderComponent,
    IncidentsTableDesktopFiltersComponent,
    IncidentsTableComponent,
  ],
  providers: [IncidentsService],
})
export class IncidentsModule {}
