import { Pipe, PipeTransform } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'srcSecurePipe',
})
export class SrcSecurePipePipe implements PipeTransform {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  transform(url: string, errorUrl = ''): Observable<SafeUrl> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))),
      catchError(() => {
        return of(errorUrl);
      })
    );
  }
}
