<h1 class="dialog-title">
  <span>Add new device type</span>
  <button mat-dialog-close>
    <i class="icon-close"></i>
  </button>
</h1>
<form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="addDeviceType()">
  <mat-dialog-content class="dialog-content">
    <div class="flex flex-row">
      <label for="deviceType" class="input-main-label">Device type name:*</label>
      <mat-form-field class="input-main">
        <input matInput id="deviceType" type="text" placeholder="Enter device type" formControlName="deviceType" />
        <mat-error class="test-required-error" *ngIf="form.get('deviceType')?.hasError('required')"
          >Input is not valid</mat-error
        >
        <mat-error class="test-already-exist-error" *ngIf="form.get('deviceType')?.hasError('alreadyExist')"
          >Value already exist</mat-error
        >
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-dialog-close class="test-btn-cancel btn-transparent btn-medium mr-4 font-bold">CANCEL</button>
    <button type="submit" class="btn-green btn-medium font-bold">SAVE</button>
  </mat-dialog-actions>
</form>
