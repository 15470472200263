import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms';
import { DeviceHelperService } from '@services';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogConfig,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { capitalize, removeSpacesValidator } from '@app-lib';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectOption } from '@models';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-add-device-type-dialog',
  templateUrl: './add-device-type-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogClose,
    MatDialogContent,
    MatFormField,
    MatError,
    MatDialogActions,
    ReactiveFormsModule,
    MatInput,
    NgIf,
  ],
})
export class AddDeviceTypeDialogComponent {
  @ViewChild('ngForm') ngForm: FormGroupDirective | undefined;
  form: FormGroup;
  deviceTypeOptions: SelectOption[] = [];

  constructor(
    private fb: FormBuilder,
    private deviceHelperService: DeviceHelperService,
    public dialogRef: MatDialogRef<AddDeviceTypeDialogComponent>
  ) {
    this.form = this.fb.group({
      deviceType: ['', [Validators.required, removeSpacesValidator]],
    });
    this.deviceHelperService.deviceTypeOptions$
      .pipe(takeUntilDestroyed())
      .subscribe(deviceTypeOptions => (this.deviceTypeOptions = deviceTypeOptions));
  }

  addDeviceType() {
    if (this.form.valid) {
      if (this.checkDeviceTypeAlreadyExist()) {
        this.form.controls['deviceType'].setErrors({ alreadyExist: true });
        return;
      }
      this.deviceHelperService.addDeviceTypeOption(capitalize(this.form.value.deviceType));
      this.ngForm?.resetForm();
      // TODO: add API request when it is ready
      // this.dialogRef.close();
    }
  }

  checkDeviceTypeAlreadyExist() {
    return this.deviceTypeOptions.some(({ value }) => value.toLowerCase() === this.form.value.deviceType.toLowerCase());
  }
}

export function openAddDeviceTypeDialog(dialog: MatDialog) {
  const config = new MatDialogConfig();
  config.disableClose = true;
  config.autoFocus = true;
  config.panelClass = 'app-dialog';
  config.backdropClass = 'backdrop-modal-panel';
  const dialogRef = dialog.open(AddDeviceTypeDialogComponent, config);
  return dialogRef.afterClosed();
}
