import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currentDate',
})
export class CurrentDatePipe implements PipeTransform {
  transform(date: string): string {
    return new Date(date).toLocaleString('en-US');
  }
}
