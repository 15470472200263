import { Pipe, PipeTransform } from '@angular/core';
import { EntityStatus, Space } from '@models';

@Pipe({
  name: 'filterSpaceByStatus',
})
export class FilterSpaceByStatusPipe implements PipeTransform {
  transform(items: Space[], status: 'current' | 'archived') {
    if (!items) {
      return [];
    }
    if (!status) {
      return items;
    }

    return items.filter(space => {
      return status === 'archived' ? space.status === EntityStatus.Archived : space.status !== EntityStatus.Archived;
    });
  }
}
