import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IncidentsState, incidentsAdapter } from './incidents.reducer';
import { IncidentStatus } from '@models';

const { selectAll } = incidentsAdapter.getSelectors();

export const selectIncidentsState = createFeatureSelector<IncidentsState>('incidents');

export const selectAllIncidents = createSelector(selectIncidentsState, selectAll);

export const selectIncidentsAmount = createSelector(selectIncidentsState, state => state.ids.length);

export const selectNewIncidentsAmount = createSelector(
  selectAllIncidents,
  state => state.filter(incident => incident.status === IncidentStatus.IN_QUEUE).length
);

export const selectInProgressIncidentsAmount = createSelector(
  selectAllIncidents,
  state => state.filter(incident => incident.status === IncidentStatus.IN_PROGRESS).length
);

export const isIncidentLoading = createSelector(selectIncidentsState, state => state.isLoading);

export const isIncidentLoaded = createSelector(selectIncidentsState, state => state.isLoaded);

export const selectLocationIncidents = (locationId: string) =>
  createSelector(selectAllIncidents, state =>
    state.filter(incident => incident.device.locationId === locationId || incident.device.location.id === locationId)
  );

export const selectSpaceIncidents = (spaceId: string) =>
  createSelector(selectAllIncidents, state =>
    state.filter(incident => incident.device.spacePath?.some(space => space.id === spaceId))
  );

export const selectIncidentById = (incidentId: string) =>
  createSelector(selectAllIncidents, state => state.find(incident => incident.id === incidentId));

export const selectIncidentsByDeviceId = (deviceId: string) =>
  createSelector(selectAllIncidents, state => state.filter(incident => incident.device.id === deviceId));
