import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AppSettingsActions,
  ClientUsersActions,
  DevicesActions,
  IncidentsActions,
  LocationActions,
} from '../action-types';
import { switchMap } from 'rxjs';
import { AppService, SessionStorageService } from '@services';
import { tap } from 'rxjs/operators';

@Injectable()
export class AppSettingsEffects {
  loadClientData = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppSettingsActions.clientIdChanged),
      tap(() => this.sessionStorageService.clear()),
      switchMap(() => [
        ClientUsersActions.resetClientUsers(),
        LocationActions.resetDefaultLocationsState(),
        LocationActions.loadLocations({ clientId: this.appService.currentClient }),
        ClientUsersActions.loadClientUsers({ clientId: this.appService.currentClient }),
        IncidentsActions.resetIncidents(),
        IncidentsActions.loadAllIncidents(),
        DevicesActions.resetDeviceEntity(),
      ])
    );
  });

  constructor(
    private actions$: Actions,
    private appService: AppService,
    private sessionStorageService: SessionStorageService
  ) {}
}
