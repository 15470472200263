<mat-card class="card app-block-shadow p-0">
  <mat-accordion class="mat-elevation-z0">
    <mat-expansion-panel class="mat-elevation-z0" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="text-ui-green-light">
        <mat-panel-title>
          <h2 class="m-0">
            <strong>Status History</strong>
          </h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card-content class="pt-0">
        <div class="w-full py-2 border-t"></div>
        <table class="main-table base-table pt-4" id="site-history-table" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>№</th>
            <td mat-cell *matCellDef="let index = index">
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (index + 1) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="updated">
            <th mat-header-cell *matHeaderCellDef>Date Changed</th>
            <td mat-cell *matCellDef="let element">{{ element.updated | date }}</td>
          </ng-container>

          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef>Entity Name</th>
            <td mat-cell *matCellDef="let element">{{ element.location }}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status changed</th>
            <td mat-cell *matCellDef="let element">{{ element.action }}</td>
          </ng-container>

          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef>User</th>
            <td mat-cell *matCellDef="let element">{{ element.user }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <app-no-data *ngIf="!dataSource.filteredData.length" [resultContent]="!!dataSource.data.length"></app-no-data>

        <mat-paginator
          [ngClass]="dataSource.filteredData.length ? 'visible' : 'invisible'"
          appPaginatorSettings
          elementToScrollId="site-history-table"
          #paginatorRef
          aria-label="Items per page:"></mat-paginator>
      </mat-card-content>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>
