import { Component, DestroyRef, OnDestroy, OnInit, inject } from '@angular/core';
import { DeviceData, EntityStatus, IncidentModel, UserClient } from '@models';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AppState,
  clientList,
  getDeviceById,
  getMyClient,
  selectIncidentsByDeviceId,
  updateDeviceData,
} from '@ngrx-store';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppService, IncidentsFilterService, IncidentsService, NotificationsService } from '@services';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styles: [],
})
export class DeviceDetailsComponent implements OnInit, OnDestroy {
  destroyRef = inject(DestroyRef);
  protected readonly EntityStatus = EntityStatus;
  deviceId: string | null = null;
  statuses = EntityStatus;
  device$!: Observable<DeviceData | undefined>;
  incidents$!: Observable<IncidentModel[]>;
  clients$!: Observable<UserClient[] | undefined>;
  myClient$: Observable<UserClient | undefined>;
  resolvedIncidentsLoading = false;
  incidentsSubs$: Subscription | undefined;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private appService: AppService,
    private incidentsService: IncidentsService,
    private incidentsFilterService: IncidentsFilterService,
    private notificationService: NotificationsService
  ) {
    this.clients$ = this.store.select(clientList);
    this.myClient$ = this.store.select(getMyClient);
  }

  ngOnInit(): void {
    this.deviceId = this.route.snapshot.paramMap.get('deviceId');
    if (this.deviceId) {
      this.device$ = this.store.select(getDeviceById(this.deviceId));
      this.store
        .select(selectIncidentsByDeviceId(this.deviceId))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(incidents => {
          this.incidentsFilterService.initOpenIncidents(incidents);
        });
      this.incidentsFilterService.resolvedDates$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(value => this.getResolvedIncidents(value));
      this.incidentsFilterService.initFilterTableType({
        displayedColumns: ['status', 'reported'],
      });
    }
  }

  ngOnDestroy(): void {
    this.incidentsFilterService.resetState();
  }

  getResolvedIncidents(dateRange: { startDateTime: string; endDateTime: string }) {
    if (dateRange.startDateTime && dateRange.startDateTime && this.deviceId) {
      const params: { [key: string]: string } = { ...dateRange, deviceId: this.deviceId };
      this.resolvedIncidentsLoading = true;
      this.incidentsSubs$ = this.incidentsService
        .getAllResolvedIncidents(this.appService.currentClient, params)
        .subscribe({
          next: data => {
            this.incidentsFilterService.initResolvedIncidents(data);
            this.resolvedIncidentsLoading = false;
          },
          error: () => {
            this.resolvedIncidentsLoading = false;
            this.notificationService.showErrorMessage('Error. Try later');
          },
        });
    }
  }

  openConfirmationDialog({
    title,
    description,
    locationId,
    deviceId,
    data,
  }: {
    title: string;
    description: string;
    locationId: string;
    deviceId: string;
    data: { [key: string]: string };
  }) {
    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation && locationId && deviceId && data) {
          this.store.dispatch(updateDeviceData({ locationId, deviceId, data }));
        }
      });
  }

  resumeDevice(device: DeviceData) {
    this.openConfirmationDialog({
      title: 'Resume',
      description: `Are you sure you want to resume ${device.friendlyName} ?`,
      locationId: device.location.id,
      deviceId: device.id,
      data: { status: EntityStatus.Active },
    });
  }

  pauseDevice(device: DeviceData) {
    this.openConfirmationDialog({
      title: 'Pause',
      description: `Are you sure you want to pause ${device.friendlyName} ?`,
      locationId: device.location.id,
      deviceId: device.id,
      data: { status: EntityStatus.Paused },
    });
  }

  archiveDevice(device: DeviceData) {
    this.openConfirmationDialog({
      title: 'Add to Archive',
      description: `Are you sure you want to add ${device.friendlyName} to archive?`,
      locationId: device.location.id,
      deviceId: device.id,
      data: { status: EntityStatus.Archived },
    });
  }

  restoreDevice(device: DeviceData) {
    this.openConfirmationDialog({
      title: 'Restore',
      description: `Are you sure you want to restore ${device.friendlyName} from archive?`,
      locationId: device.location.id,
      deviceId: device.id,
      data: { status: EntityStatus.Active },
    });
  }
}
