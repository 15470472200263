<h1 class="dialog-title">
  <span>Add new client</span>
  <button mat-dialog-close>
    <i class="icon-close"></i>
  </button>
</h1>
<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content class="dialog-content">
    <div class="flex flex-row">
      <label for="name" class="input-main-label capitalize">Client name*</label>
      <mat-form-field class="input-classic">
        <input matInput id="name" type="text" placeholder="Enter client name" formControlName="name" />
        <mat-error>Form is not valid</mat-error>
      </mat-form-field>
    </div>
    <div class="flex flex-row">
      <label for="email" class="input-main-label capitalize">E-mail*</label>
      <mat-form-field class="input-classic">
        <input matInput id="email" type="text" placeholder="Enter client email" formControlName="email" />
        <mat-error>Form is not valid</mat-error>
      </mat-form-field>
    </div>
    <div class="flex flex-row">
      <label for="phone" class="input-main-label capitalize">Phone*</label>
      <mat-form-field class="input-classic">
        <input matInput id="phone" type="number" placeholder="Enter client phone" formControlName="phone" />
        <mat-error>Form is not valid</mat-error>
      </mat-form-field>
    </div>
    <div class="flex flex-row">
      <label for="website" class="input-main-label capitalize">Website</label>
      <mat-form-field class="input-classic">
        <input matInput id="website" type="string" placeholder="Enter client website" formControlName="website" />
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-dialog-close class="test-btn-cancel btn-transparent btn-medium font-bold mr-4">CANCEL</button>
    <button type="submit" class="btn-green btn-medium font-bold" (click)="submit()">ADD</button>
  </mat-dialog-actions>
</form>
