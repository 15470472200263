import { HarnessLoader, parallel } from '@angular/cdk/testing';
import { MatSelectHarness } from '@angular/material/select/testing';
import { MatTableHarness } from '@angular/material/table/testing';

export async function getSelectOptions(loader: HarnessLoader) {
  const incidentStatusSelect = await loader.getHarness(MatSelectHarness);
  await incidentStatusSelect.open();
  const options = await incidentStatusSelect.getOptions();
  return await Promise.all(
    options.map(async o => {
      return {
        text: await o.getText(),
        isSelected: await o.isSelected(),
      };
    })
  );
}

export async function getTableColumnNames(loader: HarnessLoader) {
  const table = await loader.getHarness(MatTableHarness);
  const headerRows = await table.getHeaderRows();
  const headerCells = await headerRows[0].getCells();
  return await parallel(() => headerCells.map(cell => cell.getColumnName()));
}
