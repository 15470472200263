import { Component, DestroyRef, inject, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ChildClient, UserStatus } from '@models';
import { AppState, selectChildClientById, updateChildClient } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { COUNTRY_LIST, DEFAULT_COUNTRY } from '@app-lib';
import { MatSelectChange } from '@angular/material/select';
import { PhoneNumberClass } from '@abstraction';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styles: [],
})
export class ClientDetailsComponent extends PhoneNumberClass implements OnDestroy {
  protected readonly UserStatus = UserStatus;
  protected readonly DEFAULT_COUNTRY = DEFAULT_COUNTRY;
  protected readonly COUNTRY_LIST = COUNTRY_LIST;
  client: ChildClient | undefined;
  clientSubscription: Subscription;
  isManagingClient = false;
  detailsType: 'sites' | 'users' = 'sites';
  destroyRef = inject(DestroyRef);

  constructor(
    route: ActivatedRoute,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private router: Router
  ) {
    super();
    const childClientId = route.snapshot.params['clientId'];
    this.clientSubscription = this.store.select(selectChildClientById(childClientId)).subscribe(client => {
      this.client = client;
      const selectedCountry = COUNTRY_LIST.find(
        country => country.code === (client?.tags['countryCode'] || DEFAULT_COUNTRY.code)
      );
      if (selectedCountry) {
        this.phoneMask.set(selectedCountry.mask);
        this.phoneMaskExpression.set(selectedCountry.maskExpression);
      }
    });
  }

  ngOnDestroy(): void {
    this.clientSubscription?.unsubscribe();
  }

  updateClientData(fieldData: Record<string, string>, client: ChildClient) {
    let reqBody: Partial<ChildClient> = {};
    if (
      fieldData['website'] !== undefined ||
      fieldData['phoneNumber'] !== undefined ||
      fieldData['ticketingClientId'] !== undefined
    ) {
      reqBody['tags'] = { ...client.tags, ...fieldData };
    } else {
      reqBody = fieldData;
    }

    this.store.dispatch(updateChildClient({ clientData: reqBody, clientClientId: client.id }));
  }

  archiveClient(client: ChildClient) {
    ConfirmationDialogComponent.open(this.dialog, {
      title: 'Add to Archive',
      description: `Do you really want to archive ${client.friendlyName} ?`,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(updateChildClient({ clientData: { isArchived: true }, clientClientId: client.id }));
          this.router.navigate(['client-management']);
        }
      });
  }

  unarchiveClient(client: ChildClient) {
    ConfirmationDialogComponent.open(this.dialog, {
      title: 'Add to Archive',
      description: `Do you really want to unarchive ${client.friendlyName} ?`,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(updateChildClient({ clientData: { isArchived: false }, clientClientId: client.id }));
        }
      });
  }

  activateClient(client: ChildClient) {
    ConfirmationDialogComponent.open(this.dialog, {
      title: 'Activate',
      description: `Are you sure you want to activate client [${client?.friendlyName}]?`,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(
            updateChildClient({
              clientData: { isDisabled: false },
              clientClientId: client.id,
            })
          );
        }
      });
  }

  deactivateClient(client: ChildClient) {
    ConfirmationDialogComponent.open(this.dialog, {
      title: 'Deactivate',
      description: `Are you sure you want to deactivate client [${client?.friendlyName}]?`,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(
            updateChildClient({
              clientData: { isDisabled: true },
              clientClientId: client.id,
            })
          );
        }
      });
  }

  updateTimeout($event: number, client: ChildClient) {
    if ($event !== client.clientUserSettings.userSessionTimeoutMinutes) {
      const reqBody = {
        clientUserSettings: { ...client.clientUserSettings, ...{ userSessionTimeoutMinutes: $event } },
      };
      this.store.dispatch(updateChildClient({ clientData: reqBody, clientClientId: client.id }));
    }
  }

  updateCountryCode($event: MatSelectChange, client: ChildClient) {
    this.store.dispatch(
      updateChildClient({
        clientData: {
          tags: {
            countryCode: $event.value,
            phoneCode:
              COUNTRY_LIST.find(country => country.code === $event.value)?.phoneCode || DEFAULT_COUNTRY.phoneCode,
          },
        },
        clientClientId: client.id,
      })
    );
  }
}
