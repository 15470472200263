<div [class.mt-5]="devicesFilterService.isReportMode">
  <div
    *ngIf="!devicesFilterService.isReportMode"
    class="test-button-group border-b-ui-gray-light border-b flex justify-between items-center mb-5">
    <mat-button-toggle-group
      class="desktop-toggle-group"
      name="site-status"
      aria-label="Building Status"
      [value]="devicesFilterService.deviceStatusFilter"
      (change)="this.devicesFilterService.onDeviceStatusFilterChange($event)">
      <mat-button-toggle value="current" class="test-device-status-current">
        <span class="item-status-title">Current</span>
      </mat-button-toggle>
      <mat-button-toggle value="archived" class="test-device-status-archived">
        <span class="item-status-title">Archived</span>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <div class="test-search-panel flex justify-center" *ngIf="devicesFilterService.filtersEnabled">
      <app-table-search-input
        [tableId]="tableId"
        placeholder="Device Name, type & other"
        (searchDataEvent)="devicesFilterService.deviceSearch($event)" />
    </div>
  </div>

  <div
    *ngIf="devicesFilterService.filtersEnabled && devicesFilterService.normalizedDeviceList.length"
    class="test-filters-panel flex items-start justify-between">
    <div class="flex items-start flex-wrap">
      <app-multiple-select
        *ngIf="showDeviceTypeFilter"
        [value]="devicesFilterService.deviceTypeValue"
        [options]="devicesFilterService.deviceTypeOptions"
        label="Device Type"
        selectClass="stroked-select-multiple"
        panelClass="device-type-overlay"
        class="test-device-type-select mr-2"
        (selectDataEvent)="setDeviceType($event)" />

      <app-multiple-select
        *ngIf="showManufacturerFilter"
        [value]="devicesFilterService.manufacturerValue"
        [options]="devicesFilterService.manufacturerOptions"
        label="Manufacturer"
        selectClass="stroked-select-multiple"
        panelClass="assigned-user-overlay"
        class="test-manufacturer-select mr-2"
        (selectDataEvent)="setManufacturer($event)" />

      <mat-form-field
        *ngIf="devicesFilterService.showLocationFilter"
        class="test-building-select stroked-select-single mr-2">
        <mat-label>Building</mat-label>
        <mat-select
          [(ngModel)]="devicesFilterService.selectedBuilding"
          (ngModelChange)="buildingFilterChanged($event)"
          panelClass="single-select-overlay">
          @for (building of devicesFilterService.buildingOptions | sort : 'asc' : 'title' : 'all' ; track building){ @if
          (building.status !== EntityStatus.Archived){
          <mat-option [value]="building.value">
            {{ building.title }}
          </mat-option>
          } }
        </mat-select>
        <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
        <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
      </mat-form-field>

      <mat-form-field
        class="test-floor-select stroked-select-single mr-2"
        *ngIf="devicesFilterService.selectedBuilding">
        <mat-label>Floor</mat-label>
        <mat-select
          [(ngModel)]="devicesFilterService.selectedFloor"
          (ngModelChange)="floorFilterChanged($event)"
          panelClass="single-select-overlay">
          <mat-option
            *ngFor="let floor of devicesFilterService.floorOptions | sort : 'asc' : 'title' : 'all'"
            [value]="floor.value">
            {{ floor.title }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
        <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
      </mat-form-field>

      <mat-form-field class="test-room-select stroked-select-single mr-2" *ngIf="devicesFilterService.selectedFloor">
        <mat-label>Room</mat-label>
        <mat-select
          [(ngModel)]="devicesFilterService.selectedRoom"
          (ngModelChange)="roomFilterChanged($event)"
          panelClass="single-select-overlay device-room-filter-overlay">
          <mat-option
            *ngFor="let room of devicesFilterService.roomOptions | sort : 'asc' : 'title' : 'all'"
            [value]="room.value">
            {{ room.title }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
        <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
      </mat-form-field>

      <button
        class="test-clear-all-filters font-nunito text-sm text-ui-dark font-normal underline h-9 ml-3 pt-2"
        (click)="clearAllFilters()"
        *ngIf="devicesFilterService.selectedFilters">
        Clear All
      </button>
    </div>

    <mat-form-field class="column-select mb-4" *ngIf="showColumnSelect">
      <mat-label matTooltip="Column Select"><i class="icon-ic_column text-3xl"></i></mat-label>
      <mat-select
        panelClass="multi-select-overlay column-select-overlay"
        #columnSelectRef
        (selectionChange)="columnListUpdated($event)"
        [formControl]="devicesFilterService.columnSelect"
        multiple>
        <div class="options-action-wrapper">
          <button class="options-action-button" (click)="selectAllColumns()">Select All</button>
        </div>
        <mat-option *ngFor="let option of devicesFilterService.columnOptions" [value]="option.value">{{
          option.title
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
