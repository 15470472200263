import { createFeatureSelector, createSelector } from '@ngrx/store';
import { clientUserAdapter, ClientUserState } from './client-users.reducer';

const { selectAll } = clientUserAdapter.getSelectors();

export const selectClientUsersState = createFeatureSelector<ClientUserState>('clientUsers');

export const getCompanyUserList = createSelector(selectClientUsersState, selectAll);
export const getCompanyActiveUserList = createSelector(getCompanyUserList, userList =>
  userList.filter(user => !user.isArchived)
);

export const getCompanyArchivedUserList = createSelector(getCompanyUserList, userList =>
  userList.filter(user => user.isArchived)
);

export const getCompanyUserById = (clientUserId: string) => {
  return createSelector(getCompanyUserList, userList => userList.filter(user => user.id === clientUserId)[0]);
};

export const userListIsLoaded = createSelector(
  selectClientUsersState,
  clientUserState => clientUserState.allUsersLoaded
);
export const clientUserIsLoading = createSelector(selectClientUsersState, clientUserState => clientUserState.isLoading);

export const resendInvitationIsLoading = createSelector(
  selectClientUsersState,
  clientUserState => clientUserState.isResendInvitationLoading
);
