<mat-form-field class="search-input" [ngClass]="cssClass" [style]="{ width: inputWidth }">
  <i matPrefix class="icon-search text-ui-gray-light text-2xl"></i>
  <input
    #input
    [tp]="tpl"
    tpTrigger="click"
    tpVariation="popper"
    tpPlacement="bottom"
    [value]="inputValue"
    [(ngModel)]="inputValue"
    (ngModelChange)="modelChange($event)"
    type="text"
    matInput
    placeholder="{{ placeholder }}" />
  <button *ngIf="input.value" matSuffix mat-icon-button aria-label="Clear" class="test-clear-button" (click)="clear()">
    <i class="icon-close text-ui-dark text-3xl"></i>
  </button>
</mat-form-field>

<ng-template #tpl>
  <ul class="w-[280px]">
    @for (item of lastPickedItems; track item.title) {
    <li
      [routerLink]="item.url"
      class="h-10 text-black font-nunito text-sm flex items-center hover:bg-ui-green-150 px-3 cursor-pointer whitespace-nowrap">
      {{ item.title }}
    </li>
    }
  </ul>
</ng-template>
