import { Pipe, PipeTransform } from '@angular/core';
import { EntityStatus, LocationData } from '@models';

@Pipe({
  name: 'dashboardMapMarkerIcon',
})
export class DashboardMapMarkerIconPipe implements PipeTransform {
  transform(site: LocationData): Node {
    const beachFlagImg = document.createElement('img');

    if (!site.incidentCountByStatuses) {
      beachFlagImg.src = 'assets/icons/marker-default.svg';
      return beachFlagImg;
    }

    let icon = 'good';

    if (site.status === EntityStatus.Paused) {
      icon = 'paused';
    } else if (site.incidentCountByStatuses.newCount > 0) {
      icon = 'error';
    } else if (site.incidentCountByStatuses.inProgressCount > 0) {
      icon = 'warning';
    }

    beachFlagImg.src = `assets/icons/marker-${icon}.svg`;
    return beachFlagImg;
  }
}
