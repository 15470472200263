<div
  class="test-skeleton-animation text-center"
  *ngIf="(isFullLocationLoaded$ | async) === false && !locationFullData; else buildingDetails">
  <app-skeleton-page-header />
  <ngx-skeleton-loader [theme]="{ height: '130px' }" />
  <ngx-skeleton-loader [theme]="{ height: '300px' }" />
  <app-skeleton-table [columnCount]="5" />
  <app-skeleton-table [columnCount]="5" />
</div>
<ng-template #buildingDetails>
  @let isSuperAdmin = (isSuperAdmin$ | async);
  <div class="text-content" *ngIf="locationFullData && currentFloor">
    <app-dashboard-location-header
      [roomId]="currentRoomId"
      [floorId]="currentFloorId"
      [locationData]="locationFullData" />
    <app-building-info
      [currentFloorId]="currentFloorId"
      [currentRoomId]="currentRoomId"
      (spaceChanged)="spaceChanged($event)"
      [locationData]="locationFullData"
      [floorName]="currentFloor.friendlyName"
      [buildingStats]="buildingStats"
      class="block mb-4" />

    <mat-card class="card mb-4">
      <div class="flex items-center justify-between">
        <mat-card-title class="card-title">Floor Plan</mat-card-title>
        <mat-slide-toggle
          class="main-toggle"
          labelPosition="before"
          [checked]="floorplanEnabled"
          (change)="toggleFloorplan($event)">
          View floor plan
        </mat-slide-toggle>
      </div>

      <div [ngClass]="{ 'is-hidden-tab': !floorplanEnabled, 'mt-4': floorplanEnabled }">
        <ng-container *ngIf="!currentFloor.documentReference && currentFloor.id">
          @if (isSuperAdmin) {
          <app-upload-spaceplan
            *ngIf="!currentFloor.documentReference && currentLocationId"
            [locationId]="currentLocationId"
            uploadText="Upload Floor Plan"
            [floorId]="currentFloor.id" />
          } @else {
          <app-no-data noDataText="No floor plan added" />
          }
        </ng-container>
        <app-spaceplan
          *ngIf="currentFloor.documentReference && currentLocationId"
          [currentAttachmentId]="currentRoomId"
          [spaceplan]="{
            id: currentFloor.id,
            documentReference: currentFloor.documentReference,
            attachments: currentFloor.rooms || [],
            parentSpaceId: currentFloor.parentSpaceId
          }"
          [popover]="roomPopover"
          [locationId]="currentLocationId"
          [canEdit]="false" />
      </div>
    </mat-card>

    <mat-card class="card pb-2 mb-4">
      <mat-card-title class="card-title">{{ currentRoomId ? 'Room Incidents' : 'Floor Incidents' }}</mat-card-title>
      <mat-card-content class="p-0">
        <app-incidents-table-desktop-filters
          tableId="space-details-incidents-table"
          [filtersEnabled]="true"
          [resolvedIncidentsLoading]="resolvedIncidentsLoading" />
        <app-incidents-table
          tableId="space-details-incidents-table"
          [dataIsLoading]="resolvedIncidentsLoading"
          [isInjected]="true"
          [noDataText]="
            !!currentRoomId ? 'All the Room devices are working properly' : 'All the Floor devices are working properly'
          " />
      </mat-card-content>
    </mat-card>

    <mat-card class="card mt-4 pb-2">
      <app-room-list-table
        *ngIf="!currentRoomId; else deviceTable"
        tableId="space-details-room-list-table"
        [roomList]="roomList"
        [locationId]="locationFullData.id"
        [floorId]="currentFloorId" />
    </mat-card>
    <div class="card-separator"></div>
  </div>
  <ng-template #deviceTable>
    <div class="card">
      <div class="flex items-center justify-between">
        <mat-card-title class="card-title">Devices</mat-card-title>
        <a
          appRestrictedEl
          [routerLink]="['/devices', 'add-device']"
          [queryParams]="{ locationId: locationFullData.id, floorId: currentFloorId, roomId: currentRoomId }"
          class="btn-base border border-ui-gray-light px-2 py-1 text-ui-dark font-normal cursor-pointer">
          <mat-icon matPrefix>add</mat-icon>
          Add Device
        </a>
      </div>
      <app-devices-table-desktop-filters tableId="space-details-devices-table" />
      <app-devices-table tableId="space-details-devices-table" />
    </div>
  </ng-template>
</ng-template>
