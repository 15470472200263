import { Pipe, PipeTransform } from '@angular/core';
import { COUNTRY_LIST, DEFAULT_COUNTRY } from '@app-lib';
import { NgxMaskService } from 'ngx-mask';

@Pipe({
  name: 'formatPhoneNumber',
})
export class FormatPhoneNumberPipe implements PipeTransform {
  constructor(private mask: NgxMaskService) {}

  transform(phoneNumber: string, countryCode: string, withCountryCode = false): string {
    countryCode = countryCode || DEFAULT_COUNTRY.code;
    const selectedCountry = COUNTRY_LIST.find(country => country.code === countryCode);
    const formattedNumber = selectedCountry ? this.mask.applyMask(phoneNumber, selectedCountry.mask) : phoneNumber;
    if (!formattedNumber) return '';
    return withCountryCode ? `${selectedCountry?.phoneCode} ${formattedNumber}` : formattedNumber;
  }
}
