import { Component } from '@angular/core';
import { MainLogoComponent } from '@standalone/main-logo/main-logo.component';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-error-404',
  templateUrl: './error-404.component.html',
  standalone: true,
  styles: [],
  imports: [MainLogoComponent, RouterLink],
})
export class Error404Component {
  isMobile = false;

  constructor(private route: ActivatedRoute) {
    this.route.data.pipe(takeUntilDestroyed()).subscribe(data => {
      if (data['isMobile']) {
        this.isMobile = data['isMobile'];
      }
    });
  }
}
