import { Pipe, PipeTransform } from '@angular/core';
import { ChildClient, UserStatus } from '@models';

@Pipe({
  name: 'filterClientsByName',
})
export class FilterClientsByNamePipe implements PipeTransform {
  transform(items: ChildClient[], searchText: string): ChildClient[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    items = items.filter(item => item.status !== UserStatus.ARCHIVED);
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      return it.friendlyName.toLocaleLowerCase().includes(searchText);
    });
  }
}
