<app-main-logo [smallVersion]="isBlocked" [isMobile]="isMobile"></app-main-logo>
<mat-card
  *ngIf="!isBlocked; else userBlocked"
  class="styled-form mx-auto flex flex-col no-elevation"
  [class]="isMobile ? 'pt-[98px]' : 'pt-[80px]'">
  <div class="bg-white rounded-lg">
    <mat-card-content class="p-0 overflow-hidden">
      <form [formGroup]="form" (ngSubmit)="login()" class="login-form flex flex-col">
        <mat-form-field appearance="fill">
          <mat-icon class="text-ui-gray-light pl-6 text-xxl material-icons-outlined" matPrefix>account_circle</mat-icon>
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" class="pr-8" />
          <mat-error> @if (!form.get("email")?.value) { Email is required } @else { Email is incorrect } </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-icon class="text-ui-gray-light pl-6 text-xxl material-icons-outlined" matPrefix>lock</mat-icon>
          <mat-label>Password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" class="pr-8" />
          <i
            class="text-ui-dark cursor-pointer text-3xl mr-6"
            [class]="hide ? 'icon-eye-open' : 'icon-eye-close'"
            matSuffix
            (click)="hide = !hide"></i>
          <mat-error>Password is required</mat-error>
        </mat-form-field>
        <input hidden="hidden" type="submit" />
      </form>
    </mat-card-content>
  </div>

  <div class="text-right mt-2" *ngIf="!isMobile">
    <a class="text-white text-base font-semibold" routerLink="forgot-password">Forgot Password</a>
  </div>
  <div class="text-center" [class]="isMobile ? 'mt-4' : 'mt-2'">
    <button class="btn-yellow btn-large" (click)="login()" [disabled]="isLoading" [class.w-full]="isMobile">
      <mat-spinner matSuffix *ngIf="isLoading" class="mr-1" diameter="20" />
      <span>SIGN IN</span>
    </button>
  </div>
  <div class="text-center mt-4" *ngIf="isMobile">
    <a class="text-white text-base font-semibold" routerLink="forgot-password">Forgot Password</a>
  </div>
</mat-card>

<ng-template #userBlocked>
  <div
    class="text-center mx-auto test-user-blocked px-6"
    [class]="isMobile ? 'mt-[130px] max-w-[378px]' : 'mt-20 max-w-[420px]'">
    <svg
      class="inline-block mb-10"
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150ZM93.1426 53.457C93.1426 63.6506 84.8791 71.9141 74.6855 71.9141C64.492 71.9141 56.2285 63.6506 56.2285 53.457C56.2285 43.2635 64.492 35 74.6855 35C84.8791 35 93.1426 43.2635 93.1426 53.457ZM94.1858 92.1697C91.51 94.8352 90.0047 98.4491 90 102.219C90.0047 105.988 91.51 109.602 94.1858 112.268C96.8616 114.933 100.489 116.433 104.274 116.438C123.159 115.781 123.159 88.6672 104.274 88C100.489 88.0046 96.8616 89.5042 94.1858 92.1697ZM111.49 99.6725C111.792 100.488 111.951 101.349 111.959 102.219C111.954 103.44 111.656 104.643 111.089 105.726C110.522 106.809 109.703 107.742 108.7 108.445C107.698 109.149 106.541 109.603 105.326 109.771C104.111 109.938 102.874 109.813 101.718 109.407L111.49 99.6725ZM97.0578 104.765C96.7556 103.949 96.5966 103.088 96.5878 102.219C96.593 100.997 96.8915 99.7945 97.4585 98.7113C98.0255 97.6281 98.8444 96.6958 99.8469 95.9922C100.849 95.2886 102.006 94.8342 103.221 94.6669C104.436 94.4996 105.673 94.6243 106.83 95.0306L97.0578 104.765ZM47 105.752C47.017 90.4687 59.4023 78.0834 74.6855 78.0664C82.994 78.0756 90.446 81.7401 95.52 87.5383C93.7427 88.2742 92.1091 89.3543 90.7218 90.7363C87.943 93.5043 86.3798 97.2572 86.375 101.172C86.3784 103.894 87.1355 106.539 88.5312 108.828H50.0761C48.3772 108.828 47 107.451 47 105.752Z"
        fill="#ABC5C7" />
    </svg>
    <h1
      class="mb-4 text-white font-medium font-poppins flex items-center justify-center"
      [class]="isMobile ? 'text-2xl' : 'text-3xl'">
      <i class="icon-incidents text-[32px] text-ui-yellow-100 mr-3"></i>
      User is blocked
    </h1>
    <p class="text-white font-medium font-nunito" [class]="isMobile ? 'text-base leading-6' : 'text-xl leading-7'">
      You have reached your maximum<br />
      allowable login attempts. Please contact your administrator to restore access.
    </p>
  </div>
</ng-template>
