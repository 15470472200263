import { Pipe, PipeTransform } from '@angular/core';
import { RoleNames } from '@models';

@Pipe({
  name: 'fixRoleName',
})
export class FixRoleNamePipe implements PipeTransform {
  transform(roleName: 'ClientAdmin' | 'ReadOnly' | 'SuperAdmin'): RoleNames {
    return RoleNames[roleName] || 'Role is Undefined';
  }
}
