import { Component, Input, OnInit } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgClass, NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'app-skeleton-table',
  standalone: true,
  templateUrl: './skeleton-table.component.html',
  styles: [],
  imports: [NgxSkeletonLoaderModule, NgClass, NgForOf, NgIf],
})
export class SkeletonTableComponent implements OnInit {
  @Input() columnCount = 10;
  @Input() showFilters = true;
  @Input() showTabs = true;
  tableRowsCount!: number[];

  ngOnInit() {
    this.tableRowsCount = Array(this.columnCount).fill(0);
  }
}
