export const deviceChartData = {
  year: {
    chart: {
      type: 'spline',
    },
    title: {
      text: '1: Data point',
      align: 'left',
    },
    xAxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      accessibility: {
        description: 'Months of the year',
      },
    },
    yAxis: {
      title: {
        text: 'Number of errors',
      },
      labels: {
        format: '{value}',
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    series: [
      {
        name: 'Errors',
        marker: {
          symbol: 'diamond',
          lineColor: '#38C0A1',
        },
        data: [
          {
            y: 1.5,
          },
          1.6,
          3.3,
          5.9,
          10.5,
          13.5,
          14.5,
          14.4,
          11.5,
          8.7,
          4.7,
          2.6,
        ],
      },
    ],
  },
  month: {
    chart: {
      type: 'spline',
    },
    title: {
      text: '1: Data point',
      align: 'left',
    },
    xAxis: {
      categories: Array.from(Array(30).keys()).map(val => val + 1),
      accessibility: {
        description: 'Months of the year',
      },
    },
    yAxis: {
      title: {
        text: 'Number of errors',
      },
      labels: {
        format: '{value}',
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    series: [
      {
        name: 'Errors',
        marker: {
          symbol: 'diamond',
          lineColor: '#38C0A1',
        },
        data: [
          {
            y: 1,
          },
          1,
          3,
          5,
          10,
          13,
          15,
          0,
          0,
          0,
          0,
          0,
          6,
          6,
          8,
          8,
          9,
          76,
          4,
          4,
          3,
          2,
          2,
          6,
          7,
          1,
          1,
          1,
          1,
          1,
        ],
      },
    ],
  },
  week: {
    chart: {
      type: 'spline',
    },
    title: {
      text: '1: Data point',
      align: 'left',
    },
    xAxis: {
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      accessibility: {
        description: 'Days of the week',
      },
    },
    yAxis: {
      title: {
        text: 'Number of errors',
      },
      labels: {
        format: '{value}',
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    series: [
      {
        name: 'Errors',
        marker: {
          symbol: 'diamond',
          lineColor: '#38C0A1',
        },
        data: [
          {
            y: 1.5,
          },
          0,
          1,
          3,
          1,
          2,
          0,
        ],
      },
    ],
  },
};
