import { ClientUser, UserRolesIds, UserRolesTypes, UserStatus } from '@models';
import { DEFAULT_COUNTRY } from '@app-lib';

export const clientUser: ClientUser[] = [
  {
    id: 'id',
    firstName: 'Ernest',
    lastName: 'Hemingway',
    company: 'avservice',
    isArchived: false,
    isDisabled: false,
    phone: {
      countryCode: DEFAULT_COUNTRY.code,
      number: '2132132132',
      countryNumberCode: DEFAULT_COUNTRY.phoneCode,
      smsOptIn: false,
    },
    status: UserStatus.ACTIVE,
    email: 'test@test.com',
    role: {
      id: UserRolesIds.ClientAdmin,
      name: UserRolesTypes.ClientAdmin,
    },
    notificationsPreferences: {
      smsEnabled: false,
      emailEnabled: false,
    },
    userPasswordPolicyId: '8009b138-6a61-4e12-85cc-7842b110acda',
  },
  {
    id: 'id',
    firstName: 'Stephen',
    status: UserStatus.ACTIVE,
    lastName: 'King',
    isArchived: false,
    phone: {
      countryCode: DEFAULT_COUNTRY.code,
      number: '2132132132',
      countryNumberCode: DEFAULT_COUNTRY.phoneCode,
      smsOptIn: false,
    },
    isDisabled: false,
    company: 'avservice',
    email: 'test@test.com',
    notificationsPreferences: {
      smsEnabled: false,
      emailEnabled: false,
    },
    role: {
      id: UserRolesIds.ClientAdmin,
      name: UserRolesTypes.ClientAdmin,
    },
    userPasswordPolicyId: '8009b138-6a61-4e12-85cc-7842b110acda',
  },
];
