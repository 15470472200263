import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@models';

@Pipe({
  name: 'filterSelectOptions',
})
export class FilterSelectOptions implements PipeTransform {
  transform(items: SelectOption[], searchText: string): SelectOption[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(option => {
      return option.title.toLocaleLowerCase().includes(searchText);
    });
  }
}
