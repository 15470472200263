import { Component, Input } from '@angular/core';
import { UserStatus } from '@models';
import { NgClass, NgStyle } from '@angular/common';

@Component({
  selector: 'app-user-status',
  template: ` <span
    class="user-status"
    [ngStyle]="{ 'font-size': fontSize }"
    [ngClass]="{
    'status-active': status === userStatus.ACTIVE,
    'status-inactive': status === userStatus.INACTIVE,
    'status-invited': status === userStatus.INVITED,
    'status-blocked': status === userStatus.BLOCKED,
    'status-archived': status === userStatus.ARCHIVED,
  }"
    >{{ status }}</span
  >`,
  styleUrls: ['./user-status.component.scss'],
  imports: [NgStyle, NgClass],
  standalone: true,
})
export class UserStatusComponent {
  @Input() status: UserStatus = UserStatus.ACTIVE;
  @Input() fontSize = '0.75rem';
  userStatus = UserStatus;
}
