import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-user-activity-modal',
  templateUrl: './user-activity-modal.component.html',
  styles: [],
})
export class UserActivityModalComponent implements OnDestroy {
  interval: ReturnType<typeof setInterval> | undefined;
  counter = 0;
  idleTimeMinutes = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { timer: number; idleTime: number }) {
    if (this.data.timer > 0) {
      this.counter = this.data.timer - 2;
      this.idleTimeMinutes = data.idleTime - 1;
      this.interval = setInterval(() => {
        this.counter = this.counter - 1;
        if (!this.counter) {
          clearInterval(this.interval);
        }
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}

export function openUserActivityDialog(dialog: MatDialog, timer: number, idleTime: number) {
  const config = new MatDialogConfig();
  config.disableClose = true;
  config.data = {
    timer,
    idleTime,
  };
  config.autoFocus = false;
  config.panelClass = 'app-dialog';
  config.backdropClass = 'backdrop-modal-panel';
  return dialog.open(UserActivityModalComponent, config);
}
