import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

type ExportType = 'csv' | 'json';

@Injectable({
  providedIn: 'root',
})
export class ExportDevicesService {
  private exportSubject: Subject<ExportType> = new Subject<ExportType>();
  export$: Observable<ExportType> = this.exportSubject.asObservable();

  emitExportType(data: ExportType): void {
    this.exportSubject.next(data);
  }
}
