import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppService, NotificationsService, UserManagementService } from '@services';
import {
  clientUserCreated,
  clientUserError,
  clientUsersLoaded,
  resendUserInvitation,
  resendUserInvitationError,
  resendUserInvitationSuccess,
  updateUserClient,
  updateUserClientSuccess,
} from './client-users.actions';
import { map, tap } from 'rxjs';
import { ClientUsersActions } from '@ngrx-store';
import { Router } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { CustomHTTPResponseError } from '@models';

@Injectable()
export class ClientUsersEffects {
  loadUserList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientUsersActions.loadClientUsers),
      switchMap(action =>
        this.userManagementService
          .getClientUsersList(action.clientId)
          .pipe(map(clientUsers => clientUsersLoaded({ clientUsersList: clientUsers })))
      )
    )
  );

  addNewClientUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientUsersActions.addClientUser),
      switchMap(action =>
        this.userManagementService.addClientUser(action.clientId, action.newUserData).pipe(
          map(newUser => clientUserCreated({ createdUser: newUser })),
          catchError(async (error: CustomHTTPResponseError) => {
            return clientUserError({ error });
          })
        )
      )
    )
  );

  newClientCreated = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientUsersActions.clientUserCreated),
        tap(() => {
          this.router.navigateByUrl('/user-management');
          this.notificationService.showSuccessMessage(`User was successfully created`);
        })
      ),
    { dispatch: false }
  );

  userClientErrorHandler = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clientUserError),
        map(action => {
          this.notificationService.showErrorMessage(action.error.message);
        })
      ),
    { dispatch: false }
  );

  updateUserClient = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateUserClient),
      switchMap(action =>
        this.userManagementService
          .updateUserData(this.appService.currentClient, action.clientUserId, action.clientUser)
          .pipe(map(updatedUser => updateUserClientSuccess({ clientUser: updatedUser })))
      )
    );
  });

  updateUserClientSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserClientSuccess),
        tap(user =>
          this.notificationService.showSuccessMessage(
            `${user.clientUser.firstName} ${user.clientUser.lastName} is successfully updated`
          )
        )
      ),
    { dispatch: false }
  );

  resendUserInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendUserInvitation),
      switchMap(action =>
        this.userManagementService.resendInvitation(this.appService.currentClient, action.clientUser.id).pipe(
          map(() => {
            this.notificationService.showSuccessMessage(
              `${action.clientUser.firstName} ${action.clientUser.lastName} has been resent an invitation to the mail`
            );
            return resendUserInvitationSuccess();
          }),
          catchError(async () => {
            this.notificationService.showErrorMessage('Resend Invitation failed');
            return resendUserInvitationError();
          })
        )
      )
    )
  );

  constructor(
    private userManagementService: UserManagementService,
    private actions$: Actions,
    private router: Router,
    private appService: AppService,
    private notificationService: NotificationsService
  ) {}
}
