import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ClientUser, UserModel } from '@models';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(private http: HttpClient) {}

  getClientUsersList(clientId: string): Observable<ClientUser[]> {
    return this.http
      .get<{ data: ClientUser[] }>(`userManagement/client/${clientId}/users`)
      .pipe(map(data => data.data));
  }

  addClientUser(clientId: string, newUser: ClientUser): Observable<ClientUser> {
    return this.http
      .post<{ data: ClientUser }>(`userManagement/client/${clientId}/user`, newUser)
      .pipe(map(({ data }) => data));
  }

  updatePersonalUserData<T = UserModel>(data: Partial<T>) {
    return this.http.patch<{ data: T }>(`user/changePersonalInfo`, data).pipe(map(data => data.data));
  }

  updateUserData<T = ClientUser>(clientId: string, userId: string, data: Partial<T>) {
    return this.http
      .patch<{ data: T }>(`userManagement/client/${clientId}/user/${userId}`, data)
      .pipe(map(data => data.data));
  }

  resetPassword(clientId: string, userId: string) {
    return this.http.get(`userManagement/client/${clientId}/user/${userId}/resetPassword`);
  }

  resendInvitation(clientId: string, userId: string) {
    return this.http.get(`userManagement/client/${clientId}/user/${userId}/resendInvite`);
  }
}
