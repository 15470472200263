import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, getDeviceById } from '@ngrx-store';
import { DeviceData } from '@models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html',
})
export class EditDeviceComponent {
  device$: Observable<DeviceData | undefined> | undefined;

  constructor(private route: ActivatedRoute, private store: Store<AppState>) {
    const deviceId = this.route.snapshot.paramMap.get('deviceId');
    if (deviceId) {
      this.device$ = this.store.select(getDeviceById(deviceId));
    }
  }
}
