import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private ngxtoastr: ToastrService) {}

  showSuccessMessage(message: string) {
    this.ngxtoastr.success(
      `
        <div class="flex flex-row">
          <i class="icon-check text-2xl pr-2"></i>
          ${message}
        </div>
      `
    );
  }

  showErrorMessage(message: string) {
    this.ngxtoastr.error(
      `
        <div class="flex flex-row">
          <i class="icon-incidents-stroke text-2xl pr-2"></i>
          ${message}
        </div>
      `
    );
  }
}
