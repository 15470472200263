<div class="flex items-center justify-between mb-4">
  <h4 class="text-ui-dark text-sm font-semibold font-poppins mb-0">Reporting by Firmware</h4>
  <app-export-report-menu />
</div>
<div class="grid grid-cols-3 gap-4 mb-4">
  <div>
    <div class="flex flex-col justify-between h-full m-0">
      <mat-card class="card p-3 m-0 h-[calc(50%-8px)] flex items-center justify-center">
        <p>Firmware outdated:</p>
        <span class="report-firmware-amount">20</span>
      </mat-card>
      <mat-card class="card p-3 m-0 h-[calc(50%-8px)] flex items-center flex-row justify-center divide-x">
        <div class="report-firmware-detail">
          <p>Total Devices:</p>
          <span class="report-firmware-amount">21</span>
        </div>
        <div class="report-firmware-detail">
          <p>Connected:</p>
          <span class="report-firmware-amount">20</span>
        </div>
        <div class="report-firmware-detail">
          <p>Never Connected:</p>
          <span class="report-firmware-amount">1</span>
        </div>
      </mat-card>
    </div>
  </div>
  <mat-card class="card p-3 mb-0">
    <mat-card-title class="card-title mb-3">Devices by Statuses</mat-card-title>
    <highcharts-chart [Highcharts]="Highcharts" [options]="deviceStatusChartData"></highcharts-chart>
  </mat-card>
  <mat-card class="card p-3 mb-0">
    <mat-card-title class="card-title mb-3">Firmware outdated by device type</mat-card-title>
    <highcharts-chart [Highcharts]="Highcharts" [options]="firmwareOutdatedChartData"></highcharts-chart>
  </mat-card>
</div>
