import * as Highcharts from 'highcharts';

export const incidentStatusChartData: Highcharts.Options = {
  chart: {
    plotShadow: false,
    type: 'pie',
  },
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '{point.name}<br/> {point.y}',
        style: {
          color: 'black',
        },
      },
    },
  },
  series: [
    {
      name: 'Incidents',
      type: 'pie',
      data: [
        {
          name: 'RESOLVED',
          y: 37,
          color: '#14B8A6',
        },
        {
          name: 'IN QUEUE',
          y: 6,
          color: '#ffdc24',
        },
        {
          name: 'IN PROGRESS',
          y: 2,
          color: '#FE5A5A',
        },
      ],
    },
  ],
};

export const incidentFaultChartData: Highcharts.Options = {
  chart: {
    plotShadow: false,
    type: 'pie',
  },
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '{point.name}<br/> {point.y}',
        style: {
          color: 'black',
        },
      },
    },
  },
  series: [
    {
      name: 'Incidents',
      type: 'pie',
      data: [
        {
          name: 'Heartbeat Absent',
          y: 505,
        },
        {
          name: 'Device Offline',
          y: 150,
        },
        {
          name: 'No Video',
          y: 82,
        },
        {
          name: 'No Signal',
          y: 171,
        },
        {
          name: 'No Connection',
          y: 104,
        },
      ],
    },
  ],
};

export const incidentMonthChartData: Highcharts.Options = {
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    crosshair: false,
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Incidents',
    },
  },
  tooltip: {
    enabled: false,
  },
  series: [
    {
      type: 'column',
      data: [32, 45, 40, 67, 88, 54, 60, 23, 17, 73, 49, 44],
    },
  ],
};
