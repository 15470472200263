import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { siteStatusOptions } from '@mocks';
import { EntityStatus, IncidentStatus, LocationData } from '@models';
import { AppState, isSuperAdmin, selectAllLocations } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

type BuildingStatusFilter = 'current' | 'archived';

@Injectable({
  providedIn: 'root',
})
export class BuildingDataFilterService {
  private _showTabs = true;
  private _buildingStatusFilter = new BehaviorSubject<BuildingStatusFilter>('current');
  buildingStatusFilter$: Observable<BuildingStatusFilter> = this._buildingStatusFilter.asObservable();

  private _locations: LocationData[] = [];
  private _filteredLocations: BehaviorSubject<LocationData[]> = new BehaviorSubject<LocationData[]>([]);
  filteredLocations$: Observable<LocationData[]> = this._filteredLocations.asObservable();

  private _search = '';

  siteStatusOptions = siteStatusOptions;
  _statusFilter = '';

  incidentOptions = [
    { value: IncidentStatus.IN_QUEUE, title: 'In queue', checked: false },
    { value: IncidentStatus.IN_PROGRESS, title: 'In progress', checked: false },
  ];

  constructor(private store: Store<AppState>) {
    this.store
      .select(isSuperAdmin)
      .pipe(takeUntilDestroyed())
      .subscribe(isSuperAdmin => (this._showTabs = isSuperAdmin));

    this.store
      .select(selectAllLocations)
      .pipe(takeUntilDestroyed())
      .subscribe(locations => {
        this.initLocations(locations);
      });
  }

  initLocations(locations: LocationData[]) {
    this._locations = locations;
    this.filterLocations();
  }

  get showTabs() {
    return this._showTabs;
  }

  get buildingStatusFilter() {
    return this._buildingStatusFilter.value;
  }

  set buildingStatusFilter(value: BuildingStatusFilter) {
    this._buildingStatusFilter.next(value);
    this.filterLocations();
  }

  get search() {
    return this._search;
  }

  set search(value: string) {
    this._search = value;
    this.filterLocations();
  }

  get statusFilter() {
    return this._statusFilter;
  }

  set statusFilter(value: string) {
    this._statusFilter = value;
    this.filterLocations();
  }

  get incidentFilter() {
    return this.incidentOptions.filter(({ checked }) => checked).map(({ value }) => value);
  }

  set incidentFilter(filter: string[]) {
    this.incidentOptions = this.incidentOptions.map(option => {
      option.checked = filter.includes(option.value);
      return option;
    });
    this.filterLocations();
  }

  updateIncidentFilter(filter: { value: string; checked: boolean }) {
    this.incidentOptions = this.incidentOptions.map(option => {
      if (option.value === filter.value) {
        option.checked = filter.checked;
      }
      return option;
    });
    this.filterLocations();
  }

  private filterLocations() {
    const filteredLocations = this._locations.filter(location => {
      const statusMatch =
        this.buildingStatusFilter === 'archived'
          ? location.status === EntityStatus.Archived
          : this.statusFilter
          ? location.status === this.statusFilter
          : location.status !== EntityStatus.Archived;

      const friendlyNameMatch = !this.search || location.friendlyName.toLowerCase().includes(this.search.toLowerCase());

      const incidentMatch =
        !this.incidentFilter.length ||
        this.incidentFilter?.some(incidentType => {
          if (!location.incidentCountByStatuses) {
            return true;
          }

          if (incidentType === IncidentStatus.IN_QUEUE) {
            return location.incidentCountByStatuses.newCount > 0;
          }
          if (incidentType === IncidentStatus.IN_PROGRESS) {
            return location.incidentCountByStatuses.inProgressCount > 0;
          }

          return false;
        });

      return statusMatch && friendlyNameMatch && incidentMatch;
    });

    this._filteredLocations.next(filteredLocations);
  }

  clearFilters() {
    this.buildingStatusFilter = 'current';
    this.search = '';
    this.statusFilter = '';
    this.incidentFilter = [];
  }

  resetState() {
    this.clearFilters();
    this.initLocations([]);
  }
}
