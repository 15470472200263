<div class="flex items-center justify-between py-4">
  <mat-card-title class="card-title">Add Rooms</mat-card-title>

  <app-search-input
    placeholder="Search Floor"
    [inputValue]="floorSearch"
    (searchDataEvent)="floorSearchChanged($event)" />
</div>

<div *ngIf="locationFullData">
  <p
    class="bg-ui-gray-50 border border-ui-gray-light font-nunito text-sm text-ui-gray-100 font-semibold h-8 flex flex-re px-3 mb-0 items-center">
    Floor
  </p>
  <div class="flex flex-col-reverse">
    <app-expandable-floor-item
      *ngFor="let floor of floors | filterSpaceByFriendlyName : floorSearch"
      [floor]="floor"
      [locationId]="locationFullData.id"
      [openedFloorId]="openedFloorId"
      (clickEvent)="floorItemClick($event)" />
  </div>
</div>
