<div class="test-header flex flex-row items-center pb-4" *ngIf="locationData">
  <div class="flex-grow">
    <h1 class="text-ui-dark text-2xl font-semibold mb-0 font-poppins">
      <span *ngIf="locationData && !currentFloor && !currentRoom">Building: </span>
      <span *ngIf="locationData && currentFloor && !currentRoom">Floor: </span>
      <span *ngIf="locationData && currentFloor && currentRoom">Room: </span>
      {{ title }}
    </h1>
    <app-breadcrumbs [routeNames]="routeNames"></app-breadcrumbs>
  </div>
  <div class="flex">
    <!-- Location details buttons -->
    <ng-container *ngIf="locationData && !currentFloor && !currentRoom">
      <div class="test-location-action-block">
        <ng-container *ngIf="locationData.status !== siteStatus.Archived">
          <button
            appRestrictedEl
            [showForClientAdmin]="true"
            class="btn-green btn-medium mr-3"
            *ngIf="locationData.status === siteStatus.Paused"
            (click)="resumeSite()">
            <i class="icon-play-16 text-3xl mr-2"></i>
            Resume
          </button>
          <button
            appRestrictedEl
            [showForClientAdmin]="true"
            class="btn-gray btn-medium mr-3"
            *ngIf="locationData.status === siteStatus.Active"
            (click)="pauseSite()">
            <i class="icon-pause-16 text-3xl mr-2"></i>
            Pause
          </button>
          <button appRestrictedEl class="btn-black btn-medium mr-3" (click)="archiveSite()">
            <i class="icon-add-to-archive text-3xl mr-2"></i>
            Archive
          </button>
        </ng-container>
        <button
          appRestrictedEl
          class="btn-green btn-medium mr-3"
          *ngIf="locationData.status === siteStatus.Archived"
          (click)="unarchiveSite()">
          <i class="icon-restore text-3xl mr-2"></i>
          Restore
        </button>
        <a
          appRestrictedEl
          class="btn-blue btn-medium"
          [routerLink]="['/dashboard', locationData.id, 'edit']"
          *ngIf="locationData.status !== siteStatus.Archived && !floorId">
          <i class="icon-edit text-3xl mr-2"></i>
          Edit
        </a>
      </div>
    </ng-container>

    <!-- Floor details buttons -->
    <ng-container *ngIf="locationData && currentFloor && !currentRoom">
      <div class="test-floor-action-block">
        <ng-container *ngIf="currentFloor.status !== entityStatus.Archived">
          <button
            appRestrictedEl
            [showForClientAdmin]="true"
            class="btn-green btn-medium mr-3"
            *ngIf="currentFloor.status === entityStatus.Paused"
            (click)="resumeFloor()">
            <i class="icon-play-16 text-3xl mr-2"></i>
            Resume
          </button>
          <button
            appRestrictedEl
            [showForClientAdmin]="true"
            class="btn-gray btn-medium mr-3"
            *ngIf="currentFloor.status === entityStatus.Active"
            (click)="pauseFloor()">
            <i class="icon-pause-16 text-3xl mr-2"></i>
            Pause
          </button>
          <button appRestrictedEl class="btn-black btn-medium mr-3" (click)="archiveFloor()">
            <i class="icon-add-to-archive text-3xl mr-2"></i>
            Archive
          </button>
        </ng-container>
        <button
          appRestrictedEl
          class="btn-green btn-medium mr-3"
          *ngIf="currentFloor.status === entityStatus.Archived"
          (click)="unarchiveFloor()">
          <i class="icon-restore text-3xl mr-2"></i>
          Restore
        </button>
        <a
          appRestrictedEl
          class="btn-blue btn-medium"
          [routerLink]="['/dashboard', locationData.id, currentFloor.id, 'edit']"
          *ngIf="currentFloor.status !== entityStatus.Archived">
          <i class="icon-edit text-3xl mr-2"></i>
          Edit
        </a>
      </div>
    </ng-container>

    <!-- Room details buttons -->
    <ng-container *ngIf="locationData && currentFloor && currentRoom">
      <div class="test-room-action-block">
        <ng-container *ngIf="currentRoom.status !== entityStatus.Archived">
          <a
            *ngIf="currentRoom.externalReference"
            [href]="currentRoom.externalReference"
            target="_blank"
            class="inline-flex items-center mr-3">
            <i class="icon-link text-3xl text-ui-blue-200"></i>
            <span class="ml-2 underline text-base font-semibold font-nunito text-ui-blue-200">Doc link</span>
          </a>
          <button
            appRestrictedEl
            [showForClientAdmin]="true"
            class="btn-green btn-medium mr-3"
            *ngIf="currentRoom.status === entityStatus.Paused"
            (click)="resumeRoom()">
            <i class="icon-play-16 text-3xl mr-2"></i>
            Resume
          </button>
          <button
            appRestrictedEl
            [showForClientAdmin]="true"
            class="btn-gray btn-medium mr-3"
            *ngIf="currentRoom.status === entityStatus.Active"
            (click)="pauseRoom()">
            <i class="icon-pause-16 text-3xl mr-2"></i>
            Pause
          </button>
          <button appRestrictedEl class="btn-black btn-medium mr-3" (click)="archiveRoom()">
            <i class="icon-add-to-archive text-3xl mr-2"></i>
            Archive
          </button>
        </ng-container>
        <button
          appRestrictedEl
          class="btn-green btn-medium mr-3"
          *ngIf="currentRoom.status === entityStatus.Archived"
          (click)="unarchiveRoom()">
          <i class="icon-restore text-3xl mr-2"></i>
          Restore
        </button>
        <a
          appRestrictedEl
          [showForClientAdmin]="true"
          class="btn-blue btn-medium"
          [routerLink]="['/dashboard', locationData.id, currentFloor.id, currentRoom.id, 'edit']"
          *ngIf="currentRoom.status !== entityStatus.Archived">
          <i class="icon-edit text-3xl mr-2"></i>
          Edit
        </a>
      </div>
    </ng-container>
  </div>
</div>
