import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, loadChildClients, selectChildClients } from '@ngrx-store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const clientsManagementsResolver: ResolveFn<void> = (_r, _s) => {
  inject(Store<AppState>)
    .select(selectChildClients)
    .pipe(takeUntilDestroyed())
    .subscribe(clientList => {
      if (clientList.length < 2) {
        return inject(Store<AppState>).dispatch(loadChildClients());
      }
    });
};
