import { PhoneNumberClass } from './phone-number.class';
import { ClientUser, UserRolesTypes, UserStatus } from '@models';
import { Observable } from 'rxjs';
import {
  AppState,
  ClientUsersActions,
  resendInvitationIsLoading,
  resendUserInvitation,
  selectAuthState,
  updateUserClient,
  UserState,
} from '@ngrx-store';
import { DestroyRef, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { COUNTRY_LIST, DEFAULT_COUNTRY } from '@app-lib';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';

export abstract class UserDetailsClass extends PhoneNumberClass {
  protected readonly UserRolesTypes = UserRolesTypes;
  protected readonly UserStatus = UserStatus;
  protected readonly COUNTRY_LIST = COUNTRY_LIST;
  protected readonly DEFAULT_COUNTRY = DEFAULT_COUNTRY;

  user!: ClientUser;
  editor$: Observable<UserState> | undefined;

  resendInvitationLoading$: Observable<boolean>;
  destroyRef = inject(DestroyRef);

  protected constructor(public route: ActivatedRoute, public store: Store<AppState>, public dialog: MatDialog) {
    super();
    this.store
      .select(ClientUsersActions.getCompanyUserById(this.route.snapshot.params['id']))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(user => {
        if (user) {
          this.user = user;
          const selectedCountry = COUNTRY_LIST.find(country => country.code === user.phone.countryCode);
          if (selectedCountry) {
            this.phoneMask.set(selectedCountry.mask);
            this.phoneMaskExpression.set(selectedCountry.maskExpression);
          }
        }
      });
    this.editor$ = this.store.select(selectAuthState);
    this.resendInvitationLoading$ = this.store.select(resendInvitationIsLoading);
  }

  openConfirmationDialog({
    userId,
    title,
    description,
    data,
  }: {
    userId: string;
    title: string;
    description: string;
    data: Partial<ClientUser>;
  }) {
    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(
            updateUserClient({
              clientUserId: userId,
              clientUser: data,
            })
          );
        }
      });
  }

  archive(user: ClientUser) {
    this.openConfirmationDialog({
      userId: user.id,
      title: 'Archive',
      description: `Do you really want to archive ${user.firstName} ${user.lastName} ?`,
      data: { isArchived: true },
    });
  }

  restore(user: ClientUser) {
    this.openConfirmationDialog({
      userId: user.id,
      title: 'Restore',
      description: `Do you really want to restore ${user.firstName} ${user.lastName} ?`,
      data: { isArchived: false },
    });
  }

  resendInvitation(user: ClientUser) {
    this.store.dispatch(resendUserInvitation({ clientUser: user }));
  }
}
