import { LocationLogsData } from '@models';

export const buildingLogs: LocationLogsData[] = [
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'OYWhS',
    location: 'floor 36, Room-73',
    updated: '1691657825161',
    user: 'Elton Washington',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'JjbXf',
    location: 'floor 68, Room-34',
    updated: '1691657825161',
    user: 'Anthony Ferguson',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'yYKVp',
    location: 'floor 03, Room-73',
    updated: '1691657825161',
    user: 'John Jobs',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'GvtrD',
    location: 'floor 11, Room-12',
    updated: '1691657825161',
    user: 'Suzan Ferguson',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'B59BS',
    location: 'floor 91, Room-17',
    updated: '1691657825161',
    user: 'Anna Tyson',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: '4HjXl',
    location: 'floor 30, Room-21',
    updated: '1691657825161',
    user: 'Elton Davidson',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'GUlUG',
    location: 'floor 81, Room-15',
    updated: '1691657825161',
    user: 'Stephen Liston',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'uWslU',
    location: 'floor 68, Room-97',
    updated: '1691657825161',
    user: 'Samantha Liston',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'RfvUz',
    location: 'floor 26, Room-81',
    updated: '1691657825161',
    user: 'Linda Washington',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'vbc1L',
    location: 'floor 93, Room-98',
    updated: '1691657825161',
    user: 'Britney Parker',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'TmxYP',
    location: 'floor 55, Room-18',
    updated: '1691657825161',
    user: 'Stephen Tyson',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'SoLVt',
    location: 'floor 14, Room-25',
    updated: '1691657825161',
    user: 'Suzan Bell',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'WUZDb',
    location: 'floor 73, Room-46',
    updated: '1691657825161',
    user: 'Martin Tyson',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'lpAS1',
    location: 'floor 99, Room-43',
    updated: '1691657825161',
    user: 'Britney Liston',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'BEvGZ',
    location: 'floor 60, Room-18',
    updated: '1691657825161',
    user: 'Nina Washington',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: '9yb7J',
    location: 'floor 08, Room-66',
    updated: '1691657825161',
    user: 'Khloe Washington',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'RdPJA',
    location: 'floor 05, Room-54',
    updated: '1691657825161',
    user: 'Philip Jobs',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: '18pBl',
    location: 'floor 31, Room-69',
    updated: '1691657825161',
    user: 'John Bell',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'ByYTM',
    location: 'floor 83, Room-86',
    updated: '1691657825161',
    user: 'Philip Jobs',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'Sydz1',
    location: 'floor 28, Room-00',
    updated: '1691657825161',
    user: 'Britney Davidson',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'm8TKh',
    location: 'floor 78, Room-13',
    updated: '1691657825161',
    user: 'Samantha Jobs',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'TT8dr',
    location: 'floor 19, Room-06',
    updated: '1691657825161',
    user: 'Ralph Tyson',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'A8arc',
    location: 'floor 69, Room-62',
    updated: '1691657825161',
    user: 'Martin Washington',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'PK29T',
    location: 'floor 60, Room-47',
    updated: '1691657825161',
    user: 'Nina Ferguson',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 't7UHK',
    location: 'floor 13, Room-94',
    updated: '1691657825161',
    user: 'Ralph Smith',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'Jvvgm',
    location: 'floor 36, Room-80',
    updated: '1691657825161',
    user: 'Nina Tyson',
  },
  {
    action: 'Resume: OFFLINE -> ONLINE',
    id: 'RIcuw',
    location: 'floor 73, Room-61',
    updated: '1691657825161',
    user: 'Khloe Mask',
  },
];
