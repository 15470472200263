import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { ClientManagementMainPageComponent } from './containers/client-management-main-page/client-management-main-page.component';
import { ClientDetailsComponent } from './containers/client-details/client-details.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@directives';
import { MatInputModule } from '@angular/material/input';
import { AddClientFormComponent } from '@app/modules/client-management/containers/add-client-form/add-client-form/add-client-form.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaskDirective } from 'ngx-mask';
import { MatSliderModule } from '@angular/material/slider';
import { clientsManagementsResolver, clientUsersResolver, locationsResolver } from '@ngrx-store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { PipesModule } from '@pipes';
import { SkeletonPageHeaderComponent } from '@standalone/skeleton-page-header/skeleton-page-header.component';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { MainInputFormComponent } from '@standalone/main-input-form/main-input-form.component';
import { BreadcrumbsComponent } from '@standalone/breadcrumbs/breadcrumbs.component';
import { SliderComponent } from '@standalone/slider/slider.component';
import { UserStatusComponent } from '@standalone/user-status/user-status.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { UsersTableComponent } from '@standalone/_tables/users-table/users-table.component';
import { SiteTableDesktopFiltersComponent } from '@standalone/_tables/site-table-desktop-filters/site-table-desktop-filters.component';
import { SiteTableComponent } from '@standalone/_tables/site-table/site-table.component';
import { ClientsTableComponent } from '@standalone/_tables/clients-table/clients-table.component';
import { RoleGuard } from '@app-lib';

@NgModule({
  declarations: [ClientManagementMainPageComponent, ClientDetailsComponent, AddClientFormComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: ClientManagementMainPageComponent,
        canActivate: [RoleGuard],
        resolve: {
          childClientList: clientsManagementsResolver,
        },
      },
      {
        path: 'add-client',
        canActivate: [RoleGuard],
        component: AddClientFormComponent,
      },
      {
        path: 'client-details/:clientId',
        component: ClientDetailsComponent,
        canActivate: [RoleGuard],
        resolve: {
          childClientList: clientsManagementsResolver,
          locations: locationsResolver,
          users: clientUsersResolver,
        },
      },
    ]),
    MatCardModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    FormsModule,
    DirectivesModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    MatSliderModule,
    NgxSkeletonLoaderModule,
    MatOption,
    MatSelect,
    PipesModule,
    SkeletonPageHeaderComponent,
    SkeletonTableComponent,
    MainInputFormComponent,
    BreadcrumbsComponent,
    SliderComponent,
    UserStatusComponent,
    PageHeaderComponent,
    UsersTableComponent,
    SiteTableDesktopFiltersComponent,
    SiteTableComponent,
    ClientsTableComponent,
  ],
})
export class ClientManagementModule {}
