<div *ngIf="locationId && floor && room; else loading">
  <a
    [routerLink]="['/dashboard', locationId, floor.id, room.id]"
    class="inline-flex items-center font-nunito text-ui-dark text-sm font-semibold">
    <mat-icon>chevron_left</mat-icon>
    Back to {{ room.friendlyName }}
  </a>
  <app-page-header>Edit Room</app-page-header>
  <mat-card class="p-6 mt-3 shadow-card">
    <mat-card-content class="p-0">
      <app-main-input-form
        label="Room name:"
        controlName="friendlyName"
        formItemType="text"
        placeholder="Room name"
        [value]="room.friendlyName"
        [disabled]="currentUserRoleId !== userRoleIds.SuperAdmin"
        (updateData)="onRoomChange($event)" />
      <app-main-input-form
        label="Doc link :"
        controlName="externalReference"
        formItemType="text"
        placeholder="Doc link"
        [isRequired]="false"
        [value]="room.externalReference"
        [disabled]="currentUserRoleId === userRoleIds.ReadOnly"
        (updateData)="onRoomChange($event)" />
      <hr class="mb-6 border-ui-gray-light" />
      <app-spaceplan-tabs
        [locationId]="locationId"
        [room]="room"
        [popover]="editDevicePopover"
        (tabSelect)="selectedDocumentTab = $event"
        [canEdit]="true" />
    </mat-card-content>
  </mat-card>

  <div class="my-4" appRestrictedEl>
    <mat-button-toggle-group [value]="deviceTab" (change)="tabChange($event)" class="main-toggle-group">
      <mat-button-toggle class="border-ui-green-light" value="list">
        <mat-icon matIconPrefix *ngIf="deviceTab === 'list'">check</mat-icon>
        Device List
      </mat-button-toggle>
      <mat-button-toggle class="border-ui-green-light" value="add">
        <mat-icon matIconPrefix *ngIf="deviceTab === 'add'">check</mat-icon>
        {{ deviceToEdit ? 'Edit' : 'Add' }} Device
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <mat-card class="mt-4 shadow-card" *ngIf="deviceTab === 'list'">
    <mat-card-content class="p-6">
      <app-device-list
        [documentTab]="selectedDocumentTab"
        [roomId]="room.id"
        [locationId]="locationId"
        [roomplan]="room.documentReference"
        (editDevice)="editDevice($event)" />
    </mat-card-content>
  </mat-card>

  <div *ngIf="deviceTab === 'add'">
    <app-new-device-form
      [device]="deviceToEdit"
      [showHeader]="false"
      [showBreadcrumbs]="false"
      [cardTitle]="deviceToEdit ? 'Edit' : 'Add' + ' Device'"
      [deviceLocation]="{ siteId: locationId, floorId: floor.id, roomId: room.id }" />
  </div>
</div>
<div class="card-separator"></div>

<ng-template #loading>
  <div class="text-center">
    <mat-spinner class="mx-auto" color="primary">LOADING</mat-spinner>
  </div>
</ng-template>
