import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { UserRolesIds } from '@models';

@Directive({
  selector: '[appSuperAdminRoleFilter]',
})
export class SuperAdminRoleFilterDirective implements OnInit {
  @Input() isManagingClient: boolean | undefined;

  constructor(private el: ElementRef, private matOption: MatOption) {}

  ngOnInit(): void {
    if (this.matOption.value !== UserRolesIds.SuperAdmin) return;
    if (!this.isManagingClient) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
