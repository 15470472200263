import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { MatFormField } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  standalone: true,
  styles: [],
  imports: [MatSliderThumb, MatSlider, MatFormField, FormsModule, MatInput],
})
export class SliderComponent {
  @Input() value = 10;
  @Input() disabled = false;
  @Output() valueChanged = new EventEmitter();
  min = 5;
  max = 30;

  sliderChange(value: number) {
    this.value = value;
    this.emitValue();
  }

  inputBlur() {
    if (this.value > this.max) {
      this.value = this.max;
    }
    if (this.value < this.min) {
      this.value = this.min;
    }
    this.emitValue();
  }

  emitValue() {
    this.valueChanged.emit(this.value);
  }
}
