import { Component } from '@angular/core';
import { deviceStatusChartData, firmwareOutdatedChartData } from '@mocks';
import * as Highcharts from 'highcharts';
import Accessibility from 'highcharts/modules/accessibility';
Accessibility(Highcharts);

@Component({
  selector: 'app-firmware-charts',
  templateUrl: './firmware-charts.component.html',
  styleUrls: ['./firmware-charts.component.scss'],
})
export class FirmwareChartsComponent {
  Highcharts: typeof Highcharts = Highcharts;
  deviceStatusChartData = deviceStatusChartData;
  firmwareOutdatedChartData = firmwareOutdatedChartData;
}
