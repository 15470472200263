import { Injectable } from '@angular/core';
import { EntityStatus, IncidentStatus } from '@models';
import { BehaviorSubject, Observable } from 'rxjs';

type MapFilter = Array<string>;

@Injectable({
  providedIn: 'root',
})
export class MapFilterService {
  private _mapFilter = new BehaviorSubject<MapFilter>([]);
  mapFilter$: Observable<MapFilter> = this._mapFilter.asObservable();
  mapFilterOptions = [
    {
      title: 'Building with new incident',
      value: IncidentStatus.IN_QUEUE,
      checked: false,
    },
    {
      title: 'Building with incident in progress',
      value: IncidentStatus.IN_PROGRESS,
      checked: false,
    },
    {
      title: 'Building without incident',
      value: 'good',
      checked: false,
    },
    {
      title: 'Building on Pause',
      value: EntityStatus.Paused,
      checked: false,
    },
  ];

  get mapFilter() {
    return this._mapFilter.value;
  }

  set mapFilter(filter: string[]) {
    this.mapFilterOptions = this.mapFilterOptions.map(option => {
      option.checked = filter.includes(option.value);
      return option;
    });
    this.updateMapFilterSubject();
  }

  updateMapFilterOption(filter: { value: string; checked: boolean }) {
    this.mapFilterOptions = this.mapFilterOptions.map(option => {
      if (option.value === filter.value) {
        option.checked = filter.checked;
      }
      return option;
    });
    this.updateMapFilterSubject();
  }

  updateMapFilterSubject() {
    this._mapFilter.next(this.mapFilterOptions.filter(({ checked }) => checked).map(({ value }) => value));
  }
}
