import { Pipe, PipeTransform } from '@angular/core';
import { isSpaceplanTabType } from '@app-lib';

@Pipe({
  name: 'filterAttachmentByType',
})
export class FilterAttachmentByTypePipe implements PipeTransform {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  transform(items: any[], type: string, editAttachmentId: string | null) {
    const isTabType = isSpaceplanTabType(type);
    if (!items || !type || !isTabType) {
      return items;
    }

    return items.filter(item => {
      const position = item.unstructuredDataReference && JSON.parse(item.unstructuredDataReference);
      return Boolean(position?.coordinates?.[type] || item.id === editAttachmentId);
    });
  }
}
