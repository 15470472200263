import { Pipe, PipeTransform } from '@angular/core';
import { DeviceData, EntityStatus } from '@models';

@Pipe({
  name: 'filterDeviceByStatus',
})
export class FilterDeviceByStatusPipe implements PipeTransform {
  transform(items: DeviceData[], status: 'current' | 'archived') {
    if (!items) {
      return [];
    }
    if (!status) {
      return items;
    }

    return items.filter(space => {
      return status === 'archived' ? space.status === EntityStatus.Archived : space.status !== EntityStatus.Archived;
    });
  }
}
