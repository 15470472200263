<mat-dialog-content class="text-center py-6">
  <p class="text-ui-gray-100 text-base font-poppins font-semibold mb-5">
    You've been inactive for {{ idleTimeMinutes }} minutes 30 seconds. In order to restore<br />
    your session you need to move your mouse. Otherwise the session will be ended after:
  </p>
  <p class="text-ui-gray-150 text-4xl font-poppins font-semibold">{{ counter }}</p>
</mat-dialog-content>
<!-- <mat-dialog-actions align="end" class="pt-2 pb-6 px-8">
  <button mat-dialog-close class="btn-green btn-medium">YES</button>
</mat-dialog-actions> -->
