import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Directive({
  selector: '[appBackButton]',
})
export class BackButtonDirective {
  @Input() simpleBackLink = false;
  @Input() directLink: string | null = null;

  constructor(private router: Router, private location: Location) {}

  @HostListener('click')
  onClick() {
    if (this.navigateToDirectLink()) {
      return;
    }
    if (this.goBack()) {
      return;
    }
    this.router.navigate(['/']);
  }

  private navigateToDirectLink(): boolean {
    if (!this.directLink) return false;

    this.router.navigate(['/', this.directLink]);
    return true;
  }

  private goBack(): boolean {
    if (window.history.length <= 1) return false;

    if (this.simpleBackLink) {
      this.location.back();
      return true;
    }

    this.router.navigate(this.extractUrlArray());
    return true;
  }

  private extractUrlArray(): string[] {
    const url = this.router.url.split('?')[0];
    const urlArray = url.split('/');
    urlArray.shift();
    urlArray.pop();
    return urlArray;
  }
}
