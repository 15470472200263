import { Component, Input } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-skeleton-page-header',
  templateUrl: './skeleton-page-header.component.html',
  standalone: true,
  styles: [],
  imports: [NgxSkeletonLoaderModule],
})
export class SkeletonPageHeaderComponent {
  @Input() buttonCount = 3;
}
