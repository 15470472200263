import { DeviceData } from './devices';
import { UserModel } from './users';

export interface IncidentModel {
  id: string;
  assetId: string;
  clientId: string;
  createdTimestamp: string;
  description: string;
  device: DeviceData;
  fault: {
    name: string;
    description: string;
  };
  inProgressSubStatus: string | null;
  incidentNumber: number;
  lastUpdatedTimestamp: string;
  resolvedTimestamp: string | null;
  severity: string;
  status: IncidentStatus;
}

export interface IncidentNoteModel {
  id: string;
  textValue: string;
  timeStamp: string;
  source: string;
  document: null | string;
  user: UserModel;
}

export enum IncidentStatus {
  IN_PROGRESS = 'inProgress',
  IN_QUEUE = 'new',
  RESOLVED = 'resolved',
}

export enum IncidentTabStatusFilter {
  OPEN = 'open',
  HISTORY = 'history',
}

export interface NormalizedIncident {
  id: string;
  ticketNumber: string;
  status: IncidentStatus;
  deviceId: string;
  deviceName: string;
  deviceType: string;
  manufacturer: string;
  model: string;
  serialNumber: string;
  reported: string;
  assignedUser: string;
  buildingId: string;
  building: string;
  floorId: string;
  floor: string;
  roomId: string;
  room: string;
  resolved: string;
  faultname: string;
}
