import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FloorSpaceModel } from '@models';
import { AppState, locationState, updateFloor } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService } from '@services';
import { AttachmentSpacePopoverComponent } from '@standalone/attachment-popovers/attachment-space-popover/attachment-space-popover.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-floor',
  templateUrl: './edit-floor.component.html',
})
export class EditFloorComponent implements OnDestroy {
  locationId: string | undefined;
  floor: FloorSpaceModel | undefined;
  locationStateSub$: Subscription;
  editRoomPopover = AttachmentSpacePopoverComponent;

  constructor(private store: Store<AppState>, private appService: AppService, private route: ActivatedRoute) {
    const floorId = route.snapshot.paramMap.get('floorId');
    this.locationStateSub$ = this.store.select(locationState).subscribe(building => {
      if (building) {
        this.locationId = building.id;
        this.floor = building.floors.find(({ id }) => id === floorId);
      }
    });
  }

  ngOnDestroy() {
    this.locationStateSub$?.unsubscribe();
  }

  onNameChange(fieldData: Record<string, string>) {
    this.updateFloor(fieldData as Partial<FloorSpaceModel>);
  }

  updateFloor(floorData: Partial<FloorSpaceModel>) {
    if (this.locationId && this.floor?.id) {
      this.store.dispatch(
        updateFloor({
          spaceId: this.floor.id,
          locationId: this.locationId,
          data: floorData,
        })
      );
    }
  }
}
