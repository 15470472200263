<div *ngIf="!showAddDeviceInfo">
  <div class="flex justify-between items-center">
    <div>
      <app-page-header>Add New Building</app-page-header>
      <app-breadcrumbs [routeNames]="['add new building']"></app-breadcrumbs>
    </div>
    <div class="flex">
      <button
        *ngIf="stepper.selectedIndex"
        (click)="goToPrevStep()"
        class="test-button-prev btn-green-stroked btn-medium mr-3">
        <mat-icon>chevron_left</mat-icon>
        <span>Previous</span>
      </button>
      <button
        *ngIf="stepper.selectedIndex < lastStepNumber"
        (click)="goToNextStep()"
        [disabled]="isLocationLoading$ | async"
        class="test-button-next btn-green btn-medium">
        <mat-spinner *ngIf="isLocationLoading$ | async" diameter="20" class="mr-1"></mat-spinner>
        Next
        <mat-icon>chevron_right</mat-icon>
      </button>
      <a
        *ngIf="stepper.selectedIndex === lastStepNumber"
        routerLink="/dashboard"
        class="test-button-confirm btn-green btn-medium">
        Confirm
      </a>
    </div>
  </div>
  <mat-card class="card pt-1 relative">
    <mat-stepper
      class="app-stepper"
      headerPosition="top"
      [linear]="true"
      #stepper
      (selectionChange)="stepChanged($event)">
      <mat-step completed="false" class="test-step-1">
        <ng-template matStepLabel>
          <p><strong>Step 1*</strong></p>
          <p>Building info</p>
        </ng-template>
        <app-new-site-form [isEditableMode]="!!selectedLocationId" />
      </mat-step>
      <mat-step completed="false" class="test-step-2">
        <ng-template matStepLabel>
          <p><strong>Step 2</strong></p>
          <p>Add floors</p>
        </ng-template>
        <app-add-floors *ngIf="stepper.selectedIndex === 1" />
      </mat-step>
      <mat-step completed="false" class="test-step-3">
        <ng-template matStepLabel>
          <p><strong>Step 3</strong></p>
          <p>Add & Assign rooms</p>
        </ng-template>
        <app-add-rooms *ngIf="stepper.selectedIndex === 2" />
      </mat-step>
      <mat-step completed="false" class="test-step-4">
        <ng-template matStepLabel>
          <p><strong>Step 4</strong></p>
          <p>Add & assign devices</p>
        </ng-template>
        <app-attach-device *ngIf="stepper.selectedIndex === 3" (addNewDevice)="addNewDeviceClick($event)" />
      </mat-step>
    </mat-stepper>
  </mat-card>
</div>
<div *ngIf="showAddDeviceInfo">
  <button
    class="flex items-center font-nunito text-ui-dark text-sm font-semibold hover:underline"
    (click)="toggleAddDeviceInfo(false)">
    <mat-icon fontIcon="chevron_left" />
    Back to Add Building
  </button>
  <app-new-device-form
    [showBreadcrumbs]="false"
    [deviceLocation]="deviceLocation"
    (createDeviceCallback)="toggleAddDeviceInfo(false)" />
</div>
