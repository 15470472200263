import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeviceData, EntityStatus, SelectOption } from '@models';
import { AppState, getDeviceBySpaceId, loadDeviceCollectionBySpaceId, updateDeviceData } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { AppService } from '@services';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import { defaultDocumentTab, getAttachmentPosition, isElementInViewport, isSpaceplanTabType } from '@app-lib';

interface ExtendedDeviceData extends DeviceData {
  position: null | Record<string, any>;
}

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styles: [],
})
export class DeviceListComponent implements OnChanges, OnDestroy {
  @Input() roomId = '';
  @Input() locationId = '';
  @Input() roomplan: string | undefined;
  @Input() showHeader = true;
  @Input() documentTab = defaultDocumentTab;
  @Output() editDevice = new EventEmitter<DeviceData>();
  deviceStatus: 'current' | 'archived' = 'current';
  deviceSearch = '';
  deviceOptions: SelectOption[] = [{ value: '', title: 'Select device' }];
  selectedDevice = new FormControl();
  deviceList: ExtendedDeviceData[] | undefined;
  entityStatus = EntityStatus;
  destroyRef = inject(DestroyRef);
  devicesSub$: Subscription | undefined;
  readonly defaultDocumentTab = defaultDocumentTab;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private dialog: MatDialog,
    private appService: AppService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['roomId'] || changes['locationId']) {
      if ((this.locationId, this.roomId)) {
        this.store.dispatch(loadDeviceCollectionBySpaceId({ locationId: this.locationId, roomId: this.roomId }));
        this.devicesSub$?.unsubscribe();

        this.devicesSub$ = this.store.select(getDeviceBySpaceId(this.roomId)).subscribe(devices => {
          this.deviceList = devices.map(device => {
            return {
              ...device,
              position: device.unstructuredDataReference && JSON.parse(device.unstructuredDataReference),
            };
          });
        });
      }
    }
  }

  hasDeviceCoordinates(device: ExtendedDeviceData) {
    return Boolean(
      this.documentTab === defaultDocumentTab
        ? false
        : isSpaceplanTabType(this.documentTab)
        ? device.position?.['coordinates']?.[this.documentTab]
        : false
    );
  }

  ngOnDestroy() {
    this.devicesSub$?.unsubscribe();
  }

  deviceSearchChanged(searchValue: string) {
    this.deviceSearch = searchValue;
  }

  onDeviceStatusFilterChange(change: MatButtonToggleChange) {
    this.deviceStatus = change.value;
  }

  get editDeviceId() {
    return this.appService.activeAttachmentId;
  }

  assignDevice(id: string) {
    this.appService.activeAttachmentId = id;
    const selectorId =
      this.documentTab === this.defaultDocumentTab ? 'app-spaceplan' : `app-spaceplan-${this.documentTab}`;
    const element = document.getElementById(selectorId);

    if (element && element instanceof HTMLElement && !isElementInViewport(element)) {
      scrollIntoView(element, { behavior: 'smooth', block: 'start' });
    }
  }

  removeCoordinates(device: DeviceData) {
    const fullPositionInfo = getAttachmentPosition(device.unstructuredDataReference);

    if (fullPositionInfo && isSpaceplanTabType(this.documentTab)) {
      delete fullPositionInfo.coordinates[this.documentTab];
    }

    const unstructuredDataReference = JSON.stringify(fullPositionInfo);

    this.openModal({
      title: 'Unassign a Device',
      description: `Are you sure you want to unassign a device [${device.friendlyName}] from the block diagram?`,
      data: { unstructuredDataReference },
      id: device.id,
    });
  }

  updateDevice(id: string, data: Partial<DeviceData>) {
    this.store.dispatch(
      updateDeviceData({
        locationId: this.locationId,
        deviceId: id,
        data,
      })
    );
  }

  openModal({
    title,
    description,
    data,
    id,
  }: {
    title: string;
    description: string;
    id: string;
    data: Partial<DeviceData>;
  }) {
    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.updateDevice(id, data);
        }
      });
  }

  archiveDevice(device: DeviceData) {
    this.openModal({
      title: 'Archive device',
      description: `Are you sure you want to archive device [${device.friendlyName}]?`,
      id: device.id,
      data: { status: EntityStatus.Archived },
    });
  }

  restoreDevice(device: DeviceData) {
    this.openModal({
      title: 'Restore device',
      description: `Are you sure you want to restore device [${device.friendlyName}]?`,
      id: device.id,
      data: { status: EntityStatus.Active },
    });
  }

  editDeviceAction(device: DeviceData) {
    this.editDevice.emit(device);
  }
}
