<div
  class="test-skeleton-animation text-center"
  *ngIf="(isFullLocationLoaded$ | async) === false && !locationFullData; else roomDetails">
  <app-skeleton-page-header />
  <ngx-skeleton-loader [theme]="{ height: '130px' }" />
  <ngx-skeleton-loader [theme]="{ height: '300px' }" />
  <app-skeleton-table [columnCount]="5" />
  <app-skeleton-table [columnCount]="5" />
</div>
<ng-template #roomDetails>
  @let isSuperAdmin = (isSuperAdmin$ | async);
  <div class="text-content" *ngIf="locationFullData && currentFloor && currentRoom && currentFloorId && currentRoomId">
    <app-dashboard-location-header
      [roomId]="currentRoomId"
      [floorId]="currentFloorId"
      [locationData]="locationFullData" />

    <app-building-info
      [currentFloorId]="currentFloorId"
      [currentRoomId]="currentRoomId"
      (spaceChanged)="spaceChanged($event)"
      [locationData]="locationFullData"
      [floorName]="currentFloor.friendlyName"
      [buildingStats]="buildingStats" />

    <mat-card class="card my-4">
      <div class="flex items-center justify-between">
        <mat-card-title class="card-title">Block Diagram / Floor Plan</mat-card-title>
        <mat-slide-toggle class="main-toggle" labelPosition="before" [checked]="showPlan" (change)="togglePlan($event)">
          Show Block Diagram / Floor Plan
        </mat-slide-toggle>
      </div>

      <div [ngClass]="{ 'is-hidden-tab': !showPlan }">
        <div class="my-4">
          <mat-button-toggle-group [(ngModel)]="planType" class="main-toggle-group">
            <mat-button-toggle class="border-ui-green-light" value="room">
              <mat-icon matIconPrefix *ngIf="planType === 'room'">check</mat-icon>
              Block Diagram
            </mat-button-toggle>
            <mat-button-toggle class="border-ui-green-light" value="floor">
              <mat-icon matIconPrefix *ngIf="planType === 'floor'">check</mat-icon>
              Floor Plan
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div [ngClass]="{ 'is-hidden-tab': planType === 'floor' }">
          <hr class="mb-4 border-ui-gray-light" />
          <app-spaceplan-tabs
            *ngIf="currentRoom"
            [locationId]="currentLocationId"
            [room]="currentRoom"
            [popover]="devicePopover"
            [canEdit]="false" />
        </div>

        <div [ngClass]="{ 'is-hidden-tab': planType === 'room' }">
          <ng-container *ngIf="!currentFloor.documentReference && currentLocationId">
            @if (isSuperAdmin) {
            <app-upload-spaceplan
              appRestrictedEl
              [locationId]="currentLocationId"
              uploadText="Upload Floor Plan"
              [floorId]="currentFloor.id" />
            } @else {
            <app-no-data noDataText="No floor plan added" />
            }
          </ng-container>
          <app-spaceplan
            *ngIf="currentFloor.documentReference && currentLocationId"
            [currentAttachmentId]="currentRoomId"
            [spaceplan]="{
              id: currentFloor.id,
              documentReference: currentFloor.documentReference,
              attachments: currentFloor.rooms || [],
              parentSpaceId: currentFloor.parentSpaceId
            }"
            [popover]="roomPopover"
            [locationId]="currentLocationId"
            [canEdit]="false" />
        </div>
      </div>
    </mat-card>

    <mat-card class="card pb-2 mb-4">
      <mat-card-title class="card-title">Room Incidents</mat-card-title>
      <mat-card-content class="p-0">
        <app-incidents-table-desktop-filters
          tableId="room-details-incidents-table"
          [filtersEnabled]="true"
          [resolvedIncidentsLoading]="resolvedIncidentsLoading" />
        <app-incidents-table
          tableId="room-details-incidents-table"
          [isInjected]="true"
          [dataIsLoading]="resolvedIncidentsLoading"
          noDataText="All the Room devices are working properly" />
      </mat-card-content>
    </mat-card>

    <mat-card class="card pb-2">
      <div class="flex items-center justify-between">
        <mat-card-title class="card-title">Devices</mat-card-title>
        <a
          appRestrictedEl
          [routerLink]="['/devices', 'add-device']"
          [queryParams]="{ locationId: locationFullData.id, floorId: currentFloorId, roomId: currentRoomId }"
          class="btn-base border border-ui-gray-light px-2 py-1 text-ui-dark font-normal cursor-pointer">
          <mat-icon matPrefix>add</mat-icon>
          Add Device
        </a>
      </div>
      <app-devices-table-desktop-filters
        tableId="room-details-device-table"
        [showDeviceTypeFilter]="false"
        [showManufacturerFilter]="false" />
      <app-devices-table tableId="room-details-device-table" [isInjected]="true" />
    </mat-card>
    <div class="card-separator"></div>
  </div>
</ng-template>
