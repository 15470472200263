import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';

type ExportDataType = 'json' | 'csv';

@Component({
  selector: 'app-export-report-menu',
  template: `
    <button [matMenuTriggerFor]="menu" class="test-menu-btn btn-blue btn-medium" [disabled]="disabled">
      <i class="icon-upload-map text-3xl mr-1.5"></i>Export to CSV / JSON
      <mat-icon class="ml-2">keyboard_arrow_down </mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="export-data-overlay">
      <button mat-menu-item (click)="exportDataTypeSelect('csv')">Export to CSV</button>
      <button mat-menu-item (click)="exportDataTypeSelect('json')">Export to JSON</button>
    </mat-menu>
  `,
  standalone: true,
  styles: [],
  imports: [MatMenuTrigger, MatIcon, MatMenu, MatMenuItem],
})
export class ExportReportMenuComponent {
  @Input() disabled = false;
  @Output() exportDataType = new EventEmitter<ExportDataType>();

  exportDataTypeSelect(value: ExportDataType) {
    this.exportDataType.emit(value);
  }
}
