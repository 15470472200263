import { DeviceData } from './devices';
import { SpaceImage } from './locations';

export enum EntityStatus {
  Active = 'active',
  Paused = 'paused',
  Archived = 'archived',
}

export interface Space {
  id: string;
  name: string;
  status: EntityStatus;
  friendlyName: string;
  parentSpaceId: string | null;
  type: SpaceType;
  documentReference: string;
  unstructuredDataReference: string;
  externalReference: string;
  devicesCount: number;
  devicesByStatuses: {
    activeCount: number;
    deactivatedCount: number;
    pausedCount: number;
  };
  incidentCountByStatuses: {
    newCount: number;
    inProgressCount: number;
  };
}

export enum SpaceType {
  room = 'Room',
  floor = 'Floor',
}

export interface FloorSpaceModel extends Space {
  rooms?: RoomSpaceModel[];
  floorplan?: string;
}

export interface RoomSpaceModel extends Space {
  devices?: DeviceData[];
}

export interface Coordinates {
  width: number;
  height: number;
  left: number;
  top: number;
}

export interface AttachmentPosition {
  coordinates: Coordinates & Partial<Record<SpaceplanTabType, Coordinates>>;
}

export type AttachmentItemModel = {
  id: string;
  unstructuredDataReference: string;
  status: EntityStatus;
  incidentCountByStatuses: {
    newCount: number;
    inProgressCount: number;
  };
  type?: SpaceType;
};

export type SpaceplanModel = {
  id: string;
  documentReference: string;
  attachments: AttachmentItemModel[];
  parentSpaceId: string | null;
};

export enum SpaceplanTabType {
  AUDIO = 'audio',
  CONTROL = 'control',
  NETWORK = 'network',
  VIDEO = 'video',
}
