import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, loadLocations, loadLocationsSummaries } from '@ngrx-store';
import { AppService } from '@services';

export const locationsResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
  const clientId = route.queryParams['clientId'] || inject(AppService).currentClient;
  return inject(Store<AppState>).dispatch(loadLocations({ clientId }));
};

export const locationsSummariesResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const clientId = route.queryParams['clientId'] || inject(AppService).currentClient;
  return inject(Store<AppState>).dispatch(loadLocationsSummaries({ clientId }));
};
