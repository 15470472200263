<div class="attach-device-item" [class.disabled]="!floor.rooms?.length" [class.is-open]="floor.id === openedFloorId">
  <div
    class="attach-device-item-title flex items-center h-[44px] px-3 font-nunito text-sm cursor-pointer"
    (click)="titleClick()">
    <mat-icon class="item-closed">keyboard_arrow_down</mat-icon>
    <mat-icon class="item-open">keyboard_arrow_up</mat-icon>
    <span class="inline-flex ml-2">{{ floor.friendlyName }}</span>
  </div>
  <div class="rooms-content-wrapper">
    <span class="rooms-placeholder" *ngIf="roomsPlaceholder"
      >{{ roomsPlaceholder }} room{{ roomsPlaceholder > 1 ? 's' : '' }}</span
    >
    <ul class="rooms-content overflow-auto max-h-[440px]">
      <li
        *ngFor="let room of floor.rooms"
        class="room-item"
        [class.selected]="room.id === selectedRoomId"
        (click)="selectRoom(room.id)">
        {{ room.friendlyName }}
      </li>
    </ul>
  </div>
  <div class="devices-content-wrapper">
    <div class="devices-content" *ngIf="selectedRoomId">
      <div class="flex px-3 py-2">
        <mat-form-field class="select-classic w-full size-small">
          <mat-select [formControl]="selectedDevice" placeholder="Select Device" panelClass="single-select-overlay">
            <button class="test-add-new-device-button btn-green btn-small ml-3 my-2.5" (click)="addNewDeviceClick()">
              <mat-icon>add</mat-icon>
              Add new device
            </button>
            <mat-option *ngFor="let divice of deviceOptions" [value]="divice.value">
              {{ divice.title }}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
        </mat-form-field>
        <button
          class="btn-green btn-small min-w-[122px] ml-3"
          (click)="attachDevice()"
          [disabled]="!selectedDevice.value">
          <mat-icon>add</mat-icon>
          Add Device
        </button>
      </div>
      <ul class="overflow-auto max-h-[440px]">
        <li
          *ngFor="let device of devices"
          class="flex items-center justify-between px-3 border-t border-t-ui-gray-light h-[44px] font-nunito text-sm text-ui-dark">
          <span class="underline">{{ device.friendlyName }}</span>
          <mat-icon
            class="text-ui-gray-100 text-2xl hover:text-ui-error cursor-pointer inline-flex items-center"
            (click)="deleteDevice(device.id)"
            >delete_outline
          </mat-icon>
        </li>
      </ul>
    </div>
  </div>
</div>
