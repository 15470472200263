<div class="flex">
  <label class="input-main-label">Address:*</label>
  <mat-form-field class="input-classic">
    <input [(ngModel)]="autocompleteInput" #place matInput placeholder="Address line" type="text" />
    @if (autocompleteInput) {
    <button matSuffix aria-label="Clear" class="p-0" (click)="clearAutocomplete()">
      <i class="icon-circle-close text-ui-dark text-3xl hover:text-ui-gray-dark"></i>
    </button>
    }
  </mat-form-field>
</div>
<google-map width="100%" height="250px" [options]="mapOptions" (mapInitialized)="mapInitialized($event)">
  @if (mapCenter) {
  <map-advanced-marker
    #advancedMarker="mapAdvancedMarker"
    [position]="mapCenter"
    [content]="markerImg"></map-advanced-marker>
  }
</google-map>
