import { AfterContentInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MatFormField, MatOption, MatSelect, MatSelectChange } from '@angular/material/select';
import { DevicesFilterService, SessionStorageService } from '@services';
import { NgForOf, NgIf } from '@angular/common';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLabel } from '@angular/material/form-field';
import { MultipleSelectComponent } from '@standalone/multiple-select/multiple-select.component';
import { MatIcon } from '@angular/material/icon';
import { PipesModule } from '@pipes';
import { MatTooltip } from '@angular/material/tooltip';
import { EntityStatus } from '@models';
import { TableSearchInputComponent } from '@standalone/_tables/table-search-input/table-search-input.component';

interface Filters {
  [key: string]: string | string[];
}

@Component({
  standalone: true,
  selector: 'app-devices-table-desktop-filters',
  templateUrl: './devices-table-desktop-filters.component.html',
  imports: [
    NgIf,
    MatLabel,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatFormField,
    MatSelect,
    ReactiveFormsModule,
    MatOption,
    NgForOf,
    MultipleSelectComponent,
    FormsModule,
    MatIcon,
    PipesModule,
    MatTooltip,
    TableSearchInputComponent,
  ],
})
export class DevicesTableDesktopFiltersComponent implements AfterContentInit {
  protected readonly EntityStatus = EntityStatus;
  protected readonly filtersInitValue: Filters = {
    type: [],
    manufacturer: [],
    building: '',
    floor: '',
    room: '',
  };
  filters = { ...this.filtersInitValue };
  @Input() showColumnSelect = true;
  @Input() tableId!: string;
  @Input() showManufacturerFilter = true;
  @Input() showDeviceTypeFilter = true;
  @ViewChild('columnSelectRef') columnSelectRef!: MatSelect;

  constructor(
    public devicesFilterService: DevicesFilterService,
    private sessionStorageService: SessionStorageService,
    private cdr: ChangeDetectorRef
  ) {}

  selectAllColumns() {
    this.columnSelectRef.options.forEach((option: MatOption) => option.select());
  }

  columnListUpdated($event: MatSelectChange) {
    this.devicesFilterService.columnsChange($event);
    this.sessionStorageService.saveColumnsSettingsToSessionStorage(this.tableId, $event.value);
  }

  ngAfterContentInit(): void {
    this.applySavedFilters();
  }

  applySavedFilters() {
    const columns = this.sessionStorageService.getColumns(this.tableId);
    const storageFilters = this.sessionStorageService.getFilters(this.tableId);
    if (columns?.length) {
      this.devicesFilterService.displayedColumns = columns;
      this.devicesFilterService.initColumns(columns);
      this.columnListUpdated({ value: columns } as MatSelectChange);
    }
    if (storageFilters) {
      this.filters = storageFilters;
      if (this.showDeviceTypeFilter) {
        this.setDeviceType((this.filters['type'] || []) as Array<string>);
      }
      if (this.showManufacturerFilter) {
        this.setManufacturer((this.filters['manufacturer'] || []) as Array<string>);
      }
      this.devicesFilterService.selectedBuilding = (this.filters['building'] || '') as string;
      this.devicesFilterService.selectedFloor = (this.filters['floor'] || '') as string;
      this.devicesFilterService.selectedRoom = (this.filters['room'] || '') as string;
    }
    this.cdr.detectChanges();
  }

  setDeviceType($event: Array<string>) {
    this.devicesFilterService.deviceTypeValue = $event;
    this.filters['type'] = $event;
    this.saveFiltersToSessionStorage();
  }

  setManufacturer($event: Array<string>) {
    this.devicesFilterService.manufacturerValue = $event;
    this.filters['manufacturer'] = $event;
    this.saveFiltersToSessionStorage();
  }

  buildingFilterChanged($event: any) {
    this.filters['building'] = $event;
    this.filters['floor'] = '';
    this.filters['room'] = '';
    this.saveFiltersToSessionStorage();
  }

  floorFilterChanged($event: any) {
    this.filters['floor'] = $event;
    this.filters['room'] = '';
    this.saveFiltersToSessionStorage();
  }

  roomFilterChanged($event: any) {
    this.filters['room'] = $event;
    this.saveFiltersToSessionStorage();
  }

  saveFiltersToSessionStorage() {
    this.sessionStorageService.saveFiltersToSessionStorage(this.tableId, this.filters);
  }

  clearAllFilters() {
    this.devicesFilterService.clearAllFilters();
    this.filters = { ...this.filtersInitValue };
    this.saveFiltersToSessionStorage();
  }
}
