import { Injectable } from '@angular/core';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  authActionError,
  authChangeUserPassword,
  authUserPasswordChanged,
  forgotPassword,
  forgotPasswordSuccess,
  login,
  logout,
  passwordRecovered,
  passwordRecovery,
  superAdminClientCreated,
  updateNotificationData,
  updatePersonalData,
  updatePersonalDataSuccess,
} from './auth.actions';
import { AppService, AuthService, NotificationsService, SessionStorageService, UserManagementService } from '@services';
import { map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserModel } from '@models';

@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(login),
        tap(action => {
          if (action.user.userClients) {
            const lastClient = window.localStorage.getItem('clientId');
            const availableUserClient = action.user.userClients.find(({ id }) => id === lastClient);
            this.appService.currentClient = availableUserClient
              ? availableUserClient.id
              : action.user.userClients[0].id;
          }
          localStorage.setItem('user', JSON.stringify(action.user));
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => {
          localStorage.removeItem('user');
          this.sessionStorageService.clear();
          this.appService.isInitailizedAppSubscription = false;
          this.router.navigateByUrl('/');
        })
      ),
    { dispatch: false }
  );

  sendPasswordRecovery$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(passwordRecovery),
      switchMap(action =>
        this.authService.passwordRecovery(action.recoveryData).pipe(
          map(() => passwordRecovered()),
          catchError(async error => authActionError({ error }))
        )
      )
    );
  });

  passwordRecovered$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(passwordRecovered),
        tap(() => {
          this.router.navigateByUrl('password-recovery/success');
        })
      );
    },
    { dispatch: false }
  );

  forgotPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(forgotPassword),
      switchMap(action =>
        this.authService.forgotPassword(action.email).pipe(
          map(() => forgotPasswordSuccess()),
          catchError(async error => authActionError({ error: error }))
        )
      )
    );
  });

  forgotPasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(forgotPasswordSuccess),
        tap(() => {
          this.router.navigateByUrl('forgot-password/success');
        })
      );
    },
    { dispatch: false }
  );

  userErrorHandler$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActionError),
        tap(() => {
          this.notificationService.showErrorMessage('Error: Please try again later');
          this.router.navigateByUrl('');
        })
      );
    },
    { dispatch: false }
  );

  userChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authChangeUserPassword),
      switchMap(action =>
        this.authService.changePassword(action.data).pipe(
          map(() => {
            this.notificationService.showSuccessMessage(
              'User password successfully changed. Please log in with new password'
            );
            return authUserPasswordChanged();
          }),
          catchError(async error => authActionError({ error: error }))
        )
      )
    )
  );

  userPasswordChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authUserPasswordChanged),
      map(() => {
        this.matDialog.closeAll();
        return logout();
      })
    )
  );

  updatePersonalUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePersonalData),
      switchMap(action =>
        this.userManagementService.updatePersonalUserData<UserModel>(action.data).pipe(
          map(user => updatePersonalDataSuccess({ user })),
          catchError(async error => authActionError({ error: error }))
        )
      )
    )
  );

  updatePersonalNotificationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNotificationData),
      switchMap(action =>
        this.userManagementService.updateUserData<UserModel>(action.clientId, action.userId, action.data).pipe(
          map(user => updatePersonalDataSuccess({ user })),
          catchError(async error => authActionError({ error: error }))
        )
      )
    )
  );

  updatePersonalDataSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updatePersonalDataSuccess),
        tap(user => {
          this.notificationService.showSuccessMessage('Your profile was successfully updated');
          const userProfile = localStorage.getItem('user');
          if (userProfile) {
            const userProfileObject = JSON.parse(userProfile);
            localStorage.setItem('user', JSON.stringify({ ...userProfileObject, ...user.user }));
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  updateClientList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(superAdminClientCreated),
        tap(client => {
          const userProfile = localStorage.getItem('user');
          if (userProfile && client) {
            const userProfileObject: UserModel = JSON.parse(userProfile);
            userProfileObject.userClients?.push(client.newClient);
            localStorage.setItem('user', JSON.stringify(userProfileObject));
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private notificationService: NotificationsService,
    private authService: AuthService,
    private appService: AppService,
    private matDialog: MatDialog,
    private sessionStorageService: SessionStorageService,
    private userManagementService: UserManagementService
  ) {}
}
