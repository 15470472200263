import { Component, Inject } from '@angular/core';
import { DeviceData, EntityStatus } from '@models';
import { TIPPY_REF, TippyInstance } from '@ngneat/helipopper';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-device-popover',
  templateUrl: './device-popover.component.html',
  imports: [NgIf, RouterLink],
})
export class DevicePopoverComponent {
  locationId = '';
  floorId = '';
  device: DeviceData | null = null;
  allowNavigation = true;
  entityStatus = EntityStatus;
  hide: () => void;

  constructor(@Inject(TIPPY_REF) tippy: TippyInstance) {
    const { locationId, floorId, details, allowNavigation = true } = tippy.data;
    this.locationId = locationId;
    this.floorId = floorId;
    this.device = details;
    this.allowNavigation = allowNavigation;

    this.hide = tippy.hide;
  }
}
