import { Injectable } from '@angular/core';
import { DeviceModel, SelectOption } from '@models';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceHelperService {
  private _deviceTypeOptions: BehaviorSubject<SelectOption[]> = new BehaviorSubject<SelectOption[]>([]);
  deviceTypeOptions$: Observable<SelectOption[]> = this._deviceTypeOptions.asObservable();

  constructor(private notificationService: NotificationsService) {}

  initDeviceTypeOptions(models: DeviceModel[]) {
    const deviceTypeOptions = [...new Set(models.map(model => model.deviceType))].map(deviceType => ({
      value: deviceType,
      title: deviceType,
    }));
    this._deviceTypeOptions.next(deviceTypeOptions);
  }

  addDeviceTypeOption(deviceType: string) {
    const deviceTypeOptions = [...this._deviceTypeOptions.value, { value: deviceType, title: deviceType }];
    this._deviceTypeOptions.next(deviceTypeOptions);
    this.notificationService.showSuccessMessage(`A new device type [ ${deviceType} ] was successfully created`);
  }

  resetState() {
    this._deviceTypeOptions.next([]);
  }
}
