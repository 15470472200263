import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityStatus, FloorSpaceModel } from '@models';
import { AttachmentSpacePopoverComponent } from '@standalone/attachment-popovers/attachment-space-popover/attachment-space-popover.component';

@Component({
  selector: 'app-expandable-floor-item',
  templateUrl: './expandable-floor-item.component.html',
  styleUrls: ['./expandable-floor-item.component.scss'],
})
export class ExpandableFloorItemComponent {
  @Input() floor!: FloorSpaceModel;
  @Input() openedFloorId: string | null = null;
  @Input() locationId: string | null = null;
  @Output() clickEvent = new EventEmitter<string>();
  editRoomPopover = AttachmentSpacePopoverComponent;

  titleClick() {
    this.clickEvent.emit(this.floor.id);
  }

  get roomsPlaceholder() {
    return this.floor.rooms?.filter(({ status }) => status !== EntityStatus.Archived).length;
  }
}
