import { Component, OnDestroy } from '@angular/core';
import { LocationBaseModel, LocationFullData } from '@models';
import { AppState, locationState, updateLocation } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService } from '@services';
import { Subscription } from 'rxjs';
import { COUNTRY_LIST } from '@app-lib';
import { AutocompleteAddress } from '@standalone/google-place-autocomplete/google-place-autocomplete.component';

@Component({
  selector: 'app-edit-site',
  templateUrl: './edit-site.component.html',
  styles: [],
})
export class EditSiteComponent implements OnDestroy {
  building: LocationFullData | undefined;
  countryList = COUNTRY_LIST;
  locationStateSub$: Subscription;

  constructor(private store: Store<AppState>, private appService: AppService) {
    this.locationStateSub$ = this.store.select(locationState).subscribe(building => {
      this.building = building;
    });
  }

  ngOnDestroy() {
    this.locationStateSub$?.unsubscribe();
  }

  onSiteChange(fieldData: Record<string, string>, parentPath?: string) {
    const locationData = parentPath ? { [parentPath]: fieldData } : fieldData;
    this.updateSite(locationData);
  }

  updateSite(locationData: Partial<LocationBaseModel>) {
    if (this.building) {
      this.store.dispatch(
        updateLocation({
          locationId: this.building.id,
          data: locationData,
          clientId: this.appService.currentClient,
        })
      );
    }
  }

  countryChange(value: string) {
    const locationData = { address: { country: value } } as Partial<LocationBaseModel>;
    this.updateSite(locationData);
  }

  updateAddress(event: AutocompleteAddress) {
    if (event.lat && event.lng) {
      this.updateSite({
        address: {
          streetAddress: event.address,
          city: event.city,
          stateProvince: event.state,
          postalCode: event.zipCode,
        },
        geographic: {
          lat: event.lat,
          long: event.lng,
        },
      } as Partial<LocationBaseModel>);
    }
  }
}
