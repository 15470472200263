<div class="main-slider-container">
  <div>
    <mat-slider [disabled]="disabled" class="main-slider" [max]="max" [min]="min" [step]="1" [discrete]="true">
      <input matSliderThumb (valueChange)="sliderChange($event)" [value]="value || min" #slider />
    </mat-slider>
    <div class="flex justify-between items-center">
      <span class="slider-restriction">{{ min }} min</span>
      <span class="slider-restriction">{{ max }} max</span>
    </div>
  </div>
  <mat-form-field class="main-slider-input">
    <input
      name="value"
      [(ngModel)]="value"
      matInput
      type="number"
      [min]="min"
      [max]="max"
      placeholder="5"
      autocomplete="off"
      (blur)="inputBlur()"
      [disabled]="disabled"
      class="slider-input-field appearance-textfield" />
  </mat-form-field>
</div>
