import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AppState, userRole } from '@ngrx-store';
import { UserRolesIds } from '@models';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const activeForAdmin = route.data['activeForAdmin'];

    return this.store.select(userRole).pipe(
      tap(userRole => {
        if (userRole === UserRolesIds.ReadOnly || (userRole === UserRolesIds.ClientAdmin && !activeForAdmin)) {
          this.router.navigateByUrl('dashboard');
        }
      })
    );
  }
}
