import { Component } from '@angular/core';
import { SpaceStats, initialStats, LocationFullData, EntityStatus } from '@models';
import { Store } from '@ngrx/store';
import { AppState, isFullLocationLoaded, locationState } from '@ngrx-store';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-building-management-page',
  templateUrl: './building-management-page.component.html',
})
export class BuildingManagementPageComponent {
  locationData: LocationFullData | undefined;
  isFullLocationLoaded$: Observable<boolean>;
  buildingStats: SpaceStats = initialStats();
  readonly entityStatus = EntityStatus;

  constructor(private store: Store<AppState>) {
    this.store
      .select(locationState)
      .pipe(takeUntilDestroyed())
      .subscribe(locationData => {
        this.locationData = locationData;
        this.collectBuildingStats();
      });
    this.isFullLocationLoaded$ = this.store.select(isFullLocationLoaded);
  }

  collectBuildingStats() {
    if (this.locationData) {
      const floors = this.locationData.floors;
      this.buildingStats = initialStats();
      const isLocationArchived = this.locationData.status === EntityStatus.Archived;
      if (floors) {
        floors.forEach(floor => {
          if (floor.rooms) {
            this.buildingStats.rooms += floor.rooms.filter(({ status }) => status !== EntityStatus.Archived).length;
          }
        });
        this.buildingStats.floors = floors.filter(({ status }) => status !== EntityStatus.Archived).length;
      }
      this.buildingStats.incidentAlert = isLocationArchived
        ? 0
        : this.locationData.incidentCountByStatuses?.newCount || 0;
      this.buildingStats.incidentInProgress = isLocationArchived
        ? 0
        : this.locationData.incidentCountByStatuses?.inProgressCount || 0;
      this.buildingStats.deviceActive = isLocationArchived ? 0 : this.locationData.devicesByStatuses?.activeCount || 0;
      this.buildingStats.devicePaused = isLocationArchived ? 0 : this.locationData.devicesByStatuses?.pausedCount || 0;
    }
  }
}
