<h1 class="dialog-title">
  <span>{{ data ? 'Edit' : 'Add' }} Space</span>
  <button mat-dialog-close>
    <i class="icon-close"></i>
  </button>
</h1>
<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content class="dialog-content">
    <div class="flex flex-row">
      <label for="name" class="input-main-label capitalize">Space name:*</label>
      <mat-form-field class="input-classic">
        <input matInput id="name" type="text" placeholder="Enter space name" formControlName="name" />
        <mat-error>Form is not valid</mat-error>
      </mat-form-field>
    </div>
    <div class="flex flex-row">
      <label class="input-main-label">Select Building*</label>
      <div>
        <mat-form-field class="select-classic has-validation">
          <mat-select formControlName="buildingId" placeholder="Select Building" panelClass="single-select-overlay">
            <mat-option *ngFor="let building of buildingOptions" [value]="building.value">
              {{ building.title }}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
          <mat-error>Form is not valid</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-row items-center mb-5">
      <label class="input-main-label mt-0">Select Floor</label>
      <div>
        <mat-form-field class="select-classic">
          <mat-select formControlName="floorId" placeholder="Select Floor" panelClass="single-select-overlay">
            <mat-option *ngFor="let floor of floorOptions" [value]="floor.value">
              {{ floor.title }}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-row items-center">
      <label class="input-main-label mt-0">Select Room</label>
      <div>
        <mat-form-field class="select-classic">
          <mat-select formControlName="roomId" placeholder="Select Room" panelClass="single-select-overlay">
            <mat-option *ngFor="let room of roomOptions" [value]="room.value">
              {{ room.title }}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-dialog-close class="test-btn-cancel btn-transparent btn-medium font-bold mr-4">CANCEL</button>
    <button type="submit" class="btn-green btn-medium font-bold" (click)="submit()">{{ data ? 'SAVE' : 'ADD' }}</button>
  </mat-dialog-actions>
</form>
