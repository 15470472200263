import { Component, Input } from '@angular/core';
import { FloorSpaceModel, LocationFullData } from '@models';
import { locations } from '@mocks';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { PipesModule } from '@pipes';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatPrefix, MatSuffix } from '@angular/material/form-field';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { FilterNoBuildingsFoundComponent } from '@standalone/filter-no-buildings-found/filter-no-buildings-found.component';

type UiHelper = {
  [key: string]: {
    expanded: boolean;
    floor: FloorSpaceModel | null;
    roomSearch: string;
    floorSearch: string;
  };
};

type AlertSettingsObject = {
  [key: string]: {
    [key: string]: string[];
  };
};

@Component({
  selector: 'app-alert-settings',
  standalone: true,
  templateUrl: './alert-settings.component.html',
  styleUrls: ['./alert-settings.component.scss'],
  imports: [
    PipesModule,
    NgForOf,
    NgClass,
    MatTooltip,
    NgIf,
    MatIcon,
    MatPrefix,
    MatCheckbox,
    SearchInputComponent,
    MatSuffix,
    FilterNoBuildingsFoundComponent,
  ],
})
export class AlertSettingsComponent {
  @Input() locations: LocationFullData[] = locations;
  @Input() alertSettings: AlertSettingsObject = {};
  @Input() buildingFilterValue = '';
  public uiHelper: UiHelper = {};

  get alertSettingsValue() {
    return this.alertSettings;
  }

  constructor() {
    locations.forEach((building: LocationFullData, index: number) => {
      this.uiHelper = {
        ...this.uiHelper,
        ...{
          [building.id]: {
            expanded: !!(index <= 0 && building.floors.length),
            floor: null,
            roomSearch: '',
            floorSearch: '',
          },
        },
      };
    });
  }

  selectFloor(floor: FloorSpaceModel, building: LocationFullData) {
    this.uiHelper[building.id].floor = floor;
    this.uiHelper[building.id].roomSearch = '';
  }

  private _selectAllFloorsAndRooms(building: LocationFullData) {
    this.alertSettings[building.id] = {};

    building.floors.forEach(floor => {
      if (floor.id) {
        this.alertSettings[building.id][floor.id] = [];
        if (floor.rooms) {
          floor.rooms.forEach(room => {
            if (floor.id && room.id) {
              this.alertSettings[building.id][floor.id].push(room.id);
            }
          });
        }
      }
    });
  }

  private _removeSelectionAllFloorsAndRooms(building: LocationFullData) {
    delete this.alertSettings[building.id];
  }

  toggleAllRoomsOnTheFloor(matCheckboxChange: MatCheckboxChange, building: LocationFullData, floor: FloorSpaceModel) {
    if (!matCheckboxChange.checked) {
      delete this.alertSettings[building.id][floor.id];
      return;
    }
    if (floor.rooms) {
      const roomIds = floor.rooms.map(room => room.id);
      if (!this.alertSettings[building.id]) this.alertSettings[building.id] = {};
      if (!this.alertSettings[building.id][floor.id]) this.alertSettings[building.id][floor.id] = roomIds;
    }
  }

  isFloorChecked(buildingId: string, floorId: string): boolean {
    return !!(this.alertSettings[buildingId] && this.alertSettings[buildingId][floorId]);
  }

  toggleBuildingSelection(matCheckboxChange: MatCheckboxChange, building: LocationFullData) {
    matCheckboxChange.checked
      ? this._selectAllFloorsAndRooms(building)
      : this._removeSelectionAllFloorsAndRooms(building);
  }

  buildingHasSelectedItems(buildingId: string): boolean {
    return !!(this.alertSettings[buildingId] && Object.keys(this.alertSettings[buildingId]).length);
  }

  toggleRoomSelection(matCheckboxChange: MatCheckboxChange, building: LocationFullData, roomId: string) {
    const currentFloor = this.uiHelper[building.id].floor;
    if (currentFloor) {
      if (!this.alertSettings[building.id]) this.alertSettings[building.id] = {};
      if (currentFloor && !this.alertSettings[building.id][currentFloor?.id])
        this.alertSettings[building.id][currentFloor.id] = [];
      if (matCheckboxChange.checked) {
        this.alertSettings[building.id][currentFloor.id].push(roomId);
      } else {
        const index = this.alertSettings[building.id][currentFloor.id].indexOf(roomId);
        if (index !== -1) {
          this.alertSettings[building.id][currentFloor.id].splice(index, 1);
          if (!this.alertSettings[building.id][currentFloor.id].length) {
            delete this.alertSettings[building.id][currentFloor.id];
          }
        }
      }
    }
  }

  isRoomChecked(buildingId: string, roomId: string): boolean {
    const selectedFloor = this.uiHelper[buildingId].floor || false;
    if (!(selectedFloor && selectedFloor.id)) return false;
    if (this.alertSettings[buildingId] && this.alertSettings[buildingId][selectedFloor.id]) {
      return !!this.alertSettings[buildingId][selectedFloor.id].includes(roomId);
    }
    return false;
  }

  buildingStateIsIndeterminate(building: LocationFullData): boolean {
    const id = building.id;
    if (this.alertSettings[id]) {
      const roomsState: boolean[] = [];
      building.floors.forEach(floor => {
        roomsState.push(this.floorStateIsIndeterminate(building, floor));
      });
      if (roomsState.length && roomsState.includes(true)) return true;
      if (Object.keys(this.alertSettings[id]).length === building.floors.length) {
        return false;
      }
      return !!Object.keys(this.alertSettings[id]).length;
    }
    return false;
  }

  floorStateIsIndeterminate(building: LocationFullData, floor: FloorSpaceModel): boolean {
    if (this.alertSettings[building.id]) {
      if (!this.alertSettings[building.id][floor.id]) return false;
      const currentFloor = building.floors.filter(floorItem => floorItem.id === floor.id);
      if (currentFloor[0].rooms) {
        if (currentFloor.length && this.alertSettings[building.id][floor.id].length === currentFloor[0].rooms.length) {
          return false;
        } else if (
          currentFloor.length &&
          this.alertSettings[building.id][floor.id].length !== currentFloor[0].rooms.length
        ) {
          return true;
        }
      }
    }
    return false;
  }

  roomSearchChanged(roomSearchValue: string, buildingId: string) {
    this.uiHelper[buildingId].roomSearch = roomSearchValue;
  }

  floorSearchChanged(searchData: string, buildingId: string) {
    this.uiHelper[buildingId].floorSearch = searchData;
    this.uiHelper[buildingId].roomSearch = '';
    this.uiHelper[buildingId].floor = null;
  }
}
