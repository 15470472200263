import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COUNTRY_LIST, removeSpacesValidator } from '@app-lib';
import { openAddClientDialog } from '../add-client-dialog/add-client-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppState, LocationActions, locationErrorSelect } from '@ngrx-store';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocationData } from '@models';
import { AutocompleteAddress } from '@standalone/google-place-autocomplete/google-place-autocomplete.component';

@Component({
  selector: 'app-new-site-form',
  templateUrl: './new-site-form.component.html',
  styles: [],
})
export class NewSiteFormComponent implements OnChanges {
  @Input() isEditableMode = true;
  form: FormGroup;
  countryList = COUNTRY_LIST;

  constructor(private fb: FormBuilder, private dialog: MatDialog, private store: Store<AppState>, actions$: Actions) {
    this.form = this.fb.group({
      friendlyName: ['', [Validators.required, removeSpacesValidator]],
      clientFriendlyId: ['', [removeSpacesValidator]],
      address: this.fb.group({
        streetAddress: ['', [Validators.required]],
        city: ['', [Validators.required]],
        stateProvince: ['', [Validators.required]],
        postalCode: ['', [Validators.required]],
        country: ['US', [Validators.required]],
      }),
      geographic: this.fb.group({
        lat: [null, [Validators.required]],
        long: [null, [Validators.required]],
      }),
      tags: this.fb.group({
        ticketingBuildingId: ['', [removeSpacesValidator]],
      }),
    });

    this.store
      .select(locationErrorSelect)
      .pipe(takeUntilDestroyed())
      .subscribe(locationError => {
        if (locationError && locationError.message.includes('ClientFriendlyId')) {
          this.form.controls['clientFriendlyId'].setErrors({ alreadyExist: true });
        }
      });

    actions$
      .pipe(ofType(LocationActions.addLocationSuccess), take(1), takeUntilDestroyed())
      .subscribe((data: { newLocation: LocationData }) => {
        this.form.get('clientFriendlyId')?.setValue(data.newLocation.clientFriendlyId);
      });
  }

  get isValid(): boolean {
    return this.form.valid;
  }

  markTouchedFields() {
    this.form.markAllAsTouched();
  }

  addNewClientClick() {
    openAddClientDialog(this.dialog);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isEditableMode'].currentValue) {
      this.form.get('clientFriendlyId')?.disable();
    }
  }

  updateAddress($event: AutocompleteAddress) {
    this.form.get('address.streetAddress')?.setValue($event.address);
    this.form.get('address.city')?.setValue($event.city);
    this.form.get('address.stateProvince')?.setValue($event.state);
    this.form.get('address.postalCode')?.setValue($event.zipCode);
    this.form.get('geographic.lat')?.setValue($event.lat);
    this.form.get('geographic.long')?.setValue($event.lng);
  }
}
