import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityStatus, FloorSpaceModel, LocationFullData } from '@models';
import { AppState, locationState } from '@ngrx-store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-add-rooms',
  templateUrl: './add-rooms.component.html',
  styles: [],
})
export class AddRoomsComponent {
  locationFullData: LocationFullData | undefined;
  floors: FloorSpaceModel[] = [];
  floorSearch = '';
  openedFloorId: null | string = null;

  constructor(private store: Store<AppState>) {
    this.store
      .select(locationState)
      .pipe(takeUntilDestroyed())
      .subscribe(location => {
        this.locationFullData = location;
        this.floors = location ? location.floors.filter(({ status }) => status !== EntityStatus.Archived) : [];
      });
  }

  floorSearchChanged(searchData: string) {
    this.floorSearch = searchData;
  }

  floorItemClick(floorId: string) {
    this.openedFloorId = floorId === this.openedFloorId ? null : floorId;
  }
}
