import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { removeSpacesValidator } from '@app-lib';
import { RoomSpaceModel } from '@models';
import { AppState, isRoomUpdating, updateRoom } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-edit-room-dialog',
  templateUrl: './edit-room-dialog.component.html',
  styles: [],
})
export class EditRoomDialogComponent {
  form: FormGroup;
  isRoomUpdating$!: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { room: RoomSpaceModel; locationId: string },
    private fb: FormBuilder,
    private store: Store<AppState>
  ) {
    this.form = this.fb.group({
      friendlyName: [data.room.friendlyName, [Validators.required, removeSpacesValidator]],
      externalReference: [data.room.externalReference || '', [removeSpacesValidator]],
    });

    this.isRoomUpdating$ = this.store.select(isRoomUpdating);
  }

  submit() {
    const { locationId, room } = this.data;
    const friendlyName = this.form.value.friendlyName.trim();
    const externalReference = this.form.value.externalReference.trim();
    if (room && locationId) {
      this.store.dispatch(
        updateRoom({
          locationId: locationId,
          spaceId: room.id,
          data: {
            friendlyName,
            externalReference,
          },
        })
      );
    }
  }
}

export function openEditRoomDialog(dialog: MatDialog, room: RoomSpaceModel, locationId: string) {
  const config = new MatDialogConfig();
  config.disableClose = true;
  config.data = {
    room,
    locationId,
  };
  config.autoFocus = false;
  config.panelClass = 'app-dialog';
  config.backdropClass = 'backdrop-modal-panel';
  const dialogRef = dialog.open(EditRoomDialogComponent, config);
  return dialogRef.afterClosed();
}
