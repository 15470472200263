import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptySpacesBuildingPlan',
})
export class EmptySpacesBuildingPlanPipe implements PipeTransform {
  minSpaceCount = 40;

  transform(roomsCount = 0): number[] {
    if (this.minSpaceCount < roomsCount) return [];
    return Array.from(Array(this.minSpaceCount - roomsCount));
  }
}
