<mat-card-title class="card-title">Add Floors</mat-card-title>
<div class="border-b-ui-gray-light border-b flex justify-between items-center mb-3">
  <mat-button-toggle-group
    class="desktop-toggle-group"
    name="floor-status"
    aria-label="Floor Status"
    [value]="floorStatus"
    (change)="onFloorStatusFilterChange($event)">
    <mat-button-toggle value="current">
      <span class="item-status-title">Current</span>
    </mat-button-toggle>
    <mat-button-toggle value="archived">
      <span class="item-status-title">Archived</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <app-search-input
    placeholder="Search Floor"
    [inputValue]="floorSearch"
    (searchDataEvent)="floorSearchChanged($event)" />
</div>
<div class="border border-ui-gray-light" *ngIf="locationFullData">
  <p
    class="bg-ui-gray-50 border-b border-b-ui-gray-light font-nunito text-sm text-ui-gray-100 font-semibold h-8 flex px-3 mb-0 items-center">
    Floor
  </p>
  <form *ngIf="floorStatus !== 'archived'" [formGroup]="form" (ngSubmit)="submit()" class="flex px-3 pt-3">
    <mat-form-field class="input-classic size-small w-full mr-3">
      <input
        class="test-add-floor"
        formControlName="friendlyName"
        matInput
        placeholder="Enter floor friendly name"
        type="text" />
      <mat-error *ngIf="form.get('friendlyName')?.hasError('required')">Required</mat-error>
      <mat-error *ngIf="form.get('friendlyName')?.hasError('nameAlreadyExists')"> Name already exists</mat-error>
    </mat-form-field>
    <button
      type="submit"
      [disabled]="!form.valid || (floorIsLoading$ | async) || !form.value['friendlyName'].trim()"
      class="btn-green btn-small min-w-[112px]">
      <mat-icon *ngIf="(floorIsLoading$ | async) === false">add</mat-icon>
      <mat-spinner *ngIf="floorIsLoading$ | async" diameter="20" class="mr-1"></mat-spinner>
      Add Floor
    </button>
  </form>
  <ul class="flex flex-col-reverse">
    <li
      *ngFor="
        let floor of locationFullData.floors
          | filterSpaceByFriendlyName : floorSearch
          | filterSpaceByStatus : floorStatus
      ">
      <app-floor-item
        [floor]="floor"
        [locationId]="locationFullData.id"
        (editFloorEvent)="editFloor($event, floor.id)" />
    </li>
  </ul>
</div>
