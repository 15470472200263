<div class="test-header flex flex-row items-center pb-3">
  <div class="flex-grow">
    <ngx-skeleton-loader count="2" [theme]="{ width: '70%', display: 'block' }" />
  </div>
  <div class="flex">
    <ngx-skeleton-loader
      class="test-buttons"
      [count]="buttonCount"
      [theme]="{ width: '110px', height: '40px', marginLeft: '8px' }" />
  </div>
</div>
