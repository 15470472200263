import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel, MatSelect, MatSelectChange } from '@angular/material/select';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { PipesModule } from '@pipes';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  imports: [NgClass, MatFormField, MatSelect, MatOption, MatIcon, PipesModule, MatLabel, NgForOf, NgIf],
  standalone: true,
})
export class MultipleSelectComponent {
  @Input() sortingEnabled = true;
  @Input() label = '';
  @Input() selectClass = '';
  @Input() panelClass = '';
  @Input() showLabelPlaceholder = true;
  @Input() options: Array<{ title: string; value: string }> = [];
  @Input() showSelectedValues = true;
  @Output() selectDataEvent = new EventEmitter<Array<string>>();
  @ViewChild('selectRef') selectRef!: MatSelect;
  @Input() value: string[] = [];
  @Input() selectElementClass = '';

  selectionChange(change: MatSelectChange) {
    this.selectDataEvent.emit(change.value);
  }

  clearAll() {
    this.selectRef?.options.forEach((item: MatOption) => item.deselect());
  }

  selectAll() {
    this.selectRef?.options.forEach((item: MatOption) => item.select());
  }

  get labelDetails() {
    const selectedValues = this.value;
    const separator = `${this.showLabelPlaceholder ? ': ' : ''}`;
    let details = '';
    if (selectedValues?.length) {
      if (selectedValues.length === this.options.length) {
        details = `${separator}All`;
      } else {
        details = `${separator}${this.options.find(option => option.value === selectedValues[0])?.title} ${
          selectedValues.length > 1 ? `(+${selectedValues.length - 1})` : ''
        }`;
      }
    }

    return details;
  }

  get cssPanelClass() {
    return `multi-select-overlay ${this.panelClass}`;
  }
}
