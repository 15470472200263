<div *ngIf="room" class="room-popover relative p-4">
  <div class="test-hide m-1 flex cursor-pointer absolute top-0 right-0" (click)="hide()">
    <i class="icon-close text-ui-gray-100 text-base"></i>
  </div>

  <app-space-card-info
    [status]="room.status"
    [incidentNew]="room.incidentCountByStatuses.newCount"
    [incidentInProgress]="room.incidentCountByStatuses.inProgressCount"
    [spaceLink]="allowNavigation ? ['/dashboard', locationId, floorId, room.id] : []"
    [name]="room.friendlyName || room.name"
    [deviceActive]="room.devicesByStatuses.activeCount"
    [devicePaused]="room.devicesByStatuses.pausedCount" />
</div>
