<app-main-logo [smallVersion]="instructionsSent" [isMobile]="isMobile" />
<div *ngIf="!instructionsSent" class="test-form">
  <h1 class="text-center text-white my-10 font-poppins font-medium" [class]="isMobile ? 'text-2xl' : 'text-3xl'">
    Create new password
  </h1>
  <mat-card class="styled-form single-control-form mx-auto flex flex-col no-elevation">
    <div class="bg-white rounded-lg">
      <mat-card-content class="p-0 overflow-hidden">
        <form [formGroup]="form" (ngSubmit)="saveChanges()" class="login-form flex flex-col">
          <mat-form-field appearance="fill">
            <mat-icon class="text-ui-gray-light pl-6 text-xxl material-icons-outlined" matPrefix>lock</mat-icon>
            <mat-label>New Password</mat-label>
            <input matInput type="text" formControlName="password" class="pr-8" />
            <mat-error>Input is not valid</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-icon class="text-ui-gray-light pl-6 text-xxl material-icons-outlined" matPrefix>lock</mat-icon>
            <mat-label>Confirm Password</mat-label>
            <input matInput type="text" formControlName="confirmPassword" class="pr-8" />
            <mat-error>
              {{
              form.controls['confirmPassword'].errors?.['confirmedValidator']
                ? 'Passwords mismatch'
                : 'Input is not valid'
              }}
            </mat-error>
            <input type="submit" hidden="hidden" />
          </mat-form-field>
        </form>
      </mat-card-content>
    </div>

    <div class="mt-4 mb-6">
      <app-password-requirements [password]="form.controls['password'].value" />
    </div>

    <div class="text-center">
      <button
        class="test-submit-btn btn-yellow btn-large"
        [class.w-full]="isMobile"
        [disabled]="(isLoading$ | async) === true"
        (click)="saveChanges()">
        <mat-spinner matSuffix *ngIf="(isLoading$ | async) === true" class="mr-1" diameter="20"></mat-spinner>
        SAVE CHANGES
      </button>
    </div>
  </mat-card>
</div>

<div *ngIf="instructionsSent" class="text-center mt-20 test-instruction-sent">
  <svg
    class="inline-block mb-10"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M75 0.625C33.9277 0.625 0.625 33.9277 0.625 75C0.625 116.072 33.9277 149.375 75 149.375C116.072 149.375 149.375 116.072 149.375 75C149.375 33.9277 116.072 0.625 75 0.625ZM107.124 50.7119L72.1611 99.1885C71.6725 99.8705 71.0283 100.426 70.2819 100.81C69.5356 101.193 68.7087 101.393 67.8696 101.393C67.0306 101.393 66.2036 101.193 65.4573 100.81C64.711 100.426 64.0668 99.8705 63.5781 99.1885L42.876 70.501C42.2451 69.6211 42.876 68.3926 43.9551 68.3926H51.7412C53.4346 68.3926 55.0449 69.2061 56.041 70.6006L67.8613 87.0029L93.959 50.8115C94.9551 49.4336 96.5488 48.6035 98.2588 48.6035H106.045C107.124 48.6035 107.755 49.832 107.124 50.7119Z"
      fill="#ABC5C7" />
  </svg>
  <p class="mb-4 text-white font-medium font-poppins leading-8" [class]="isMobile ? 'text-2xl' : 'text-3xl'">
    Password changed <br />
    successfully!
  </p>
  <p
    class="mx-auto text-white font-nunito mb-10 font-medium leading-8"
    [class]="isMobile ? 'text-base w-[340px] px-4' : 'text-lg w-[380px]'">
    Your password has been changed successfully. Use your new password to sign in
  </p>
  <a class="btn-yellow btn-large" [class.w-[274px]]="isMobile" routerLink="">{{
    'back to sign in screen' | uppercase
  }}</a>
</div>
