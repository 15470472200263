import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './auth.reducers';
import { UserRolesIds, UserRolesTypes } from '@models';

export const selectAuthState = createFeatureSelector<UserState>('auth');

export const selectUser = createSelector(selectAuthState, auth => auth);

export const isLoggedIn = createSelector(selectAuthState, auth => !!auth.id);

export const isSuperAdmin = createSelector(selectAuthState, auth => auth.role.id === UserRolesIds.SuperAdmin);
export const userRole = createSelector(selectAuthState, auth => auth.role.id as UserRolesIds);
export const userRoleName = createSelector(selectAuthState, auth => auth.role.name as UserRolesTypes);
export const clientList = createSelector(selectAuthState, auth => auth.userClients);

export const getClient = (clientId: string) =>
  createSelector(selectAuthState, auth => auth.userClients?.find(({ id }) => id === clientId));

export const getMyClient = createSelector(selectAuthState, auth => {
  if (auth.userClients && auth.userClients.length > 1) {
    return auth.userClients.find(({ isManagingClient }) => isManagingClient);
  } else {
    return auth.userClients ? auth.userClients[0] : undefined;
  }
});

export const isLoggedOut = createSelector(isLoggedIn, loggedIn => !loggedIn);

export const isLoading = createSelector(selectAuthState, userState => userState.isLoading);
