import { IncidentNoteModel } from '@models';
import { usersMock } from './users';

export const notes: IncidentNoteModel[] = [
  {
    id: '1',
    textValue:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    timeStamp: new Date().toLocaleString(),
    source: 'api',
    document: null,
    user: usersMock[0],
  },
  {
    id: '2',
    textValue:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    timeStamp: new Date().toLocaleString(),
    source: 'api',
    document: null,
    user: usersMock[1],
  },
  {
    id: '3',
    textValue:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    timeStamp: new Date().toLocaleString(),
    source: 'api',
    document: null,
    user: usersMock[2],
  },
  {
    id: '4',
    textValue:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    timeStamp: new Date().toLocaleString(),
    source: 'api',
    document: null,
    user: usersMock[0],
  },
];
