import { Component, Input } from '@angular/core';
import { EntityStatus } from '@models';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-entity-status',
  template: ` <span
    class="status-block"
    [ngClass]="{
    'status-active': status === EntityStatus.Active,
    'status-paused': status === EntityStatus.Paused,
    'status-archived': status === EntityStatus.Archived,
    'text-base': fontSize === 'base',
    'text-xl': fontSize === 'big',
    'font-bold': fontWeight === 'bold',
    'font-medium': fontWeight === 'medium',
  }"
    >{{ status }}</span
  >`,
  standalone: true,
  styleUrls: ['./entity-status.component.scss'],
  imports: [NgClass],
})
export class EntityStatusComponent {
  protected readonly EntityStatus = EntityStatus;
  @Input() status: EntityStatus | undefined;
  @Input() fontSize: 'small' | 'base' | 'big' = 'small';
  @Input() fontWeight: 'bold' | 'semi-bold' | 'medium' = 'semi-bold';
}
