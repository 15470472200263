import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LocationLogsData } from '@models';

@Component({
  selector: 'app-site-history-table',
  templateUrl: './site-history-table.component.html',
  styles: [],
})
export class SiteHistoryTableComponent implements AfterViewInit, OnChanges {
  @Input() logs!: LocationLogsData[];
  displayedColumns: string[] = ['position', 'updated', 'location', 'status', 'user'];
  dataSource: MatTableDataSource<LocationLogsData> = new MatTableDataSource(this.logs);
  panelOpenState = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['logs']) {
      this.dataSource = new MatTableDataSource(this.logs);
    }
  }
}
