import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { emailValidationPattern, removeSpacesValidator } from '@app-lib';

@Component({
  selector: 'app-add-client-dialog',
  templateUrl: './add-client-dialog.component.html',
  styles: [],
})
export class AddClientDialogComponent {
  form: FormGroup;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<AddClientDialogComponent>) {
    this.form = this.fb.group({
      name: ['', [Validators.required, removeSpacesValidator]],
      email: ['', [Validators.required, Validators.pattern(emailValidationPattern)]],
      phone: ['', [Validators.required]],
      website: [''],
    });
  }

  submit() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}

export function openAddClientDialog(dialog: MatDialog) {
  const config = new MatDialogConfig();
  config.disableClose = true;
  config.panelClass = 'app-dialog';
  config.backdropClass = 'backdrop-modal-panel';
  const dialogRef = dialog.open(AddClientDialogComponent, config);
  return dialogRef.afterClosed();
}
