<app-main-logo [smallVersion]="true"></app-main-logo>
<div class="text-center" [class]="isMobile ? 'mx-auto w-[330px]' : ''">
  <div
    class="text-ui-status font-poppins font-bold leading-none"
    [class]="isMobile ? 'mt-[130px] text-[160px]' : 'mt-12 text-page-status'">
    404
  </div>
  <h1 class="text-white font-semibold font-poppins mt-10" [class]="isMobile ? 'text-2xl' : 'text-3xl'">
    Page not found
  </h1>
  <p class="text-white text-lg font-medium mt-4" [class]="isMobile ? 'text-base' : 'text-lg'">
    The requested URL was not found on this server
  </p>
  <button class="btn-yellow btn-large mt-10" [class.w-full]="isMobile" routerLink="/">BACK TO MAIN PAGE</button>
</div>
