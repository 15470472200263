<div
  class="font-nunito flex items-center"
  [class]="size === 'normal' ? 'text-sm font-normal' : 'text-xs font-semibold'">
  <span class="test-active-amount text-ui-green-light" [matTooltip]="showTooltip ? 'Active' : ''">{{
    activeAmount
  }}</span
  >&nbsp;<span class="text-ui-gray-dark"
    >|&nbsp;<span [matTooltip]="showTooltip ? 'Paused' : ''" class="test-paused-amount">{{ pausedAmount }}</span></span
  >
</div>
