import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, areLocationsLoaded } from '@ngrx-store';
import { Observable } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MapFilterService } from '@services';

@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styles: [],
})
export class DashboardMainComponent implements OnDestroy {
  locationsLoaded$: Observable<boolean>;

  constructor(private store: Store<AppState>, public mapFilterService: MapFilterService) {
    this.locationsLoaded$ = this.store.select(areLocationsLoaded);
  }

  ngOnDestroy(): void {
    this.mapFilterService.mapFilter = [];
  }

  mapEnabled = true;

  toggleMap(event: MatSlideToggleChange) {
    this.mapEnabled = event.checked;
  }

  mapFilterChange(value: string[]) {
    this.mapFilterService.mapFilter = value;
  }
}
