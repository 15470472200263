import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppService } from '@services';
import { userRole } from '@ngrx-store';
import { Subscription } from 'rxjs';
import { UserRolesIds } from '@models';

@Directive({
  selector: '[appRestrictedEl]',
})
export class RestrictedElementDirective implements OnDestroy, OnInit {
  subs!: Subscription;
  @Input() showForClientAdmin = false;

  constructor(private store: Store<AppService>, private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.subs = this.store.select(userRole).subscribe((role: UserRolesIds) => {
      if (role !== UserRolesIds.SuperAdmin) {
        if (role == UserRolesIds.ClientAdmin && this.showForClientAdmin) return;
        this.elementRef.nativeElement.style.setProperty('display', 'none', 'important');
      }
    });
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
