<div *ngIf="building; else loading">
  <a
    [routerLink]="['/dashboard', building.id]"
    class="inline-flex items-center font-nunito text-ui-dark text-sm font-semibold">
    <mat-icon>chevron_left</mat-icon>
    Back to {{ building.friendlyName }}
  </a>
  <app-page-header>Edit Building</app-page-header>
  <mat-card class="p-6 mt-3 shadow-card">
    <mat-card-title class="card-title pb-4">Building Info</mat-card-title>
    <mat-card-content class="p-0">
      <app-main-input-form
        label="Building name:*"
        controlName="friendlyName"
        formItemType="text"
        placeholder="Building name"
        [value]="building.friendlyName"
        (updateData)="onSiteChange($event)" />
      <app-main-input-form
        label="Ticketing building ID:"
        controlName="ticketingBuildingId"
        formItemType="text"
        placeholder="Enter ticketing building ID"
        [value]="building.tags['ticketingBuildingId']"
        [isRequired]="false"
        (updateData)="onSiteChange($event, 'tags')" />
    </mat-card-content>

    <mat-card-title class="card-title pb-4">Address</mat-card-title>
    <mat-card-content class="p-0">
      <div class="flex">
        <label class="input-main-label">Country:</label>
        <mat-form-field class="select-classic gray-bg has-validation">
          <mat-select
            [value]="building.address.country"
            (valueChange)="countryChange($event)"
            placeholder="Select country"
            panelClass="single-select-overlay">
            <mat-option *ngFor="let country of countryList | sort : 'asc' : 'name'" [value]="country.code">
              {{ country.name }}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix fontIcon="keyboard_arrow_down" class="arrow-down" />
          <mat-icon matSuffix class="arrow-up" fontIcon="keyboard_arrow_up" />
          <mat-error>Input is not valid</mat-error>
        </mat-form-field>
      </div>
      <div class="mb-5">
        <app-google-place-autocomplete
          (addressChanged)="updateAddress($event)"
          class="w-full"
          [coordinates]="{ lat: building.geographic.lat, lng: building.geographic.long }"
          [restrictionArea]="[building.address.country]" />
      </div>

      <div class="flex mb-5">
        <label class="input-main-label">City:</label>
        <div class="input-classic-simulation">
          {{ building.address.city }}
        </div>
      </div>
      <div class="flex mb-5">
        <label class="input-main-label">State:</label>
        <div class="input-classic-simulation">
          {{ building.address.stateProvince }}
        </div>
      </div>
      <app-main-input-form
        label="Street line:"
        controlName="streetAddress"
        formItemType="text"
        placeholder="Enter address"
        [value]="building.address.streetAddress"
        (updateData)="onSiteChange($event, 'address')" />
      <div class="flex mb-5">
        <label class="input-main-label">ZIP code:</label>
        <div class="input-classic-simulation">
          {{ building.address.postalCode }}
        </div>
      </div>
    </mat-card-content>

    <mat-card-title class="card-title pb-4">Geolocation</mat-card-title>
    <mat-card-content class="p-0">
      <div class="flex mb-5">
        <label class="input-main-label">Latitude:</label>
        <div class="input-classic-simulation">
          {{ building.geographic.lat }}
        </div>
      </div>
      <!-- <app-main-input-form
        [disabled]="true"
        label="Latitude:*"
        controlName="lat"
        formItemType="number"
        placeholder="Enter latitude value"
        [value]="building.geographic.lat"
        [min]="-90"
        [max]="90"
        (updateData)="onSiteChange($event, 'geographic')" /> -->
      <!-- <app-main-input-form
        [disabled]="true"
        label="Longitude:*"
        controlName="long"
        formItemType="number"
        placeholder="Enter longitude value"
        [value]="building.geographic.long"
        [min]="-180"
        [max]="180"
        (updateData)="onSiteChange($event, 'geographic')" /> -->
      <div class="flex mb-5">
        <label class="input-main-label">Longitude:</label>
        <div class="input-classic-simulation">
          {{ building.geographic.long }}
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="p-6 my-3 shadow-card">
    <mat-card-content class="p-0">
      <app-add-floors />
    </mat-card-content>
  </mat-card>
</div>

<ng-template #loading>
  <div class="text-center">
    <mat-spinner class="mx-auto" color="primary">LOADING</mat-spinner>
  </div>
</ng-template>
