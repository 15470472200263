<section class="text-center py-[80px]">
  <div class="mx-auto">
    <svg class="mx-auto" width="112" height="100" viewBox="0 0 112 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M99.2976 62.4301H52.7591L40.1367 47.4126H86.6752L99.2976 62.4301Z" fill="#F5F5F7" />
      <path d="M1.30762 62.4301H27.5139L40.1363 47.4126H13.93L1.30762 62.4301Z" fill="#F5F5F7" />
      <path d="M52.7591 62.4301L40.1367 47.4126V98.6888H86.6752V62.4301H52.7591Z" fill="#F5F5F7" />
      <path d="M27.5146 62.4301H13.9307V98.6888H40.137V47.4126L27.5146 62.4301Z" fill="#CED2D9" />
      <path
        d="M87.4087 44.4932L83.9297 41.0142C83.3353 41.7659 82.7059 42.5002 82.0066 43.1995C81.3073 43.8988 80.573 44.5281 79.8213 45.1225L83.3003 48.6016C82.8982 50.3498 83.3877 52.2729 84.7514 53.6365L100.416 69.3009C102.531 71.4163 105.975 71.4163 108.091 69.3009C110.206 67.1855 110.206 63.7414 108.091 61.6261L92.4262 45.9617C91.08 44.5631 89.1744 44.0911 87.4087 44.4932Z"
        fill="#CED2D9" />
      <path
        d="M64.6647 50.367C78.2111 50.367 89.1927 39.3854 89.1927 25.839C89.1927 12.2926 78.2111 1.31104 64.6647 1.31104C51.1183 1.31104 40.1367 12.2926 40.1367 25.839C40.1367 39.3854 51.1183 50.367 64.6647 50.367Z"
        fill="#F5F5F7" />
      <path
        d="M78.0038 12.5005C74.4373 8.93412 69.7171 6.97607 64.6646 6.97607C59.6122 6.97607 54.8919 8.93412 51.3429 12.5005C44.0003 19.8607 44.0003 31.8187 51.3429 39.1614C54.9094 42.7278 59.6296 44.6859 64.6646 44.6859C69.6996 44.6859 74.4373 42.7278 78.0038 39.1614C85.3464 31.8187 85.3464 19.8607 78.0038 12.5005ZM76.1506 37.3082C73.0912 40.3677 69.0178 42.0635 64.6821 42.0635C60.3464 42.0635 56.273 40.3677 53.2136 37.3082C46.8849 30.9796 46.8849 20.6824 53.2136 14.3537C56.273 11.2943 60.3464 9.59845 64.6821 9.59845C69.0178 9.59845 73.0912 11.2943 76.1506 14.3537C82.4793 20.6824 82.4793 30.9796 76.1506 37.3082Z"
        fill="#BEC8D2" />
      <path
        d="M109.035 60.6643L93.3707 45C91.9197 43.549 89.8917 42.8497 87.8638 43.0769L85.661 40.8741C92.8987 30.7867 92.0071 16.6259 82.9512 7.55245C72.8812 -2.51748 56.4826 -2.51748 46.3952 7.55245C36.3253 17.6224 36.3253 34.021 46.3952 44.1084C51.4302 49.1434 58.0561 51.6608 64.6645 51.6608C69.9442 51.6608 75.2239 50.0175 79.6994 46.8182L81.9022 49.021C81.6749 51.049 82.3568 53.0769 83.8253 54.528L99.4896 70.1923C100.801 71.5035 102.532 72.1678 104.262 72.1678C105.993 72.1678 107.724 71.5035 109.035 70.1923C111.657 67.5699 111.657 63.3042 109.035 60.6643ZM48.2484 42.2552C43.8603 37.8671 41.4477 32.0455 41.4477 25.8392C41.4477 19.6329 43.8603 13.8112 48.2484 9.42308C52.6365 5.03497 58.4582 2.62238 64.6645 2.62238C70.8708 2.62238 76.6924 5.03497 81.0805 9.42308C85.4687 13.8112 87.8812 19.6329 87.8812 25.8392C87.8812 32.0455 85.4687 37.8671 81.0805 42.2552C76.6924 46.6434 70.8708 49.0559 64.6645 49.0559C58.4582 49.0559 52.6365 46.6434 48.2484 42.2552ZM107.182 68.3566C105.574 69.965 102.969 69.965 101.36 68.3566L85.6959 52.6923C84.6994 51.6958 84.2973 50.2622 84.5945 48.8811C84.6994 48.4441 84.5596 47.9895 84.2449 47.6748L81.7624 45.1923C82.1645 44.8427 82.5666 44.5105 82.9337 44.1259C83.3183 43.7413 83.6505 43.3392 84.0001 42.9545L86.4826 45.4371C86.7973 45.7518 87.2519 45.8916 87.6889 45.7867C89.0875 45.472 90.5036 45.8741 91.5176 46.8881L107.182 62.5524C107.969 63.3392 108.388 64.3706 108.388 65.472C108.388 66.5734 107.969 67.5699 107.182 68.3566Z"
        fill="#BEC8D2" />
      <path
        d="M28.5106 63.2865L40.1364 49.4578L51.7623 63.2865C52.0071 63.5837 52.3742 63.7585 52.7588 63.7585H86.1679C86.8847 63.7585 87.4791 63.1641 87.4791 62.4473C87.4791 61.7305 86.8847 61.1361 86.1679 61.1361H53.3707L42.9511 48.741H44.1924C44.9092 48.741 45.5036 48.1466 45.5036 47.4298C45.5036 46.7131 44.9092 46.1187 44.1924 46.1187H13.9301C13.5455 46.1187 13.1784 46.2935 12.9336 46.5907L0.311253 61.6082C-0.0209147 61.9928 -0.0908446 62.5522 0.118946 63.0068C0.328736 63.4613 0.800764 63.7585 1.30776 63.7585H27.514C27.8987 63.741 28.2658 63.5662 28.5106 63.2865ZM4.12244 61.1187L14.542 48.7235H37.3217L26.9022 61.1187H4.12244Z"
        fill="#BEC8D2" />
      <path
        d="M60.0146 86.1187H46.3083C45.5915 86.1187 44.9971 86.7131 44.9971 87.4298C44.9971 88.1466 45.5915 88.741 46.3083 88.741H60.0146C60.7313 88.741 61.3257 88.1466 61.3257 87.4298C61.3257 86.7131 60.7313 86.1187 60.0146 86.1187Z"
        fill="#BEC8D2" />
      <path
        d="M60.0146 91.2065H46.3083C45.5915 91.2065 44.9971 91.8009 44.9971 92.5177C44.9971 93.2345 45.5915 93.8289 46.3083 93.8289H60.0146C60.7313 93.8289 61.3257 93.2345 61.3257 92.5177C61.3257 91.7835 60.7313 91.2065 60.0146 91.2065Z"
        fill="#BEC8D2" />
      <path
        d="M70.8352 19.6678C70.3282 19.1608 69.4891 19.1608 68.9821 19.6678L64.6639 23.986L60.3457 19.6678C59.8387 19.1608 58.9995 19.1608 58.4925 19.6678C57.9856 20.1748 57.9856 21.014 58.4925 21.521L62.8107 25.8392L58.4925 30.1574C57.9856 30.6643 57.9856 31.5035 58.4925 32.0105C58.7548 32.2727 59.087 32.3951 59.4191 32.3951C59.7513 32.3951 60.0835 32.2727 60.3457 32.0105L64.6639 27.6923L68.9821 32.0105C69.2443 32.2727 69.5765 32.3951 69.9086 32.3951C70.2408 32.3951 70.573 32.2727 70.8352 32.0105C71.3422 31.5035 71.3422 30.6643 70.8352 30.1574L66.517 25.8392L70.8352 21.521C71.3597 21.014 71.3597 20.1748 70.8352 19.6678Z"
        fill="#BEC8D2" />
      <path
        d="M86.6741 66.3634C85.9573 66.3634 85.3629 66.9578 85.3629 67.6746V97.3774H41.4468V55.5592C41.4468 54.8425 40.8524 54.248 40.1357 54.248C39.4189 54.248 38.8245 54.8425 38.8245 55.5592V97.3774H15.2405V67.6746C15.2405 66.9578 14.6461 66.3634 13.9294 66.3634C13.2126 66.3634 12.6182 66.9578 12.6182 67.6746V98.6886C12.6182 99.4054 13.2126 99.9998 13.9294 99.9998H40.1357H86.6741C87.3909 99.9998 87.9853 99.4054 87.9853 98.6886V67.6746C87.9853 66.9578 87.3909 66.3634 86.6741 66.3634Z"
        fill="#BEC8D2" />
    </svg>
  </div>
  <div class="text-base text-ui-gray-100 py-2">No Results</div>
</section>
