import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizedUser, ChangePassword, ResetPassword, UserModel } from '@models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(email: string, password: string): Observable<AuthorizedUser> {
    return this.http.post<AuthorizedUser>('user/authenticateUser', { email, password });
  }

  logout() {
    return this.http.get('user/logout');
  }

  forgotPassword(email: string) {
    return this.http.get('user/forgotPassword', { params: { email } });
  }

  passwordRecovery(data: ResetPassword): Observable<ResetPassword> {
    return this.http.post<ResetPassword>('user/resetPassword', data);
  }

  changePassword(data: ChangePassword) {
    return this.http.post<ChangePassword>('user/changePassword', data);
  }
}
