import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-main-logo',
  templateUrl: './main-logo.component.html',
  standalone: true,
  imports: [CommonModule],
  styles: [],
})
export class MainLogoComponent implements OnInit {
  @Input() isMobile = false;
  @Input() smallVersion = false;
  logoWidth = 185;
  logoHeight = 54;
  viewBox = `0,0 ${this.logoWidth} ${this.logoHeight}`;

  ngOnInit() {
    if (this.isMobile) {
      this.logoWidth = this.smallVersion ? 185 : 350;
    }

    this.logoWidth = this.smallVersion ? 185 : this.isMobile ? 300 : 350;
    this.logoHeight = this.smallVersion ? 30 : this.isMobile ? 40 : 54;
    this.viewBox = `0,0 ${this.logoWidth} ${this.logoHeight}`;
  }
}
