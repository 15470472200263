import { Component, Input } from '@angular/core';
import { IncidentStatus } from '@models';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-incident-status-count',
  template: `
    <span
      class="status-block"
      [matTooltip]="status ? incidentStatusTooltip[status] : ''"
      [ngClass]="{
    'status-new': status === incidentStatus.IN_QUEUE,
    'status-inProgress': status === incidentStatus.IN_PROGRESS,
    'status-resolved': status === incidentStatus.RESOLVED,}">
      {{ amount }}
    </span>
  `,
  styleUrls: ['./incident-status-count.component.scss'],
  imports: [MatTooltip, NgClass],
})
export class IncidentStatusCountComponent {
  @Input() status!: IncidentStatus;
  @Input() amount = 0;

  incidentStatus = IncidentStatus;

  incidentStatusTooltip = {
    [IncidentStatus.IN_QUEUE]: 'In queue',
    [IncidentStatus.IN_PROGRESS]: 'In progress',
    [IncidentStatus.RESOLVED]: 'Resolved',
  };
}
