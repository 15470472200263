import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { incidentColumnOptions } from '@app-lib';
import { IncidentModel } from '@models';
import { AppState, isIncidentLoaded, selectAllIncidents } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService, IncidentsFilterService, IncidentsService } from '@services';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-incidents-main-page',
  templateUrl: './incidents-main-page.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class IncidentsMainPageComponent implements OnDestroy {
  isIncidentLoaded$: Observable<boolean>;
  incidentsSubs$: Subscription | undefined;
  displayedColumns = incidentColumnOptions.map(option => option.value);
  openIncidents: IncidentModel[] = [];
  resolvedIncidents: IncidentModel[] = [];
  resolvedIncidentsLoading = false;

  constructor(
    private store: Store<AppState>,
    private incidentsService: IncidentsService,
    private appService: AppService,
    public incidentsFilterService: IncidentsFilterService
  ) {
    this.store
      .select(selectAllIncidents)
      .pipe(takeUntilDestroyed())
      .subscribe(incidents => {
        this.openIncidents = incidents;
        this.incidentsFilterService.initOpenIncidents(incidents);
      });
    this.incidentsFilterService.initFilterTableType({
      displayedColumns: this.displayedColumns,
    });
    this.isIncidentLoaded$ = this.store.select(isIncidentLoaded);

    this.incidentsFilterService.resolvedDates$
      .pipe(takeUntilDestroyed())
      .subscribe(value => this.getResolvedIncidents(value));
  }

  ngOnDestroy(): void {
    this.incidentsSubs$?.unsubscribe();
    this.incidentsFilterService.resetState();
  }

  getResolvedIncidents(dateRange: { startDateTime: string; endDateTime: string }) {
    if (dateRange.startDateTime && dateRange.startDateTime) {
      this.resolvedIncidentsLoading = true;
      this.incidentsSubs$ = this.incidentsService
        .getAllResolvedIncidents(this.appService.currentClient, dateRange)
        .subscribe({
          next: data => {
            this.incidentsFilterService.initResolvedIncidents(data);
            this.resolvedIncidentsLoading = false;
          },
          error: () => {
            this.resolvedIncidentsLoading = false;
            // TODO: show notification error
          },
        });
    }
  }
}
