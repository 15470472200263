import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ClientUser } from '@models';
import { createReducer, on } from '@ngrx/store';
import {
  addClientUser,
  clientUserCreated,
  clientUserError,
  clientUsersLoaded,
  resendUserInvitation,
  resendUserInvitationError,
  resendUserInvitationSuccess,
  resetClientUsers,
  updateUserClientSuccess,
} from './client-users.actions';

export interface ClientUserState extends EntityState<ClientUser> {
  allUsersLoaded: boolean;
  isLoading: boolean;
  isResendInvitationLoading: boolean;
}

export const clientUserAdapter = createEntityAdapter<ClientUser>({});

export const initialClientUserState = clientUserAdapter.getInitialState({
  allUsersLoaded: false,
  isLoading: false,
  isResendInvitationLoading: false,
});

export const clientUsersReducer = createReducer(
  initialClientUserState,

  on(clientUsersLoaded, (state, action) => {
    return clientUserAdapter.setAll(action.clientUsersList, { ...state, allUsersLoaded: true });
  }),

  on(clientUserCreated, (state, action) => {
    return clientUserAdapter.addOne(action.createdUser, { ...state, action, allUsersLoaded: true, isLoading: false });
  }),

  on(addClientUser, state => {
    return { ...state, isLoading: true };
  }),

  on(clientUserError, state => {
    return { ...state, isLoading: false };
  }),

  on(updateUserClientSuccess, (state, action: { clientUser: ClientUser }) => {
    return clientUserAdapter.updateOne(
      {
        id: action.clientUser.id,
        changes: action.clientUser,
      },
      { ...state }
    );
  }),

  on(resetClientUsers, () => {
    return initialClientUserState;
  }),

  on(resendUserInvitation, state => {
    return { ...state, isResendInvitationLoading: true };
  }),

  on(resendUserInvitationSuccess, state => {
    return { ...state, isResendInvitationLoading: false };
  }),

  on(resendUserInvitationError, state => {
    return { ...state, isResendInvitationLoading: false };
  })
);
