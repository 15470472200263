import { ActionReducer, MetaReducer } from '@ngrx/store';
import { AppState, logout } from './index';

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function (state, action) {
    if (action.type === logout.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = [clearState];
