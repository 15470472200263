<mat-card class="card">
  <app-personal-profile [isMobile]="isMobile" *ngIf="editor.id === user.id; else userContent" [user]="editor" />
  <ng-template #userContent>
    <div *ngIf="editor.role.name !== UserRolesTypes.ReadOnly; else readOnlyData">
      <div class="test-admin-content">
        <mat-card-title class="card-title-small pb-4">Basic Info</mat-card-title>
        <app-main-input-form
          label="First Name"
          controlName="firstName"
          formItemType="text"
          placeholder="First Name"
          [value]="user.firstName"
          (updateData)="updateUserData($event, user)" />
        <app-main-input-form
          label="Last Name"
          controlName="lastName"
          formItemType="text"
          placeholder="Last Name"
          [value]="user.lastName"
          (updateData)="updateUserData($event, user)" />

        <div [class.flex]="!isMobile" class="flex-row pb-6">
          <label class="input-main-label">User Status</label>
          <div class="font-bold border py-2 px-3 w-[404px] rounded bg-ui-gray-50" [class.w-full]="isMobile">
            <app-user-status [status]="user.status" fontSize="0.875rem" />
          </div>
        </div>

        <div appRestrictedEl [class.flex]="!isMobile" class="flex-row pb-6">
          <label for="status" class="input-main-label">Client Status</label>
          <mat-form-field class="select-classic" [class.w-full]="isMobile">
            <mat-select
              id="status"
              placeholder="Client Status"
              [disabled]="true"
              [value]="user.isDisabled ? 'inactive' : 'active'"
              class="text-ui-green-light font-extrabold"
              panelClass="single-select-overlay">
              <mat-option
                *ngFor="let option of userStatusOption"
                [value]="option.value"
                [ngClass]="!user.isDisabled ? 'text-ui-green-light' : 'bg-ui-gray-100'">
                {{ option.title.toUpperCase() }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="pb-4">
          <div *ngIf="currentClient$ | async as client" [class.flex]="!isMobile" class="flex-row">
            <label class="input-main-label">Role:</label>
            <mat-form-field class="select-classic" [class.w-full]="isMobile">
              <mat-select
                id="role"
                [value]="user.role.id"
                (valueChange)="roleUpdated($event, user)"
                placeholder="Select role"
                panelClass="single-select-overlay">
                <mat-option
                  *ngFor="let role of roleOptions[editor.role.name]"
                  [value]="role.id"
                  appSuperAdminRoleFilter
                  [isManagingClient]="client?.isManagingClient">
                  {{ role.name }}
                </mat-option>
              </mat-select>
              <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
              <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
            </mat-form-field>
          </div>
        </div>

        <mat-card-title class="card-title-small pb-4">Contact Info</mat-card-title>
        <app-main-input-form
          [disabled]="true"
          label="E-mail"
          controlName="email"
          formItemType="text"
          placeholder="E-mail"
          [value]="user.email"
          (updateData)="updateUserData($event, user)" />
        <app-main-input-form
          label="Phone number"
          [hasPrefix]="true"
          controlName="phoneNumber"
          formItemType="string"
          [value]="user.phone.number"
          placeholder="Phone number"
          [applyPhoneMask]="true"
          [phoneMask]="phoneMask()"
          [phoneMaskExpression]="phoneMaskExpression()"
          (updateData)="updatePhoneNumber($event, user)">
          <div class="flex justify-between">
            <mat-form-field class="input-classic max-w-[150px] mr-2">
              <mat-select
                (selectionChange)="updateCountryCode($event, user)"
                [value]="user.phone.countryCode || DEFAULT_COUNTRY.code">
                @for (country of COUNTRY_LIST | sort:'asc':'code'; track country.code) {
                <mat-option [value]="country.code">{{ country.code }} ({{ country.phoneCode }})</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </app-main-input-form>
      </div>
    </div>

    <ng-template #readOnlyData>
      <div class="test-read-only-content">
        <mat-card-title class="card-title-small pb-4">Basic Info</mat-card-title>
        <div [class.justify-between]="isMobile" class="flex flex-row">
          <label class="input-main-label">First Name</label>
          <div class="mt-2 font-bold">{{ user.firstName }}</div>
        </div>
        <div [class.justify-between]="isMobile" class="flex flex-row">
          <label class="input-main-label">Last Name</label>
          <div class="mt-2 font-bold">{{ user.lastName }}</div>
        </div>
        <div [class.justify-between]="isMobile" class="flex flex-row" *ngIf="user.status">
          <label class="input-main-label">User Status</label>
          <div class="mt-2 font-bold">
            <app-user-status [status]="user.status" />
          </div>
        </div>
        <div [class.justify-between]="isMobile" class="flex flex-row" *ngIf="user.role.name">
          <label class="input-main-label">Role</label>
          <div class="mt-2 font-bold">{{ RoleNames[user.role.name] }}</div>
        </div>
        <mat-card-title class="card-title-small py-4">Contact Info</mat-card-title>
        <div [class.justify-between]="isMobile" class="flex flex-row">
          <label class="input-main-label">Email</label>
          <div class="mt-2 font-bold">{{ user.email }}</div>
        </div>
        <div [class.justify-between]="isMobile" class="flex flex-row pb-6">
          <label class="input-main-label">Phone Number</label>
          <div class="mt-2 font-bold">{{ user.phone.number | mask : '(000) 000-0000' }}</div>
        </div>
      </div>
    </ng-template>

    <div [class.flex]="!isMobile" class="flex-row items-center my-6" appRestrictedEl [showForClientAdmin]="true">
      <mat-card-title [class.pb-4]="isMobile" class="card-title-small w-52 mr-6">Password</mat-card-title>
      <div
        [class.flex]="!isMobile"
        [class.w-full]="isMobile"
        class="classic-form-item-wrapper justify-between items-center">
        <button
          [disabled]="resetPasswordLoading"
          [class.w-full]="isMobile"
          class="btn-medium"
          [ngClass]="user.status === UserStatus.BLOCKED ? 'btn-red' : 'btn-yellow'"
          (click)="resetPassword(user)">
          <mat-spinner *ngIf="resetPasswordLoading" diameter="20" class="mr-1" />
          RESET PASSWORD
        </button>
        <span
          class="w-[190px] font-nunito text-[10px] text-ui-red-100 leading-3"
          *ngIf="user.status === UserStatus.BLOCKED"
          [ngClass]="{ 'block text-center w-full text-sm leading-5': isMobile }"
          >The user has reached the maximum permissible number of login attempts.</span
        >
      </div>
    </div>
    <div>
      <div class="flex items-center">
        <mat-card-title class="card-title-small mr-6 w-52">Notifications</mat-card-title>
        <mat-checkbox *ngIf="!isMobile" class="opt-in-checkbox -ml-2.5" [checked]="user.phone.smsOptIn" disabled="true">
          SMS Opt-in message:
        </mat-checkbox>
      </div>
      <div [ngClass]="isMobile ? 'pt-5' : 'flex flex-row ml-56 mb-2.5 w-[420px] px-2 justify-between'">
        <mat-slide-toggle
          class="test-mail-toggle main-toggle"
          labelPosition="before"
          [disabled]="editor.role.name === UserRolesTypes.ReadOnly"
          [checked]="user.notificationsPreferences.emailEnabled"
          (change)="toggleNotification($event, 'email', user)">
          <span>Email:</span> {{ user.notificationsPreferences.emailEnabled ? 'On' : 'Off' }}
        </mat-slide-toggle>
        <mat-checkbox
          *ngIf="isMobile"
          class="opt-in-checkbox -ml-2.5 mb-6"
          [checked]="user.phone.smsOptIn"
          disabled="true">
          SMS Opt-in message:
        </mat-checkbox>
        <mat-slide-toggle
          class="test-sms-toggle main-toggle"
          labelPosition="before"
          [disabled]="editor.role.name === UserRolesTypes.ReadOnly || !user.phone.smsOptIn"
          [checked]="user.notificationsPreferences.smsEnabled"
          (change)="toggleNotification($event, 'sms', user)">
          <span>SMS:</span> {{ user.notificationsPreferences.smsEnabled ? 'On' : 'Off' }}
        </mat-slide-toggle>
      </div>
    </div>
  </ng-template>
</mat-card>
