import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmedValidator, passwordValidationPattern } from '@app-lib';
import { Store } from '@ngrx/store';
import { AppState, isLoading, passwordRecovery } from '@ngrx-store';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { MainLogoComponent } from '@standalone/main-logo/main-logo.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatError, MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { PasswordRequirementsComponent } from '@standalone/password-requirements/password-requirements.component';
import { AsyncPipe, NgIf, UpperCasePipe } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatLabel } from '@angular/material/select';

@Component({
  standalone: true,
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  imports: [
    MainLogoComponent,
    MatCard,
    MatCardContent,
    ReactiveFormsModule,
    MatFormField,
    MatIcon,
    MatInput,
    PasswordRequirementsComponent,
    AsyncPipe,
    MatProgressSpinner,
    MatSuffix,
    MatPrefix,
    UpperCasePipe,
    MatError,
    MatLabel,
    RouterLink,
    NgIf,
  ],
})
export class PasswordRecoveryComponent {
  @Input() isMobile = false;
  form: FormGroup;
  instructionsSent = false;
  isLoading$: Observable<boolean | undefined>;

  constructor(private fb: FormBuilder, private store: Store<AppState>, private route: ActivatedRoute) {
    this.isLoading$ = this.store.select(isLoading);
    this.instructionsSent = route.snapshot.paramMap.get('state') === 'success';
    this.form = fb.group(
      {
        password: ['', [Validators.required, Validators.pattern(passwordValidationPattern)]],
        confirmPassword: ['', [Validators.required, Validators.pattern(passwordValidationPattern)]],
        token: [this.route.snapshot.queryParams['token'], [Validators.required]],
        userPolicyId: [this.route.snapshot.queryParams['policyId'], [Validators.required]],
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
      }
    );
  }

  saveChanges() {
    if (this.form.valid) {
      this.store.dispatch(passwordRecovery({ recoveryData: this.form.value }));
    } else {
      this.form.markAllAsTouched();
    }
  }
}
