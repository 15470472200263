import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIconButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { Store } from '@ngrx/store';
import { AppState, updateRoom } from '@ngrx-store';

@Component({
  selector: 'app-mobile-edit-external-link-modal',
  template: `
    <mat-card>
      <mat-card-header class="flex justify-between">
        <mat-card-title> Edit doc link </mat-card-title>
        <button mat-dialog-close>
          <i class="icon-close  text-ui-dark text-3xl"></i>
        </button>
      </mat-card-header>

      <mat-card-content class="pt-6">
        <mat-form-field class="input-with-clear-btn min-w-[300px]">
          <input
            #input
            [value]="inputValue"
            [(ngModel)]="inputValue"
            type="text"
            matInput
            placeholder="Enter external link" />
          <button
            *ngIf="input.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            class="test-clear-button"
            (click)="clear()">
            <i class="icon-close text-ui-dark text-3xl"></i>
          </button>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <mat-dialog-actions align="end" class="dialog-actions">
      <button mat-dialog-close class="test-btn-cancel btn-transparent btn-medium font-bold mr-4">CANCEL</button>
      <button type="submit" class="btn-green btn-medium font-bold" (click)="confirm()">SAVE</button>
    </mat-dialog-actions>
  `,
  standalone: true,
  styles: ``,
  imports: [
    MatDialogClose,
    MatFormField,
    MatIconButton,
    MatInput,
    MatPrefix,
    MatSuffix,
    NgIf,
    ReactiveFormsModule,
    NgClass,
    FormsModule,
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    MatDialogActions,
  ],
})
export class AddExternalLinkModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      locationId: string;
      link: string;
      spaceId: string;
    },
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<AddExternalLinkModalComponent>
  ) {}

  inputValue = '';

  clear() {
    this.inputValue = '';
  }

  confirm() {
    this.store.dispatch(
      updateRoom({
        locationId: this.data.locationId,
        spaceId: this.data.spaceId,
        data: { externalReference: this.inputValue },
      })
    );
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.inputValue = this.data?.link || '';
  }
}
