import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { removeSpacesValidator } from '@app-lib';
import { NotificationsService } from '@services';
import { buildingMock, floorMock, roomMock } from '@mocks';
import { WidgetModel } from '@models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-add-site-dialog',
  templateUrl: './add-site-dialog.component.html',
  styles: [],
})
export class AddSiteDialogComponent {
  form: FormGroup;
  buildingOptions = buildingMock;
  floorOptions = floorMock;
  roomOptions = roomMock;

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    public dialogRef: MatDialogRef<AddSiteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: WidgetModel
  ) {
    this.form = this.fb.group({
      name: [data?.name, [Validators.required, removeSpacesValidator]],
      buildingId: [data?.building?.id, [Validators.required]],
      floorId: [{ value: data?.floor?.id, disabled: !data }],
      roomId: [{ value: data?.room?.id, disabled: !data?.floor?.id }],
    });

    this.form
      .get('buildingId')
      ?.valueChanges.pipe(takeUntilDestroyed())
      .subscribe(value => {
        if (value) {
          this.form.get('floorId')?.enable();
        }
      });

    this.form
      .get('floorId')
      ?.valueChanges.pipe(takeUntilDestroyed())
      .subscribe(value => {
        if (value) {
          this.form.get('roomId')?.enable();
        }
      });
  }

  submit() {
    this.form.valid
      ? this.dialogRef.close(this.form.value)
      : this.notificationService.showErrorMessage('The form is not valid');
  }
}

export function openAddSiteDialog(dialog: MatDialog, data?: WidgetModel) {
  const config = new MatDialogConfig();
  config.data = data;
  config.disableClose = true;
  config.panelClass = 'app-dialog';
  config.backdropClass = 'backdrop-modal-panel';
  const dialogRef = dialog.open(AddSiteDialogComponent, config);
  return dialogRef.afterClosed();
}
