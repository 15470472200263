import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthService } from '@services';
import { AppState, login } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AuthorizedUser } from '@models';
import { emailValidationPattern } from '@app-lib';
import { NotificationsService } from '@services';
import { MainLogoComponent } from '@standalone/main-logo/main-logo.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { NgIf } from '@angular/common';
import { MatError, MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatLabel } from '@angular/material/select';

@Component({
  standalone: true,
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  imports: [
    MainLogoComponent,
    MatCard,
    NgIf,
    MatCardContent,
    ReactiveFormsModule,
    MatFormField,
    MatIcon,
    MatLabel,
    MatInput,
    MatError,
    RouterLink,
    MatProgressSpinner,
    MatPrefix,
    MatSuffix,
  ],
})
export class SignInComponent implements OnDestroy {
  @Input() isMobile = false;
  form: FormGroup;
  hide = true;
  isBlocked = false;
  isLoading = false;
  signInSubs$: Subscription | undefined;

  constructor(
    fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private store: Store<AppState>,
    private notificationService: NotificationsService,
    private route: ActivatedRoute
  ) {
    this.form = fb.group({
      email: ['', [Validators.required, Validators.pattern(emailValidationPattern)]],
      password: ['', [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    this.signInSubs$?.unsubscribe();
  }

  login() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const val = this.form.value;
    this.signInSubs$ = this.authService
      .login(val.email, val.password)
      .pipe(
        tap((user: AuthorizedUser) => {
          this.store.dispatch(login({ user }));
        })
      )
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || 'dashboard');
        },
        error: (error: { message: string; name: string; status: number }) => {
          this.isLoading = false;
          this.notificationService.showErrorMessage('Entered data is not correct');
          if (error.status === 403) {
            this.isBlocked = true;
          }
        },
      });
  }
}
