import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appSelectBlur]',
})
export class SelectBlurDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('selectionChange', ['$event'])
  onSelectionChange() {
    // requestAnimationFrame is designed to perform animations and other tasks related to redrawing the screen. It ensures that the code will be executed before the next DOM update.
    requestAnimationFrame(() => {
      this.elementRef.nativeElement.blur();
    });
  }
}
