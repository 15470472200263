import { ChildClient, UserClient, UserModel, UserRolesIds, UserRolesTypes, UserStatus } from '@models';

export const childClientMock: ChildClient[] = [
  {
    id: '0',
    friendlyName: 'string',
    isDisabled: false,
    clientName: 'client one',
    shortName: 'string',
    description: 'string',
    isArchived: false,
    createdOn: '2023-07-10T19:23:39.7910261Z',
    status: UserStatus.ACTIVE,
    isManagingClient: true,
    internalClientCode: 'string',
    tags: {
      phoneNumber: 'string',
      website: 'website',
    },
    clientUserSettings: {
      userSessionTimeoutMinutes: 30,
    },
    libraryAccessList: [
      {
        libraryId: 'string',
        ownerClientId: 'string',
        access: 'string',
      },
    ],
  },
  {
    id: '0',
    friendlyName: 'client 2',
    isDisabled: false,
    isArchived: false,
    clientName: 'string 2',
    shortName: 'string',
    description: 'string',
    createdOn: '2023-07-10T19:23:39.7910261Z',
    status: UserStatus.ACTIVE,
    isManagingClient: false,
    internalClientCode: 'string',
    tags: {
      phoneNumber: 'string',
      website: 'website',
    },
    clientUserSettings: {
      userSessionTimeoutMinutes: 30,
    },
    libraryAccessList: [
      {
        libraryId: 'string',
        ownerClientId: 'string',
        access: 'string',
      },
    ],
  },
  {
    id: '0',
    friendlyName: 'client 3',
    clientName: 'string 3',
    isDisabled: false,
    isArchived: false,
    clientUserSettings: {
      userSessionTimeoutMinutes: 30,
    },
    createdOn: '2023-07-10T19:23:39.7910261Z',
    shortName: 'string',
    description: 'string',
    status: UserStatus.ACTIVE,
    isManagingClient: true,
    internalClientCode: 'string',
    tags: {
      test: 'string',
    },
    libraryAccessList: [
      {
        libraryId: 'string',
        ownerClientId: 'string',
        access: 'string',
      },
    ],
  },
];

export const clientUsersMock: UserClient[] = [
  {
    id: '1',
    roleId: UserRolesIds.ClientAdmin,
    clientName: 'client 1',
    clientShortName: 'cl1',
    friendlyName: 'client user 1',
    description: 'client 1 description',
    isManagingClient: false,
  },
  {
    id: '2',
    roleId: UserRolesIds.ClientAdmin,
    clientName: 'client 2',
    clientShortName: 'cl2',
    friendlyName: 'client user 2',
    description: 'client 2 description',
    isManagingClient: true,
  },
  {
    id: '3',
    roleId: UserRolesIds.ClientAdmin,
    clientName: 'client 3',
    clientShortName: 'cl3',
    friendlyName: 'client user 3',
    description: 'client 3 description',
    isManagingClient: false,
  },
];
export const usersMock: UserModel[] = [
  {
    id: '1',
    firstName: 'Milan',
    lastName: 'Novakovich',
    role: {
      id: UserRolesIds.ClientAdmin,
      name: UserRolesTypes.ClientAdmin,
    },
    email: 'milan@example.com',
    phone: {
      countryCode: 'US',
      number: '21311321312321',
      countryNumberCode: '+1',
      smsOptIn: false,
    },
    lastActivity: '2022-12-27, 2:45:01',
    status: UserStatus.ACTIVE,
    organizationName: '',
    notificationsPreferences: {
      emailEnabled: true,
      smsEnabled: false,
    },
  },
  {
    id: '2',
    firstName: 'Floyd',
    lastName: 'Miles',
    role: {
      id: UserRolesIds.ClientAdmin,
      name: UserRolesTypes.ClientAdmin,
    },
    email: 'floyd@example.com',
    phone: {
      countryCode: 'US',
      number: '21311321312321',
      countryNumberCode: '+1',
      smsOptIn: false,
    },
    lastActivity: '2022-12-27, 2:45:01',
    status: UserStatus.INVITED,
    organizationName: '',
    notificationsPreferences: {
      emailEnabled: true,
      smsEnabled: false,
    },
  },
  {
    id: '3',
    firstName: 'Ronald',
    lastName: 'Richards',
    role: {
      id: UserRolesIds.ClientAdmin,
      name: UserRolesTypes.ClientAdmin,
    },
    email: 'richards@example.com',
    phone: {
      countryCode: 'US',
      number: '21311321312321',
      countryNumberCode: '+1',
      smsOptIn: false,
    },
    lastActivity: '2022-12-27, 2:45:01',
    status: UserStatus.INACTIVE,
    organizationName: '',
    notificationsPreferences: {
      emailEnabled: true,
      smsEnabled: false,
    },
  },
  {
    id: '4',
    firstName: 'Robert',
    lastName: 'Fox',
    role: {
      id: UserRolesIds.ClientAdmin,
      name: UserRolesTypes.ClientAdmin,
    },
    email: 'fox@example.com',
    phone: {
      countryCode: 'US',
      number: '21311321312321',
      countryNumberCode: '+1',
      smsOptIn: false,
    },
    lastActivity: '2022-12-27, 2:45:01',
    status: UserStatus.BLOCKED,
    organizationName: '',
    notificationsPreferences: {
      emailEnabled: true,
      smsEnabled: false,
    },
  },
  {
    id: '5',
    firstName: 'Courtney',
    lastName: 'Henry',
    role: {
      id: UserRolesIds.ClientAdmin,
      name: UserRolesTypes.ClientAdmin,
    },
    email: 'henry@example.com',
    phone: {
      countryCode: 'US',
      number: '21311321312321',
      countryNumberCode: '+1',
      smsOptIn: false,
    },
    lastActivity: '2022-12-27, 2:45:01',
    status: UserStatus.ARCHIVED,
    organizationName: '',
    notificationsPreferences: {
      emailEnabled: true,
      smsEnabled: false,
    },
  },
];
