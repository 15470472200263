<mat-card *ngIf="device" class="card w-[320px] top-0 sticky">
  <app-page-header>{{ device.friendlyName }}</app-page-header>
  <p
    class="font-nunito font-semibold text-base leading-[22px] text-ui-dark mt-2 mb-0"
    *ngIf="device.tags?.['description']">
    {{ device.tags?.['description'] }}
  </p>

  <div
    *ngIf="device.status !== EntityStatus.Archived && device.incidentCountByStatuses as incidents"
    class="test-incident-status my-3">
    <app-incident-status [status]="incidentStatus.IN_QUEUE" *ngIf="incidents.newCount" />
    <app-incident-status
      [status]="incidentStatus.IN_PROGRESS"
      *ngIf="!incidents.newCount && incidents.inProgressCount" />

    <span
      *ngIf="!incidents.newCount && !incidents.inProgressCount"
      class="inline-flex justify-center items-center h-6 min-w-[24px] capitalize text-xs px-2 rounded-xl text-white bg-ui-green-light">
      Good
    </span>
  </div>
  <div class="mb-3">
    <app-img-loader
      loader="skeleton"
      *ngIf="device.deviceModelInformation && device.deviceModelInformation.documentReference"
      altText="floorplan"
      errorSrc="assets/icons/image-error.svg"
      [imgSrc]="
        'clientId/' +
        clientId +
        '/library/standardMake/' +
        device.deviceModelInformation.make.id +
        '/standardModel/' +
        device.deviceModelInformation.id +
        '/document/' +
        device.deviceModelInformation.documentReference
      " />
    <img
      alt="Device Image"
      *ngIf="!device.deviceModelInformation?.documentReference"
      src="assets/icons/image-placeholder.svg" />
  </div>

  <ul>
    <li>
      <span>Device type:</span>
      {{ device.deviceModelInformation?.deviceType }}
    </li>

    <li *ngIf="device.deviceModelInformation?.make?.name" class="test-manufacturer">
      <span>Manufacturer:</span>
      {{ device.deviceModelInformation?.make?.name }}
    </li>

    <li class="test-model" *ngIf="device.deviceModelInformation?.name">
      <span>Model:</span> {{ device.deviceModelInformation?.name }}
    </li>

    <li class="test-serial-number" *ngIf="device.physicalDeviceId">
      <span>Serial №:</span> {{ device.physicalDeviceId }}
    </li>

    <li class="test-serial-number" *ngIf="device.tags?.['ipAddress']">
      <span>Ip Address:</span>
      @if (device.tags?.['ipAddress'] === "N/A") {
      {{ device.tags?.['ipAddress'] }}
      } @else {
      <a
        class="underline"
        [href]="'http://' + getDeviceUrl(device)"
        [matTooltip]="'http://' + getDeviceUrl(device)"
        matTooltipPosition="above"
        target="_blank">
        {{ device.tags?.['ipAddress'] }}
      </a>
      }
    </li>
    <li class="test-serial-number" *ngIf="device.tags?.['macAddress']">
      <span>Mac Address:</span>
      {{ device.tags?.['macAddress'] }}
    </li>

    <li class="test-firmware" *ngIf="device.tags?.['firmware']">
      <span>Firmware:</span>
      {{ device.tags?.['firmware'] }}
    </li>

    <li class="test-temperature" *ngIf="tempSensor">
      <div><span>Current temperature : </span> {{ tempSensor }} &deg;F</div>
    </li>

    <li class="test-reported-time" *ngIf="device.latestStreamingTimestamp">
      <div><span>Last reported : </span> {{ device.latestStreamingTimestamp | currentDate }}</div>
    </li>

    <li appRestrictedEl class="test-device-id break-all">
      <span>Device ID : </span> {{ device.id }}
      <button
        class="px-1 pt-1 bg-ui-gray-light text-white rounded cursor-pointer h-[30px] w-[30px] active:bg-ui-green-light align-middle"
        (click)="copiedToClipboard()"
        [cdkCopyToClipboard]="device.id">
        <mat-icon
          class="text-base cursor-pointer p-0 pt-0.5 text-ui-gray-dark active:text-white"
          fontIcon="content_copy" />
      </button>
    </li>

    <li class="test-add-properties-loading" *ngIf="!device.additionalAttributes">
      <ngx-skeleton-loader count="2" appearance="line" />
    </li>
  </ul>
</mat-card>
