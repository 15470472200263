import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { IncidentModel, IncidentNoteModel } from '@models';

@Injectable({
  providedIn: 'root',
})
export class IncidentsService {
  constructor(private http: HttpClient) {}

  getAllIncidents(clientId: string): Observable<IncidentModel[]> {
    return this.http
      .get<{ data: IncidentModel[] }>(`clientId/${clientId}/incidents/open`, {
        params: {
          deviceStatus: ['active', 'paused'],
        },
      })
      .pipe(map(data => data.data));
  }

  getAllResolvedIncidents(clientId: string, params: Record<string, string> = {}): Observable<IncidentModel[]> {
    return this.http
      .get<{ data: IncidentModel[] }>(`clientId/${clientId}/incidents/resolved`, { params })
      .pipe(map(data => data.data));
  }

  getIncidentById(clientId: string, incidentId: string): Observable<IncidentModel> {
    return this.http
      .get<{ data: IncidentModel }>(`clientId/${clientId}/incident/id/${incidentId}?includeDeviceInfo=true`)
      .pipe(map(data => data.data));
  }

  getIncidentsByPage(page: number, count: number): Observable<{ payload: IncidentModel[] }> {
    const queryParams = new HttpParams().append('page', page).append('count', count);
    return this.http.get<{ payload: IncidentModel[] }>('/api/incidents', {
      params: queryParams,
    });
  }

  getIncidentNotes(clientId: string, incidentId: string) {
    return this.http
      .get<{ data: IncidentNoteModel[] }>(`clientId/${clientId}/incident/id/${incidentId}/notes`)
      .pipe(map(data => data.data));
  }

  addIncidentNote(clientId: string, incidentId: string, body: { textValue: string }) {
    return this.http
      .post<{ data: IncidentNoteModel }>(`clientId/${clientId}/incident/id/${incidentId}/note`, body)
      .pipe(map(data => data.data));
  }
}
