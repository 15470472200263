import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { NewSiteFormComponent } from '../../components/new-site-form/new-site-form.component';
import { DeviceLocation, LocationData } from '@models';
import {
  addLocation,
  AppState,
  isLocationLoading,
  LocationActions,
  resetLocationState,
  updateLocation,
} from '@ngrx-store';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, take } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-add-new-site',
  templateUrl: './add-new-site.component.html',
  styles: [],
})
export class AddNewSiteComponent implements OnDestroy {
  @ViewChild(NewSiteFormComponent) siteDataForm!: NewSiteFormComponent;
  @ViewChild('stepper') stepper!: MatStepper;
  showAddDeviceInfo = false;
  deviceLocation: DeviceLocation | null = null;
  isLocationLoading$: Observable<boolean>;
  lastStepNumber = 3;
  selectedLocationId: string | null = null;
  locationSuccessSub$: Subscription;

  stepFinishCallback: { [key: number]: () => void } = {
    0: () => {
      if (!this.siteDataForm.isValid) {
        this.siteDataForm.markTouchedFields();
        return;
      }

      if (this.selectedLocationId) {
        this.updateLocation();
        return;
      }

      this.createLocation();
    },
  };

  constructor(private store: Store<AppState>, actions$: Actions, private route: ActivatedRoute) {
    this.store.dispatch(resetLocationState());
    this.isLocationLoading$ = this.store.pipe(select(isLocationLoading));

    actions$
      .pipe(ofType(LocationActions.addLocationSuccess), take(1), takeUntilDestroyed())
      .subscribe((data: { newLocation: LocationData }) => {
        this.selectedLocationId = data.newLocation.id;
        this.nextStepAction();
      });

    this.locationSuccessSub$ = actions$.pipe(ofType(LocationActions.updateLocationSuccess)).subscribe(() => {
      this.nextStepAction();
    });
  }

  ngOnDestroy() {
    this.locationSuccessSub$?.unsubscribe();
  }

  createLocation() {
    this.store.dispatch(
      addLocation({
        newLocation: this.siteDataForm.form.getRawValue(),
        clientId: this.route.snapshot.queryParams['clientId'],
      })
    );
  }

  updateLocation() {
    if (this.selectedLocationId) {
      this.store.dispatch(
        updateLocation({
          locationId: this.selectedLocationId,
          data: { ...this.siteDataForm.form.value },
          clientId: this.route.snapshot.queryParams['clientId'],
        })
      );
    }
  }

  goToNextStep() {
    if (this.stepper.selected && this.stepFinishCallback[this.stepper.selectedIndex]) {
      this.stepFinishCallback[this.stepper.selectedIndex]();
      return;
    }
    this.nextStepAction();
  }

  nextStepAction() {
    if (this.stepper.selected) {
      this.stepper.selected.completed = true;
      this.stepper.next();
    }
  }

  goToPrevStep() {
    this.stepper.previous();
  }

  toggleAddDeviceInfo(value: boolean) {
    this.showAddDeviceInfo = value;
  }

  addNewDeviceClick(data: DeviceLocation) {
    this.deviceLocation = data;
    this.toggleAddDeviceInfo(true);
  }

  stepChanged($event: StepperSelectionEvent) {
    if ($event.selectedIndex < $event.previouslySelectedIndex) {
      $event.previouslySelectedStep.interacted = false;
    }
  }
}
