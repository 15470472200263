import { Component, DestroyRef, inject, Input } from '@angular/core';
import { COUNTRY_LIST, DEFAULT_COUNTRY } from '@app-lib';
import { ClientUser, RoleNames, rolesRules, UserClient, UserRolesTypes, UserStatus } from '@models';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { DirectivesModule } from '@directives';
import { MatCard, MatCardTitle } from '@angular/material/card';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatOption } from '@angular/material/autocomplete';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NgxMaskPipe } from 'ngx-mask';
import { PipesModule } from '@pipes';
import { AppState, getClient, updateUserClient, UserState } from '@ngrx-store';
import { PhoneNumberClass } from '@abstraction';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppService, NotificationsService, UserManagementService } from '@services';
import { Observable } from 'rxjs';
import { UserStatusComponent } from '@standalone/user-status/user-status.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PersonalProfileComponent } from '@standalone/personal-pofile/personal-profile.component';
import { MainInputFormComponent } from '@standalone/main-input-form/main-input-form.component';

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [
    AsyncPipe,
    DirectivesModule,
    MatCard,
    MatCardTitle,
    MatFormField,
    MatIcon,
    MatOption,
    MatProgressSpinner,
    MatSelect,
    MatSlideToggle,
    MatSuffix,
    NgForOf,
    NgIf,
    NgxMaskPipe,
    PipesModule,
    NgClass,
    UserStatusComponent,
    MatCheckboxModule,
    PersonalProfileComponent,
    MainInputFormComponent,
  ],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss',
})
export class UserDetailsComponent extends PhoneNumberClass {
  readonly COUNTRY_LIST = COUNTRY_LIST;
  readonly DEFAULT_COUNTRY = DEFAULT_COUNTRY;
  readonly UserStatus = UserStatus;
  readonly UserRolesTypes = UserRolesTypes;
  readonly RoleNames = RoleNames;
  readonly roleOptions = rolesRules;
  @Input() user!: ClientUser;
  @Input() editor!: UserState;
  @Input() isMobile: boolean = false;
  resetPasswordLoading = false;
  destroyRef = inject(DestroyRef);
  currentClient$: Observable<UserClient | undefined>;
  userStatusOption = [
    { title: 'Active', value: 'active' },
    { title: 'Inactive', value: 'inactive' },
  ];

  constructor(
    private store: Store<AppState>,
    private appService: AppService,
    private notificationService: NotificationsService,
    private userManagementService: UserManagementService
  ) {
    super();
    this.currentClient$ = this.store.select(getClient(appService.currentClient));
  }

  updateCountryCode($event: MatSelectChange, user: ClientUser) {
    this.changeMaskSettings($event);
    this.store.dispatch(
      updateUserClient({
        clientUserId: user.id,
        clientUser: {
          phone: {
            countryCode: $event.value,
            number: user.phone.number,
            smsOptIn: user.phone.smsOptIn,
            countryNumberCode:
              COUNTRY_LIST.find(country => country.code === $event.value)?.phoneCode || DEFAULT_COUNTRY.phoneCode,
          },
        },
      })
    );
  }

  updatePhoneNumber($event: Record<string, string>, user: ClientUser) {
    this.store.dispatch(
      updateUserClient({
        clientUserId: user.id,
        clientUser: {
          phone: {
            countryCode: user.phone.countryCode,
            smsOptIn: user.phone.smsOptIn,
            number: Object.values($event)[0],
            countryNumberCode:
              COUNTRY_LIST.find(country => country.code === user.phone.countryCode)?.phoneCode ||
              DEFAULT_COUNTRY.phoneCode,
          },
        },
      })
    );
  }

  updateUserData(fieldData: Record<string, string>, user: ClientUser) {
    this.store.dispatch(updateUserClient({ clientUserId: user.id, clientUser: fieldData }));
  }

  toggleNotification(event: MatSlideToggleChange, type: 'sms' | 'email', user: ClientUser) {
    const notificationsPreferences = {
      emailEnabled: type === 'email' ? event.checked : user.notificationsPreferences.emailEnabled,
      smsEnabled: type === 'sms' ? event.checked : user.notificationsPreferences.smsEnabled,
    };

    this.store.dispatch(
      updateUserClient({
        clientUserId: user.id,
        clientUser: { notificationsPreferences },
      })
    );
  }

  roleUpdated($event: string, user: ClientUser) {
    this.store.dispatch(
      updateUserClient({
        clientUserId: user.id,
        clientUser: {
          roleId: $event,
        },
      })
    );
  }

  resetPassword(user: ClientUser) {
    this.resetPasswordLoading = true;
    this.userManagementService
      .resetPassword(this.appService.currentClient, user.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.resetPasswordLoading = false;
          this.notificationService.showSuccessMessage('Password was successfully reset');
        },
        error: () => {
          this.resetPasswordLoading = false;
          this.notificationService.showErrorMessage('Reset password error');
        },
      });
  }
}
