import * as Highcharts from 'highcharts';

export const deviceStatusChartData: Highcharts.Options = {
  chart: {
    plotShadow: false,
    type: 'pie',
  },
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '{point.name}<br/> {point.y}',
        style: {
          color: 'black',
        },
      },
    },
  },
  series: [
    {
      name: 'Devices',
      type: 'pie',
      data: [
        {
          name: 'GOOD',
          y: 60,
          color: '#14B8A6',
        },
        {
          name: 'OFFLINE',
          y: 15,
          color: '#737788',
        },
        {
          name: 'WARNING',
          y: 10,
          color: '#ffdc24',
        },
        {
          name: 'ALERT',
          y: 15,
          color: '#FE5A5A',
        },
      ],
    },
  ],
};

export const firmwareOutdatedChartData: Highcharts.Options = {
  chart: {
    plotShadow: false,
    type: 'pie',
  },
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '{point.name}<br/> {point.y}',
        style: {
          color: 'black',
        },
      },
    },
  },
  series: [
    {
      name: 'Outdated firmware',
      type: 'pie',
      data: [
        {
          name: 'Displays',
          y: 50,
          color: '#9E9E9E',
        },
        {
          name: 'AV Matrix Switchers',
          y: 15,
          color: '#3B82F6',
        },
        {
          name: 'Codecs',
          y: 8,
          color: '#6366F1',
        },
        {
          name: 'Network Cameras',
          y: 17,
          color: '#F59E0B',
        },
        {
          name: 'Room Navigators',
          y: 10,
          color: '#FFDC24',
        },
      ],
    },
  ],
};
