import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-password-requirements',
  templateUrl: './password-requirements.component.html',
  standalone: true,
  styleUrl: './password-requirements.component.scss',
})
export class PasswordRequirementsComponent implements OnChanges {
  @Input() password = '';
  @Input() successClassCSS = 'text-ui-green-450';
  @Input() isDarkMode = false;
  hasNumber = false;
  hasSpecialCharacter = false;
  hasLowercase = false;
  hasUppercase = false;
  hasMinLength = false;

  ngOnChanges(changes: SimpleChanges) {
    this.password = changes['password'].currentValue;
    this.hasNumber = this.passwordMatch(/\d+/);
    this.hasSpecialCharacter = this.passwordMatch(/[@$!%*#?&^_-]/);
    this.hasLowercase = this.passwordMatch(/[a-z]/);
    this.hasUppercase = this.passwordMatch(/[A-Z]/);
    this.hasMinLength = this.password.length >= 12;
  }

  passwordMatch(regex: RegExp) {
    return Boolean(this.password.match(regex));
  }
}
