<h1 class="dialog-title">
  <span>Change password</span>
  <button mat-dialog-close>
    <i class="icon-close"></i>
  </button>
</h1>
<form *ngIf="form" [formGroup]="form" (ngSubmit)="saveChanges()" class="change-password-popup">
  <mat-dialog-content class="dialog-content">
    <div class="flex flex-row">
      <label for="new-password" class="input-main-label">New password</label>
      <mat-form-field class="input-main">
        <input
          matInput
          id="new-password"
          [type]="hideInput.password ? 'password' : 'text'"
          formControlName="password" />
        <i
          class="text-ui-dark cursor-pointer text-3xl mr-2"
          [class]="hideInput.password ? 'icon-eye-open' : 'icon-eye-close'"
          matSuffix
          (click)="hideInput.password = !hideInput.password"></i>
        <mat-error>Form is not valid</mat-error>
      </mat-form-field>
    </div>
    <div class="flex flex-row">
      <label for="new-password-repeat" class="input-main-label">Confirm new password</label>
      <mat-form-field class="input-main">
        <input
          matInput
          id="new-password-repeat"
          [type]="hideInput.confirmPassword ? 'password' : 'text'"
          formControlName="confirmPassword" />
        <i
          class="text-ui-dark cursor-pointer text-3xl mr-2"
          [class]="hideInput.confirmPassword ? 'icon-eye-open' : 'icon-eye-close'"
          matSuffix
          (click)="hideInput.confirmPassword = !hideInput.confirmPassword"></i>
        <mat-error>
          {{
            form.controls['confirmPassword'].errors?.['confirmedValidator']
              ? 'Passwords mismatch'
              : 'Form is not valid'
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="w-[calc(100%-11rem)] ml-auto">
      <app-password-requirements [password]="form.controls['password'].value" [isDarkMode]="true" />
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-dialog-close class="test-btn-cancel btn-transparent btn-medium font-bold mr-4">CANCEL</button>
    <button
      type="submit"
      class="btn-green btn-medium font-bold"
      (click)="saveChanges()"
      [disabled]="(isLoading$ | async) === true">
      <mat-spinner *ngIf="(isLoading$ | async) === true" diameter="20" class="mr-1"></mat-spinner>
      SAVE
    </button>
  </mat-dialog-actions>
</form>
