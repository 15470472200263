import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ChildClient } from '@models';
import { createReducer, on } from '@ngrx/store';
import {
  addChildClient,
  addChildClientSuccess,
  clientManagementError,
  loadChildClientsSuccess,
  updateChildClientSuccess,
} from './clients-managements.actions';

export interface ChildClientState extends EntityState<ChildClient> {
  isLoading: boolean;
}

export const childClientAdapter = createEntityAdapter<ChildClient>({});

export const initialChildClientState = childClientAdapter.getInitialState({
  isLoading: false,
});

export const clientsManagementsReducer = createReducer(
  initialChildClientState,
  on(loadChildClientsSuccess, (state, action) => {
    return childClientAdapter.setAll(action.clientList, { ...state, isLoading: false });
  }),
  on(addChildClient, state => {
    return { ...state, isLoading: true };
  }),
  on(addChildClientSuccess, (state, action) =>
    childClientAdapter.addOne(action.newClient, { ...state, isLoading: false })
  ),
  on(updateChildClientSuccess, (state, action) => {
    return childClientAdapter.updateOne(
      {
        id: action.childClient.id,
        changes: action.childClient,
      },
      { ...state }
    );
  }),
  on(clientManagementError, state => {
    return { ...state, isLoading: false };
  })
);
