import { Injectable, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSelectChange } from '@angular/material/select';
import { defaultSelectOption, normalizeDeviceItem } from '@app-lib';
import { deviceColumnOptions } from '@mocks';
import {
  DeviceData,
  DeviceTableCols,
  EntityStatus,
  NormalizedDeviceData,
  SelectExtendedOption,
  SelectOption,
} from '@models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DevicesFilterService {
  public filtersApplied = signal(false);
  private _filteredDevices = new BehaviorSubject<NormalizedDeviceData[]>([]);
  private defaultColumnsValue: DeviceTableCols[] = [DeviceTableCols.STATUS, DeviceTableCols.BUILDING];
  filteredDevices$: Observable<NormalizedDeviceData[]> = this._filteredDevices.asObservable();
  displayedColumns = this.defaultColumnsValue;
  dataLoading = false;
  selectEnabled = false;
  filtersEnabled = true;
  showLocationFilter = true;
  isReportMode = false;

  columnSelect = new FormControl();
  private _selectedBuilding = '';
  private _selectedFloor = '';
  private _selectedRoom = '';

  normalizedDeviceList: NormalizedDeviceData[] = [];

  // Filters List
  deviceStatusFilter: 'current' | 'archived' = 'current';
  manufacturerOptions: SelectExtendedOption[] = [];
  deviceTypeOptions: SelectExtendedOption[] = [];
  buildingOptions: SelectOption[] = [];
  floorOptions: SelectOption[] = [];
  roomOptions: SelectOption[] = [];
  globalTextFilter = '';
  columnOptions = deviceColumnOptions;
  entityStatus = EntityStatus;

  get filteredDevices() {
    return this._filteredDevices.value;
  }

  get deviceTypeValue() {
    return this.deviceTypeOptions.filter(({ checked }) => checked).map(({ value }) => value);
  }

  set deviceTypeValue(filter: string[]) {
    this.deviceTypeOptions = this.deviceTypeOptions.map(option => {
      option.checked = filter.includes(option.value);
      return option;
    });
    this.filterDevices();
  }

  updateDeviceTypeValue(filter: { value: string; checked: boolean }) {
    this.deviceTypeOptions = this.deviceTypeOptions.map(option => {
      if (option.value === filter.value) {
        option.checked = filter.checked;
      }
      return option;
    });
    this.filterDevices();
  }

  get manufacturerValue() {
    return this.manufacturerOptions.filter(({ checked }) => checked).map(({ value }) => value);
  }

  set manufacturerValue(filter: string[]) {
    this.manufacturerOptions = this.manufacturerOptions.map(option => {
      option.checked = filter.includes(option.value);
      return option;
    });
    this.filterDevices();
  }

  updateManufacturerValue(filter: { value: string; checked: boolean }) {
    this.manufacturerOptions = this.manufacturerOptions.map(option => {
      if (option.value === filter.value) {
        option.checked = filter.checked;
      }
      return option;
    });
    this.filterDevices();
  }

  initDevices(devices: DeviceData[]) {
    this.normalizedDeviceList = devices.map(device => normalizeDeviceItem(device));
    this.collectFilterData();
    this.filterDevices();
  }

  initColumns(columns: DeviceTableCols[]) {
    this.columnSelect.setValue(columns);
    this.displayedColumns = this.generateTableColumns(columns);
  }

  filterDevices() {
    const isArchived = EntityStatus.Archived;
    const filteredDevices = this.normalizedDeviceList.filter(device => {
      const textMatch = this.textMatches(device);
      const deviceTypeMatch = this.matchesDeviceType(device);
      const manufacturerMatch = this.matchesManufacturer(device);
      const buildingMatch = this.matchesBuilding(device);
      const floorMatch = this.matchesFloor(device);
      const roomMatch = this.matchesRoom(device);
      const commonMatch =
        device.status !== isArchived &&
        textMatch &&
        deviceTypeMatch &&
        manufacturerMatch &&
        buildingMatch &&
        floorMatch &&
        roomMatch;
      this.applyFiltersStatus();
      return this.deviceStatusFilter === isArchived ? device.status === isArchived : commonMatch;
    });
    this._filteredDevices.next(filteredDevices);
  }

  private textMatches(device: any) {
    if (!this.globalTextFilter) return true;
    const concatenatedDeviceProperties =
      device.id +
      device.deviceType +
      device.building +
      device.manufacturer +
      device.floor +
      device.room +
      device.deviceName +
      device.serialNumber +
      device.model;
    return concatenatedDeviceProperties.toLowerCase().includes(this.globalTextFilter.toLowerCase());
  }

  private matchesDeviceType(device: any) {
    return !this.deviceTypeValue.length || this.deviceTypeValue.includes(device.deviceType);
  }

  private matchesManufacturer(device: any) {
    return !this.manufacturerValue.length || this.manufacturerValue.includes(device.manufacturer);
  }

  private matchesBuilding(device: any) {
    return !this.selectedBuilding || device.building.toLowerCase().includes(this.selectedBuilding.toLowerCase());
  }

  private matchesFloor(device: any) {
    return !this.selectedFloor || device.floor.toLowerCase().includes(this.selectedFloor.toLowerCase());
  }

  private matchesRoom(device: any) {
    return !this.selectedRoom || device.room.toLowerCase().includes(this.selectedRoom.toLowerCase());
  }

  collectFilterData() {
    const manufacturersFilter = new Set<string>([]);
    const deviceTypesFilter = new Set<string>([]);
    const buildingFilter = new Set<string>([]);

    this.normalizedDeviceList.forEach(device => {
      device.manufacturer && manufacturersFilter.add(device.manufacturer);
      device.deviceType && deviceTypesFilter.add(device.deviceType);
      device.building && device.status !== EntityStatus.Archived && buildingFilter.add(device.building);
    });

    this.manufacturerOptions = [...manufacturersFilter].map(item => {
      return { title: item, value: item, checked: false };
    });
    this.deviceTypeOptions = [...deviceTypesFilter].map(item => {
      return { title: item, value: item, checked: false };
    });

    if (this.showLocationFilter) {
      const buildingFilterOptions = [...buildingFilter].map(item => ({ title: item, value: item }));
      this.buildingOptions = [defaultSelectOption, ...buildingFilterOptions];
    }
  }

  deviceSearch(value: string) {
    this.globalTextFilter = value.trim().toLowerCase();
    this.filterDevices();
  }

  generateTableColumns(values: DeviceTableCols[] = []): DeviceTableCols[] {
    const columns = [
      this.selectEnabled ? DeviceTableCols.SELECT : DeviceTableCols.INDEX,
      DeviceTableCols.DEVICE_NAME,
      ...values,
    ];
    if (!this.isReportMode) {
      columns.push(DeviceTableCols.ACTIONS);
    }
    return columns;
  }

  columnsChange(event: MatSelectChange) {
    this.displayedColumns = this.generateTableColumns(event.value);
  }

  get selectedFilters(): boolean {
    return Boolean(this.deviceTypeValue.length || this.manufacturerValue.length || this.selectedBuilding);
  }

  clearAllFilters() {
    this.selectedRoom = '';
    this.selectedFloor = '';
    this.selectedBuilding = '';
    this.deviceTypeValue = [];
    this.manufacturerValue = [];
    this.globalTextFilter = '';
    this.filterDevices();
  }

  private applyFiltersStatus() {
    this.filtersApplied.set(
      !!(
        this.selectedRoom.length ||
        this.selectedFloor.length ||
        this.selectedBuilding.length ||
        this.deviceTypeValue.length ||
        this.manufacturerValue.length ||
        this.globalTextFilter
      )
    );
  }

  onDeviceStatusFilterChange(change: MatButtonToggleChange) {
    this.deviceStatusFilter = change.value;
    if (this.deviceStatusFilter == 'archived') {
      this.filtersEnabled = false;
      this.clearAllFilters();
    } else {
      this.filtersEnabled = true;
    }
    this.filterDevices();
  }

  get selectedBuilding() {
    return this._selectedBuilding;
  }

  set selectedBuilding(building: string) {
    this._selectedBuilding = building;
    this.selectedFloor = '';
    this.selectedRoom = '';

    const floorListOptions = this.normalizedDeviceList
      .filter(device => device.building === building)
      .map(device => device.floor);

    this.floorOptions = [
      defaultSelectOption,
      ...[...new Set(floorListOptions)].map(item => {
        return { title: item, value: item };
      }),
    ];
    this.filterDevices();
  }

  get selectedFloor() {
    return this._selectedFloor;
  }

  set selectedFloor(floor: string) {
    this._selectedFloor = floor;
    this.selectedRoom = '';
    const roomListOptions = this.normalizedDeviceList
      .filter(device => device.floor === floor && device.building === this.selectedBuilding)
      .map(device => device.room);

    this.roomOptions = [
      defaultSelectOption,
      ...[...new Set(roomListOptions)].map(item => {
        return { title: item, value: item };
      }),
    ];
    this.filterDevices();
  }

  get selectedRoom() {
    return this._selectedRoom;
  }

  set selectedRoom(room: string) {
    this._selectedRoom = room;
    this.filterDevices();
  }

  resetState() {
    this.normalizedDeviceList = [];
    this._filteredDevices.next([]);
    this.displayedColumns = this.defaultColumnsValue;
    this.dataLoading = false;
    this.selectEnabled = false;
    this.filtersEnabled = true;
    this.showLocationFilter = true;
    this.isReportMode = false;

    this.deviceStatusFilter = 'current';
    this.globalTextFilter = '';
    this.deviceTypeValue = [];
    this.manufacturerValue = [];
    this.selectedBuilding = '';
    this.selectedFloor = '';
    this.selectedRoom = '';

    this.manufacturerOptions = [];
    this.deviceTypeOptions = [];
    this.buildingOptions = [];
    this.floorOptions = [];
    this.roomOptions = [];
  }
}
