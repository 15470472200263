<h1 class="dialog-title">
  <span class="test-title">{{ data.title }}</span>
  <button mat-dialog-close>
    <i class="icon-close"></i>
  </button>
</h1>
<mat-dialog-content class="dialog-content test-description">
  <p
    class="font-poppins text-ui-gray-100"
    [class]="appService.isMobile ? 'text-base font-medium' : 'text-xl font-semibold text-center'">
    {{ data.description }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog-actions">
  <button mat-dialog-close class="test-btn-cancel btn-transparent btn-medium font-bold mr-4">NO</button>
  <button type="submit" class="btn-green btn-medium font-bold" (click)="confirm()">YES</button>
</mat-dialog-actions>
