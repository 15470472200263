import { UserRolesTypes, UserStatus } from './users';

export interface ClientUser {
  id: string;
  status: UserStatus;
  isArchived: boolean;
  isDisabled: boolean;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  roleId?: string;
  lastLogin?: string;
  role: {
    id: string;
    name: UserRolesTypes;
  };
  phone: {
    countryCode: string;
    number: string;
    countryNumberCode: string;
    smsOptIn: boolean;
  };
  notificationsPreferences: {
    emailEnabled: boolean;
    smsEnabled: boolean;
  };
  userPasswordPolicyId: '8009b138-6a61-4e12-85cc-7842b110acda';
}

export interface ChildClient {
  id: string;
  clientName: string;
  isArchived: boolean;
  isDisabled: boolean;
  friendlyName: string;
  shortName: string;
  description: string | null;
  status: UserStatus;
  isManagingClient: boolean;
  internalClientCode: string;
  createdOn: string;
  tags: {
    [key: string]: string;
  };
  clientUserSettings: {
    userSessionTimeoutMinutes: number;
  };
  libraryAccessList: [
    {
      libraryId: string;
      ownerClientId: string;
      access: string;
    }
  ];
}
