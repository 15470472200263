import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EntityStatus, FloorSpaceModel, LocationFullData, RoomSpaceModel, SpaceStats } from '@models';

@Component({
  selector: 'app-building-plan-visual-mode',
  templateUrl: './building-plan-visual-mode.component.html',
  styleUrls: ['./building-plan-visual-mode.component.scss'],
})
export class BuildingPlanVisualModeComponent implements OnChanges {
  protected readonly EntityStatus = EntityStatus;
  @Input() locationData!: LocationFullData;
  @Input() buildingState!: SpaceStats;
  floorSearch = '';
  showArchived = false;
  floorsWithSortedRooms: { [key: string]: RoomSpaceModel[] } = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes['locationData']) {
      this.updateRoomStateData();
    }
  }

  updateRoomStateData() {
    this.locationData.floors.forEach(floor => {
      this.generateRoomStateData(floor);
    });
  }

  generateRoomStateData(floor: FloorSpaceModel) {
    const sortingRes: { [key: string]: RoomSpaceModel[] } = {
      alert: [],
      inProgress: [],
      good: [],
      paused: [],
    };
    if (floor.rooms) {
      floor.rooms.forEach(room => {
        if (room.status) {
          if (room.status === EntityStatus.Paused) {
            sortingRes['paused'].push(room);
            return;
          }
          if (room.status === EntityStatus.Active && room.incidentCountByStatuses.newCount) {
            sortingRes['alert'].push(room);
            return;
          }
          if (
            room.status === EntityStatus.Active &&
            !room.incidentCountByStatuses.newCount &&
            room.incidentCountByStatuses.inProgressCount
          ) {
            sortingRes['inProgress'].push(room);
            return;
          }

          sortingRes['good'].push(room);
        }
      });
    }

    this.floorsWithSortedRooms[floor.id] = [
      ...sortingRes['alert'],
      ...sortingRes['inProgress'],
      ...sortingRes['good'],
      ...sortingRes['paused'],
    ];
  }

  searchUpdate(searchValue: string) {
    this.floorSearch = searchValue;
  }
}
