<h4
  *ngIf="device"
  class="font-poppins font-semibold text-base whitespace-nowrap overflow-hidden text-ellipsis flex items-center p-4 min-w-[247px]"
  [class.h-6]="device.status === entityStatus.Archived">
  <i class="icon-add-to-archive text-ui-gray-100 text-3xl" *ngIf="device.status === entityStatus.Archived"></i>
  <i class="icon-offline text-ui-gray-100 text-3xl" *ngIf="device.status === entityStatus.Paused"></i>
  <ng-container *ngIf="device.status === entityStatus.Active">
    <i
      class="icon-CheckCircleRounded text-ui-green-light text-3xl"
      *ngIf="!device.incidentCountByStatuses.newCount && !device.incidentCountByStatuses.inProgressCount">
      <span class="path1"></span><span class="path2"></span>
    </i>
    <i class="icon-alert text-ui-red-50 text-3xl" *ngIf="device.incidentCountByStatuses.newCount > 0"></i>
    <i
      class="icon-warning text-ui-yellow-100 text-3xl"
      *ngIf="device.incidentCountByStatuses.inProgressCount > 0 && !device.incidentCountByStatuses.newCount"></i>
  </ng-container>
  <a
    class="hover:underline cursor-pointer inline-flex ml-1.5 text-sm"
    [routerLink]="['/devices', locationId, device.id]"
    >{{ device.friendlyName }}</a
  >
</h4>
