import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppState, forgotPassword, isLoading } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { emailValidationPattern } from '@app-lib';
import { Observable } from 'rxjs';
import { MainLogoComponent } from '@standalone/main-logo/main-logo.component';
import { AsyncPipe, NgIf, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatError, MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatInput } from '@angular/material/input';
import { MatLabel } from '@angular/material/select';

@Component({
  standalone: true,
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: [],
  imports: [
    MainLogoComponent,
    NgIf,
    MatCard,
    MatCardContent,
    MatFormField,
    ReactiveFormsModule,
    MatIcon,
    AsyncPipe,
    MatSuffix,
    MatProgressSpinner,
    UpperCasePipe,
    MatInput,
    MatPrefix,
    MatLabel,
    MatError,
    RouterLink,
    NgTemplateOutlet,
  ],
})
export class ForgotPasswordComponent {
  @Input() isMobile = false;
  isLoading$: Observable<boolean | undefined>;
  form: FormGroup;
  instructionsSent = false;

  constructor(fb: FormBuilder, private store: Store<AppState>, private route: ActivatedRoute) {
    this.instructionsSent = route.snapshot.paramMap.get('state') === 'success';
    this.form = fb.group({
      email: new FormControl(
        {
          value: '',
          disabled: false,
        },
        [Validators.required, Validators.pattern(emailValidationPattern)]
      ),
    });
    this.isLoading$ = this.store.select(isLoading);
  }

  sendEmail() {
    if (this.form.valid) {
      this.store.dispatch(forgotPassword({ email: this.form.value.email }));
    } else {
      this.form.markAllAsTouched();
    }
  }
}
