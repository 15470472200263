import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { mockDeviceData } from '@mocks';
import { DeviceData, DeviceLocation, FloorSpaceModel } from '@models';

@Component({
  selector: 'app-attach-device-item',
  templateUrl: './attach-device-item.component.html',
  styleUrls: ['./attach-device-item.component.scss'],
})
export class AttachDeviceItemComponent {
  @Input() floor!: FloorSpaceModel;
  @Input() openedFloorId: string | null = null;
  @Output() clickEvent = new EventEmitter<string>();
  @Output() addNewDevice = new EventEmitter<DeviceLocation>();
  deviceOptions = mockDeviceData.map(({ id, friendlyName }) => ({ title: friendlyName, value: id }));
  selectedDevice = new FormControl();

  selectedRoomId: string | null = null;

  titleClick() {
    this.clickEvent.emit(this.floor.id);
    if (!this.selectedRoomId && this.floor.rooms?.length) {
      this.selectedRoomId = this.floor.rooms[0].id;
    }
  }

  selectRoom(roomId: string | undefined) {
    if (roomId) this.selectedRoomId = this.selectedRoomId === roomId ? null : roomId;
  }

  attachDevice() {
    const device = mockDeviceData.find(({ id }) => id === this.selectedDevice.value);
    console.log(device);
    // TODO need to fix when the device api will be implemented with correct types
    // if (device) {
    //   this.floor.rooms?.find(({ id }) => id === this.selectedRoomId)?.devices?.push(device);
    // }
    this.selectedDevice.reset();
  }

  deleteDevice(deviceId: string) {
    // TODO: replace this lines with appropriate API method
    const selectedRoom = this.floor.rooms?.find(({ id }) => id === this.selectedRoomId);
    if (selectedRoom) {
      selectedRoom.devices = selectedRoom.devices?.filter(({ id }) => id !== deviceId);
    }
  }

  addNewDeviceClick() {
    if (this.selectedRoomId) {
      // TODO: bing method to api when it is ready
    }
  }

  get roomsPlaceholder() {
    return this.floor.rooms?.length;
  }

  get devices(): DeviceData[] | undefined {
    return this.floor.rooms?.find(({ id }) => id === this.selectedRoomId)?.devices;
  }
}
