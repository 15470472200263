import { Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { AppState, deleteFloorplan, deleteRoomplan, isSuperAdmin } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-delete-spaceplan',
  templateUrl: './delete-spaceplan.component.html',
  imports: [MatIcon, AsyncPipe, NgIf],
  standalone: true,
})
export class DeleteSpaceplanComponent {
  @Input() documentReference = '';
  @Input() locationId = '';
  @Input() floorId = '';
  @Input() roomId = '';
  destroyRef = inject(DestroyRef);
  isSuperAdmin$: Observable<boolean>;

  constructor(private dialog: MatDialog, private store: Store<AppState>) {
    this.isSuperAdmin$ = this.store.select(isSuperAdmin);
  }

  deleteSpaceplan() {
    ConfirmationDialogComponent.open(this.dialog, {
      title: `Remove ${this.roomId ? 'block diagram' : 'floor plan'}`,
      description: `Are you sure you want to remove ${this.documentReference}?`,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation && this.documentReference && this.locationId && this.floorId) {
          const dispatchAction = this.roomId
            ? deleteRoomplan({
                locationId: this.locationId,
                floorId: this.floorId,
                roomId: this.roomId,
                fileName: this.documentReference,
              })
            : deleteFloorplan({
                locationId: this.locationId,
                spaceId: this.floorId,
                fileName: this.documentReference,
              });

          this.store.dispatch(dispatchAction);
        }
      });
  }
}
