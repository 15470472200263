import { createAction, props } from '@ngrx/store';
import { ClientUser, CustomHTTPResponseError } from '@models';

export const loadClientUsers = createAction(
  '[Client Management] Load Client Users List',
  props<{ clientId: string }>()
);

export const clientUsersLoaded = createAction(
  '[Client Management] Client User List Loaded',
  props<{ clientUsersList: ClientUser[] }>()
);

export const addClientUser = createAction(
  '[Client Management] Add Client User',
  props<{ newUserData: ClientUser; clientId: string }>()
);

export const clientUserCreated = createAction(
  '[Client Management] New Client User Created',
  props<{ createdUser: ClientUser }>()
);

export const updateUserClient = createAction(
  '[Client Management] Update Client User Start',
  props<{ clientUserId: string; clientUser: Partial<ClientUser> }>()
);

export const updateUserClientSuccess = createAction(
  '[Client Management] Update Client User Success',
  props<{ clientUser: ClientUser }>()
);

export const resetClientUsers = createAction('[Client Management] Reset Client User Collection');

export const clientUserError = createAction(
  '[Client Management] Client User Error',
  props<{ error: CustomHTTPResponseError }>()
);

export const resendUserInvitation = createAction(
  '[Client Management] Resend Client User Invitation',
  props<{ clientUser: ClientUser }>()
);

export const resendUserInvitationSuccess = createAction('[Client Management] Resend Client User Invitation Success');

export const resendUserInvitationError = createAction('[Client Management] Resend Client User Invitation Error');
