import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppService, IncidentsService, NotificationsService } from '@services';
import { catchError, concatMap, interval, map, startWith, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { loadAllIncidentError, loadAllIncidentSuccess } from './incidents.actions';
import { AuthActions, IncidentsActions } from '../action-types';
import { isLoggedIn } from '../user';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';

@Injectable()
export class IncidentsEffects {
  constructor(
    private actions$: Actions,
    private incidentService: IncidentsService,
    private notificationService: NotificationsService,
    private appService: AppService,
    private store: Store<AppState>
  ) {}

  loadAllIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsActions.loadAllIncidents),
      switchMap(
        // getting incidents' info once in 10sec
        () => interval(1000 * 1000).pipe(startWith(0), takeUntil(this.actions$.pipe(ofType(AuthActions.logout))))
      ),
      concatMap(() =>
        this.incidentService.getAllIncidents(this.appService.currentClient).pipe(
          map(incidents => loadAllIncidentSuccess({ incidents })),
          catchError(async error => loadAllIncidentError({ error }))
        )
      )
    )
  );

  locationErrorHandler = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IncidentsActions.loadAllIncidentError),
        withLatestFrom(this.store.select(isLoggedIn)),
        tap(([action, isLoggedIn]) => {
          if (isLoggedIn && action?.error?.message) {
            this.notificationService.showErrorMessage(action.error.message);
          }
        })
      ),
    { dispatch: false }
  );
}
