<div class="flex justify-between items-center mb-5">
  <div>
    <app-page-header>Add New User</app-page-header>
    <app-breadcrumbs [routeNames]="['Add New User']"></app-breadcrumbs>
  </div>
  <label
    for="create-user-submit"
    class="cursor-pointer btn-medium btn-base px-4 py-2"
    [class]="(isLoading$ | async) === false ? 'btn-green' : 'disabled-base'">
    <mat-icon *ngIf="(isLoading$ | async) === false">add</mat-icon>
    <mat-spinner diameter="20" class="mr-1" *ngIf="(isLoading$ | async) === true"></mat-spinner>
    Create New User
  </label>
</div>
<input type="text" class="hidden" />
<mat-card class="card" *ngIf="user">
  <mat-card-content class="p-0">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <mat-card-title class="card-title pb-4">Basic Info</mat-card-title>
      <mat-card-content class="p-0">
        <div class="flex flex-row">
          <label class="input-main-label">First name:*</label>
          <mat-form-field class="input-classic">
            <input formControlName="firstName" matInput placeholder="Enter first name" type="text" />
            <mat-error>Input is not valid</mat-error>
          </mat-form-field>
        </div>

        <div class="flex flex-row">
          <label class="input-main-label">Last name:*</label>
          <mat-form-field class="input-classic">
            <input formControlName="lastName" matInput placeholder="Enter last name" type="text" />
            <mat-error>Input is not valid</mat-error>
          </mat-form-field>
        </div>

        <div class="pb-4" *ngIf="roleOptions[user.role.name]?.length">
          <div class="flex flex-row">
            <label class="input-main-label">Role:*</label>
            <mat-form-field class="select-classic" *ngIf="currentClient | async as client">
              <mat-select formControlName="roleId" placeholder="Select role" panelClass="single-select-overlay">
                <mat-option
                  *ngFor="let role of roleOptions[user.role.name]"
                  [value]="role.id"
                  appSuperAdminRoleFilter
                  [isManagingClient]="client?.isManagingClient">
                  {{ role.name }}
                </mat-option>
              </mat-select>
              <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
              <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
            </mat-form-field>
          </div>
        </div>

        <mat-card-title class="card-title pb-4 pt-12">Contact Info</mat-card-title>

        <div class="flex flex-row">
          <label class="input-main-label">E-mail:*</label>
          <mat-form-field class="input-classic">
            <input formControlName="email" matInput placeholder="Enter e-mail" type="text" />
            <mat-error>Input is not valid</mat-error>
          </mat-form-field>
        </div>

        <div class="flex flex-row" formGroupName="phone">
          <label class="input-main-label">Phone number:*</label>
          <div class="flex justify-between">
            <mat-form-field class="input-classic max-w-[150px] mr-2">
              <mat-select (selectionChange)="changeMaskSettings($event)" formControlName="countryCode">
                @for (country of COUNTRY_LIST; track country.code) {
                <mat-option [value]="country.code">{{ country.code }} ({{ country.phoneCode }})</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field class="input-classic flex-grow max-w-[245px]">
              <input
                formControlName="number"
                matInput
                [shownMaskExpression]="phoneMaskExpression()"
                [mask]="phoneMask()"
                [placeholder]="phoneMask()"
                [validation]="true"
                type="text" />
              <mat-error>Input is not valid</mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-card-title class="card-title pt-7">Notifications</mat-card-title>
        <div class="flex flex-row ml-56 w-[420px] px-2 justify-between" formGroupName="notificationsPreferences">
          <mat-slide-toggle class="test-mail-toggle main-toggle" formControlName="emailEnabled" labelPosition="before">
            <span>Email:</span> {{ form.get('notificationsPreferences.emailEnabled')?.value ? 'On' : 'Off' }}
          </mat-slide-toggle>
          <mat-slide-toggle class="test-sms-toggle main-toggle" formControlName="smsEnabled" labelPosition="before">
            <span>SMS:</span> {{ form.get('notificationsPreferences.smsEnabled')?.value ? 'On' : 'Off' }}
          </mat-slide-toggle>
        </div>

        <input type="submit" id="create-user-submit" hidden="hidden" />
      </mat-card-content>
    </form>
  </mat-card-content>
</mat-card>
