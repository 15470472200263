import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AppState, isLoggedIn } from '@ngrx-store';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(isLoggedIn),
      tap(loggedIn => {
        if (loggedIn) {
          this.router.navigateByUrl('dashboard');
        }
      }),
      map(loggedIn => !loggedIn)
    );
  }
}
