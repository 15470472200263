<div>
  <label *ngIf="label" for="{{ controlName }}" class="input-main-label">{{ label }}</label>
  <form class="input-main-form" [formGroup]="form" (ngSubmit)="submitForm()">
    @if (hasPrefix) {
    <ng-content />
    }
    <mat-form-field class="input-main" [class]="formFieldClass">
      <input
        #input
        [formControlName]="controlName"
        id="{{ controlName }}"
        matInput
        placeholder="{{ placeholder }}"
        type="{{ formItemType }}"
        [min]="min"
        [max]="max"
        [shownMaskExpression]="applyPhoneMask ? phoneMaskExpression || DEFAULT_COUNTRY.maskExpression : null"
        [mask]="applyPhoneMask ? phoneMask || DEFAULT_COUNTRY.mask : null"
        autocomplete="off"
        (focus)="onFocus()"
        (blur)="onBlur()" />
      <i
        *ngIf="!form.controls[controlName].disabled && !form.controls[controlName].errors && !showSubmitButton"
        matSuffix
        class="mat-icon mode-edit-icon icon-edit text-3xl"></i>
      <mat-icon matSuffix *ngIf="form.controls[controlName].errors">report</mat-icon>
      <mat-error>Input is not valid</mat-error>
    </mat-form-field>
    <button type="submit" matSuffix *ngIf="form.valid" #submitButton [class.is-active]="showSubmitButton">
      <mat-icon>check</mat-icon>
    </button>
  </form>
</div>
