<h1 class="dialog-title">
  <span>Add new manufacturer</span>
  <button mat-dialog-close>
    <i class="icon-close"></i>
  </button>
</h1>
<form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="addManufacturer()">
  <mat-dialog-content class="dialog-content">
    <div class="flex flex-row">
      <label for="manufacturer" class="input-main-label first-letter:capitalize">Manufacturer name:*</label>
      <mat-form-field class="input-main">
        <input matInput id="manufacturer" type="text" placeholder="Enter manufacturer name" formControlName="name" />
        <mat-error *ngIf="form.get('name')?.hasError('required')">Input is not valid</mat-error>
        <mat-error *ngIf="form.get('name')?.hasError('alreadyExist')">Value already exist</mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-dialog-close class="test-btn-cancel btn-transparent btn-medium mr-4 font-bold">CANCEL</button>
    <button
      type="submit"
      class="test-submit-btn btn-green btn-medium font-bold"
      [disabled]="(isLoading$ | async) === true">
      <mat-spinner *ngIf="(isLoading$ | async) === true" diameter="20" class="mr-1"></mat-spinner>
      SAVE
    </button>
  </mat-dialog-actions>
</form>
