import { MenuItemModel } from '@models';

export const MenuItems: MenuItemModel[] = [
  {
    itemIndex: 1,
    title: 'Dashboard',
    icon: 'icon-dashboard',
    url: 'dashboard',
  },
  {
    itemIndex: 2,
    title: 'Devices',
    icon: 'icon-devices',
    url: 'devices',
  },
  {
    itemIndex: 3,
    title: 'Incidents',
    icon: 'icon-incidents',
    url: 'incidents',
    showIncidentsAmount: true,
  },
  {
    itemIndex: 4,
    title: 'Reporting',
    icon: 'icon-reporting',
    url: 'reporting',
  },
  {
    itemIndex: 5,
    title: 'User Management',
    icon: 'icon-users-management',
    url: 'user-management',
  },
];

export const AdditionalMenuItems: MenuItemModel[] = [
  {
    itemIndex: 1,
    title: 'Client Management',
    icon: 'icon-star',
    url: 'client-management',
    class: 'separator',
    superAdminOnly: true,
  },
  {
    itemIndex: 2,
    title: 'My Profile',
    icon: 'icon-my-profile',
    url: 'user-profile',
  },
];
