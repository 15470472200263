import { Component, DestroyRef, inject, OnDestroy } from '@angular/core';
import {
  DeviceData,
  DeviceTableCols,
  EntityStatus,
  FloorSpaceModel,
  IncidentModel,
  initialStats,
  LocationFullData,
  RoomSpaceModel,
  SpaceStats,
} from '@models';
import {
  AppState,
  getDeviceBySpaceId,
  isFullLocationLoaded,
  isSuperAdmin,
  loadDeviceCollectionBySpaceId,
  locationState,
  selectSpaceIncidents,
} from '@ngrx-store';
import { Store } from '@ngrx/store';
import { interval, Observable, startWith, Subscription } from 'rxjs';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AppService, DevicesFilterService, IncidentsFilterService, IncidentsService } from '@services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RoomPopoverComponent } from '@standalone/room-popover/room-popover.component';

@Component({
  selector: 'app-space-details',
  templateUrl: './space-details.component.html',
})
export class SpaceDetailsComponent implements OnDestroy {
  isFullLocationLoaded$: Observable<boolean>;
  locationFullData!: LocationFullData;
  currentLocationId!: string;
  currentFloorId!: string;
  currentRoomId!: string;
  currentFloor: FloorSpaceModel | undefined;
  currentRoom: RoomSpaceModel | undefined;
  roomList: RoomSpaceModel[] = [];
  routerSubs$!: Subscription;
  locationSubs$!: Subscription;

  buildingStats: SpaceStats = initialStats();
  incidentTableColumns = ['status', 'faultname', 'deviceId', 'room', 'deviceType', 'reported'];
  floorplanEnabled = true;
  isSuperAdmin$: Observable<boolean>;
  // spaceIncidents$!: Observable<IncidentModel[] | undefined>;
  resolvedIncidents: IncidentModel[] = [];
  resolvedIncidentsLoading = false;
  destroyRef = inject(DestroyRef);
  roomPopover = RoomPopoverComponent;
  roomDevices: DeviceData[] | undefined;
  deviceSubscription$: Subscription | undefined;
  isDeviceTableInitialized = false;
  isIncidentsTableInitialized = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private incidentsService: IncidentsService,
    private appService: AppService,
    private incidentsFilterService: IncidentsFilterService,
    public devicesFilterService: DevicesFilterService
  ) {
    this.routerSubs$ = router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.defineCurrentFloor();
      }
      if (val instanceof ActivationEnd && val.snapshot.component) {
        this.deviceSubscription$?.unsubscribe();
        this.currentLocationId = val.snapshot.paramMap.has('floorId')
          ? (val.snapshot.paramMap.get('locationId') as string)
          : '';
        this.currentFloorId = val.snapshot.paramMap.has('floorId')
          ? (val.snapshot.paramMap.get('floorId') as string)
          : '';
        this.currentRoomId = val.snapshot.paramMap.has('roomId') ? (val.snapshot.paramMap.get('roomId') as string) : '';
        if (this.currentRoomId) {
          this.floorplanEnabled = false;
          this.isDeviceTableInitialized = false;
          this.isIncidentsTableInitialized = false;

          this.deviceSubscription$ = interval(1000 * 5)
            .pipe(startWith(0), takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
              this.store.dispatch(
                loadDeviceCollectionBySpaceId({ locationId: this.currentLocationId, roomId: this.currentRoomId })
              );
            });
          this.store
            .select(getDeviceBySpaceId(this.currentRoomId))
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(devices => {
              if (!this.isDeviceTableInitialized) {
                this.devicesFilterService.initColumns([
                  DeviceTableCols.DEVICE_TYPE,
                  DeviceTableCols.MANUFACTURER,
                  DeviceTableCols.MODEL,
                  DeviceTableCols.SERIAL_NUMBER,
                  DeviceTableCols.STATUS,
                  DeviceTableCols.INCIDENTS,
                ]);
              }
              this.isDeviceTableInitialized = true;
              this.roomDevices = devices;
              this.devicesFilterService.initDevices(devices);
              this.devicesFilterService.showLocationFilter = false;
            });
        }
        this.getSpaceIncidents();
        this.normalizeBuildingStats();
        this.resolvedIncidents = [];
      }
    });
    this.isSuperAdmin$ = this.store.select(isSuperAdmin);
    this.isFullLocationLoaded$ = this.store.select(isFullLocationLoaded);
    this.locationSubs$ = this.store.select(locationState).subscribe(res => {
      if (res) {
        this.locationFullData = res;
        this.defineCurrentFloor();
      }
    });

    this.incidentsFilterService.resolvedDates$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => this.getResolvedIncidents(value));
  }

  toggleFloorplan(event: MatSlideToggleChange) {
    this.floorplanEnabled = event.checked;
  }

  ngOnDestroy(): void {
    this.routerSubs$?.unsubscribe();
    this.locationSubs$?.unsubscribe();
    this.incidentsFilterService.resetState();
    this.devicesFilterService.resetState();
  }

  defineCurrentFloor() {
    if (this.locationFullData) {
      const currentFloor = this.locationFullData.floors.find(f => f.id === this.currentFloorId);
      if (currentFloor) {
        this.currentFloor = currentFloor;
        this.normalizeBuildingStats();
        this.roomList = this.currentFloor?.rooms || [];
      }

      if (this.currentRoomId && this.currentFloor) {
        this.currentRoom = this.roomList.find(({ id }) => this.currentRoomId === id);
      }
    }
  }

  normalizeBuildingStats() {
    if (this.currentFloor) {
      if (this.currentRoomId) {
        const room = this.currentFloor.rooms?.find(({ id }) => id === this.currentRoomId);
        if (room) {
          const isRoomArchived = room.status === EntityStatus.Archived;
          this.buildingStats.incidentAlert = isRoomArchived ? 0 : room.incidentCountByStatuses.newCount;
          this.buildingStats.incidentInProgress = isRoomArchived ? 0 : room.incidentCountByStatuses.inProgressCount;
          this.buildingStats.deviceActive = isRoomArchived ? 0 : room.devicesByStatuses.activeCount;
          this.buildingStats.devicePaused = isRoomArchived ? 0 : room.devicesByStatuses.pausedCount;
        }
      } else {
        this.buildingStats.rooms =
          this.currentFloor.rooms?.filter(({ status }) => status !== EntityStatus.Archived).length || 0;
        this.buildingStats.incidentAlert =
          this.currentFloor.status === EntityStatus.Archived ? 0 : this.currentFloor.incidentCountByStatuses.newCount;
        this.buildingStats.incidentInProgress =
          this.currentFloor.status === EntityStatus.Archived
            ? 0
            : this.currentFloor.incidentCountByStatuses.inProgressCount;
        this.buildingStats.deviceActive =
          this.currentFloor.status === EntityStatus.Archived ? 0 : this.currentFloor.devicesByStatuses.activeCount;
        this.buildingStats.devicePaused =
          this.currentFloor.status === EntityStatus.Archived ? 0 : this.currentFloor.devicesByStatuses.pausedCount;
      }
    }
  }

  spaceChanged({ spaceId, isFloorSwitch }: { spaceId: string; isFloorSwitch: boolean }) {
    let path = this.router.url.split('/');
    if (isFloorSwitch && this.currentRoomId) {
      path.pop();
    }
    path[path.length - 1] = spaceId;
    path = path.filter(n => n);
    this.router.navigate(path);
  }

  getSpaceIncidents() {
    this.store
      .select(selectSpaceIncidents(this.currentRoomId || this.currentFloorId))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(incidents => {
        this.incidentsFilterService.initOpenIncidents(incidents);
        if (!this.isIncidentsTableInitialized) {
          this.incidentsFilterService.initFilterTableType({
            displayedColumns: this.incidentTableColumns,
            isFloorTable: true,
            isRoomTable: !!this.currentRoomId,
          });
          this.isIncidentsTableInitialized = true;
        }
      });
  }

  getResolvedIncidents(dateRange: { startDateTime: string; endDateTime: string }) {
    if (dateRange.startDateTime && dateRange.endDateTime) {
      this.resolvedIncidentsLoading = true;

      this.incidentsService
        .getAllResolvedIncidents(this.appService.currentClient, {
          ...dateRange,
          spaceId: this.currentRoomId || this.currentFloorId,
        })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: data => {
            this.resolvedIncidents = data;
            this.incidentsFilterService.initResolvedIncidents(data);
            this.resolvedIncidentsLoading = false;
          },
          error: () => {
            this.resolvedIncidentsLoading = false;
          },
        });
    }
  }
}
